import { Center, Loader } from '@mantine/core';
import React from 'react';
import NotAuthorised from './NotAuthorised';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import NotAuthenticated from './NotAuthenticated';
import { Navigate, useLocation } from 'react-router-dom';

export type RequireAuthenticatedProps = {
	children?: React.ReactNode;
	redirectNotAuthenticated?: string;
};

export default function RequireAuthenticated(props: RequireAuthenticatedProps) {
	const { children, redirectNotAuthenticated } = props;

	const location = useLocation();
	const { user, isInitialLoading } = useAuthenticatedUser();

	if (isInitialLoading) {
		return (
			<Center h="100%">
				<Loader />
			</Center>
		);
	} else if (user) {
		if (user.role === 'ADMIN') {
			return <>{children}</>;
		} else {
			return <NotAuthorised />;
		}
	} else {
		if (redirectNotAuthenticated) {
			return <Navigate to={redirectNotAuthenticated} state={{ redirectTo: location.pathname }} />;
		} else {
			return <NotAuthenticated />;
		}
	}
}
