import React, { useState } from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useComputedSlug from '../../hooks/useComputedSlug';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Select, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import { QuizListFragment, QuizSingleFragment, CategoryListFragment } from '../../graphql/graphql';
import { CategorySortableTreeItem, useCategoryService } from '../../services/CategoryService';
import { useQuizService } from '../../services/QuizService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';

export const validationSchema = z.object({
	title: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
});

export type AddQuizModalProps = CrudAddModalProps<QuizListFragment, QuizSingleFragment> & {
	categoryId?: string;
};

export default function AddQuizModal(props: AddQuizModalProps) {
	const { modalId, onCancel, onCreated, categoryId } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [categories, setCategories] = useState<CategorySortableTreeItem<CategoryListFragment>[]>([]);

	const quizService = useQuizService();
	const categoryService = useCategoryService();

	const isLoadingCategories = useLoadingEffect(async () => {
		try {
			const response = await categoryService.getAllInFlattenedTree().response;
			setCategories(response);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load categories');
		}
	}, [categoryService, categoryId]);

	const form = useForm<AddQuizForm>({
		initialValues: {
			title: '',
			slug: '',
			description: '',
			categoryId: categoryId ?? '',
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: AddQuizForm) => {
		const loader = addLoader();

		try {
			const createdQuiz = await quizService.create({
				title: values.title,
				slug: values.slug,
				description: values.description,
				...((categoryId || values.categoryId) && {
					quizCategories: {
						create: [
							{
								category: {
									connect: {
										id: categoryId || values.categoryId,
									},
								},
							},
						],
					},
				}),
			});
			onCreated(createdQuiz);
			NotificationUtils.showSuccess('Successfully created quiz', createdQuiz.title);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create quiz');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'title');

	const categoryData = (categories ?? []).map((category) => ({
		value: category.item.id,
		label: category.getName(),
	}));

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...slugComputedFromProps}
						label="Title"
						description="The title of the quiz. Forms the basis of the slug."
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="The description of the quiz. This will appear before they attempt it."
						disabled={isLoading}
						withAsterisk
						rows={5}
					/>
					<TextInput
						{...form.getInputProps('maxAttempts')}
						label="Max attempts"
						description="The maximum number of times a user can attempt the quiz. Leave blank for unlimited."
						disabled={isLoading}
					/>
					<Select
						{...form.getInputProps('categoryId')}
						data={categoryData}
						label="Initial category"
						placeholder={isLoadingCategories ? 'Loading...' : '--- Please select ---'}
						nothingFoundMessage="No search results"
						searchable
						disabled={isLoading || isLoadingCategories || categoryId !== undefined}
					/>
					<Group justify="flex-end">
						<Button
							type="button"
							variant="subtle"
							onClick={handleCancel}
							disabled={isLoading || isLoadingCategories}
						>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading || isLoadingCategories}>
							Add quiz {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddQuizForm = Pick<QuizSingleFragment, 'title' | 'slug' | 'description'> & {
	categoryId: string;
};
