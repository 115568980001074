import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import ProgrammesList from '../../components/programmes/ProgrammesList';

export default function ProgrammesPage() {
	return (
		<BackendPage title="Programmes">
			<ProgrammesList />
		</BackendPage>
	);
}
