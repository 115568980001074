import { useMemo } from 'react';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import type { User } from '@dr-pam/common-types/database';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import {
	CreateUserSubscriptionMutation,
	CreateUserSubscriptionMutationVariables,
	DeleteUserSubscriptionsMutation,
	DeleteUserSubscriptionsMutationVariables,
	UpdateUserSubscriptionMutation,
	UpdateUserSubscriptionMutationVariables,
	UserSubscriptionCreateInput,
	UserSubscriptionListFragment,
	UserSubscriptionUpdateInput,
	UserSubscriptionsQuery,
	UserSubscriptionsQueryVariables,
} from '../graphql/graphql';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export type UserForCreate = {
	email: string;
	fullName: string;
	displayName?: string | null;
	password: string;
};

export type UserForUpdate = {
	id?: string;
	email?: string;
	fullName?: string;
	displayName?: string;
	photoUrl?: string;
	isEnabled?: boolean;
	password?: string;
};

export const GQL_FRAG_USER_SUBSCRIPTION_LIST = gql`
	fragment UserSubscriptionList on UserSubscription {
		id
		validFrom
		validTo
		userId
		productSubscriptionTypeId
		productSubscriptionType {
			id
			name
			isPublished
			durationInDays
			product {
				id
				name
				isPublished
				productProgrammes {
					id
					programme {
						id
						name
						isPublished
					}
				}
			}
		}
	}
`;

export const GQL_FRAG_USER_SUBSCRIPTION_SINGLE = gql`
	${GQL_FRAG_USER_SUBSCRIPTION_LIST}
	fragment UserSubscriptionSingle on UserSubscription {
		...UserSubscriptionList
	}
`;

export const GQL_GET_USER_SUBSCRIPTIONS_FOR_LIST = gql`
	${GQL_FRAG_USER_SUBSCRIPTION_LIST}
	query UserSubscriptions($userId: String!) {
		userSubscriptions(where: { userId: { equals: $userId } }, orderBy: { validFrom: asc }) {
			...UserSubscriptionList
		}
	}
`;

export const GQL_DELETE_USER_SUBSCRIPTIONS = gql`
	mutation DeleteUserSubscriptions($userSubscriptionIds: [String!]) {
		deleteManyUserSubscription(where: { id: { in: $userSubscriptionIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_USER_SUBSCRIPTION = gql`
	${GQL_FRAG_USER_SUBSCRIPTION_SINGLE}
	mutation CreateUserSubscription($userSubscription: UserSubscriptionCreateInput!) {
		createOneUserSubscription(data: $userSubscription) {
			...UserSubscriptionSingle
		}
	}
`;

export const GQL_UPDATE_USER_SUBSCRIPTION = gql`
	${GQL_FRAG_USER_SUBSCRIPTION_SINGLE}
	mutation UpdateUserSubscription($userSubscriptionId: String!, $userSubscription: UserSubscriptionUpdateInput!) {
		updateOneUserSubscription(where: { id: $userSubscriptionId }, data: $userSubscription) {
			...UserSubscriptionSingle
		}
	}
`;

const API_ROOT = `/api/user`;

export default class UserService {
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public get(userId: string) {
		const request = FetchUtils.getJson<User>(`${API_ROOT}/${userId}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getAll() {
		const request = FetchUtils.getJson<User[]>(`${API_ROOT}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public search(query: string) {
		const request = FetchUtils.getJson<User[]>(`${API_ROOT}?search=${encodeURIComponent(query)}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public create(user: UserForCreate) {
		const request = FetchUtils.postJson<User, UserForCreate>(API_ROOT, user);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public update(userId: string, user: UserForUpdate) {
		const request = FetchUtils.putJson<User, UserForUpdate>(`${API_ROOT}/${userId}`, user);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public delete(userId: string) {
		const request = FetchUtils.delete(`${API_ROOT}/${userId}`);
		return FetchUtils.abortableRequest(request, this._registerAbort).response;
	}

	public async addSubscription(userId: string, userSubscription: UserSubscriptionCreateInput) {
		const result = await this._apolloClient.mutate<
			CreateUserSubscriptionMutation,
			CreateUserSubscriptionMutationVariables
		>({
			mutation: GQL_CREATE_USER_SUBSCRIPTION,
			variables: {
				userSubscription,
			},
		});
		if (!result.data?.createOneUserSubscription) {
			throw new Error('Failed to create userSubscription');
		}
		return result.data.createOneUserSubscription;
	}

	public async removeSubscription(userSubscriptionIds: string | string[]) {
		userSubscriptionIds = Array.isArray(userSubscriptionIds) ? userSubscriptionIds : [userSubscriptionIds];

		await this._apolloClient.mutate<DeleteUserSubscriptionsMutation, DeleteUserSubscriptionsMutationVariables>({
			mutation: GQL_DELETE_USER_SUBSCRIPTIONS,
			variables: {
				userSubscriptionIds,
			},
		});
	}

	public async updateSubscription(userSubscriptionId: string, userSubscription: UserSubscriptionUpdateInput) {
		const result = await this._apolloClient.mutate<
			UpdateUserSubscriptionMutation,
			UpdateUserSubscriptionMutationVariables
		>({
			mutation: GQL_UPDATE_USER_SUBSCRIPTION,
			variables: {
				userSubscriptionId,
				userSubscription,
			},
		});
		if (!result.data?.updateOneUserSubscription) {
			throw new Error('Failed to update userSubscription');
		}
		return result.data.updateOneUserSubscription;
	}

	public getSubscriptions(userId: string) {
		const request = ApolloUtils.abortableQuery<
			UserSubscriptionsQuery,
			UserSubscriptionListFragment[],
			UserSubscriptionsQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_USER_SUBSCRIPTIONS_FOR_LIST,
				variables: {
					userId,
				},
			},
			(data) => data.userSubscriptions,
			this._registerAbort,
		);

		return request;
	}
}

export function useUserService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new UserService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
