import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import UsersList from '../../components/users/UsersList';

export default function UsersPage() {
	return (
		<BackendPage title="Users">
			<UsersList />
		</BackendPage>
	);
}
