import React, { useEffect, useMemo, useState } from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Select, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useUserService } from '../../services/UserService';
import z from 'zod';
import {
	ProductListFragment,
	ProductSubscriptionTypeListFragment,
	UserSubscriptionCreateInput,
	UserSubscriptionListFragment,
} from '../../graphql/graphql';
import { DateInput } from '@mantine/dates';
import { useProductService } from '../../services/ProductService';
import dayjs from 'dayjs';
import DateTimeUtils from '@dr-pam/common-components/Utils/DateTimeUtils';

const RequiredConfig = { required_error: 'Required', invalid_type_error: 'Required' };

const validationSchema = z
	.object({
		validFrom: z.date(RequiredConfig),
		validTo: z.null().or(z.date(RequiredConfig)),
		productId: z.string(RequiredConfig).nonempty('Required'),
		productSubscriptionTypeId: z.string(RequiredConfig).nonempty('Required'),
	})
	.refine((data) => data.validTo === null || data.validFrom < data.validTo, {
		message: `"Valid Until" must be before "Valid From"`,
		path: ['validTo'],
	});

export type AddSubscriptionModalProps = CrudAddModalProps<
	UserSubscriptionListFragment,
	UserSubscriptionListFragment
> & {
	userId: string;
};

export default function AddSubscriptionModal(props: AddSubscriptionModalProps) {
	const { modalId, onCancel, onCreated, userId } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [products, setProducts] = useState<ProductListFragment[]>([]);
	const [subscriptionTypes, setSubscriptionTypes] = useState<ProductSubscriptionTypeListFragment[]>([]);

	const userService = useUserService();
	const productService = useProductService();

	useEffect(() => {
		const loader = addLoader();

		const request = productService.getAll();
		request.response
			.then((products) => {
				setProducts(products);
			})
			.catch((err) => {
				NotificationUtils.showError(err as Error, 'Failed to load products');
			})
			.finally(() => {
				removeLoader(loader);
			});
	}, [addLoader, productService, removeLoader]);

	const form = useForm<AddSubscriptionForm>({
		initialValues: {
			validFrom: null,
			validTo: null,
			productId: null,
			productSubscriptionTypeId: null,
		},
		validate: zodResolver(validationSchema),
	});

	useEffect(() => {
		if (!form.values.productId) {
			form.setFieldValue('productSubscriptionTypeId', null);
			setSubscriptionTypes([]);
			return;
		}

		const loader = addLoader();

		const request = productService.getWithProgrammesAndSubscriptionTypes(form.values.productId);
		request.response
			.then((subscriptionTypes) => {
				setSubscriptionTypes(subscriptionTypes?.productSubscriptionTypes ?? []);
			})
			.catch((err) => {
				NotificationUtils.showError(err as Error, 'Failed to subscription types products');
			})
			.finally(() => {
				removeLoader(loader);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addLoader, form.values.productId, productService, removeLoader]);

	const productsData = useMemo(() => products.map((p) => ({ value: p.id, label: p.name })), [products]);
	const subscriptionTypesData = useMemo(
		() => subscriptionTypes.map((pst) => ({ value: pst.id, label: pst.name })),
		[subscriptionTypes],
	);

	const selectedProductSubscriptionType = subscriptionTypes.find(
		(x) => x.id === form.values.productSubscriptionTypeId,
	);

	useEffect(() => {
		if (form.values.productSubscriptionTypeId && form.values.validFrom && !form.values.validTo) {
			if (selectedProductSubscriptionType && selectedProductSubscriptionType.durationInDays) {
				form.setFieldValue(
					'validTo',
					dayjs(form.values.validFrom).add(selectedProductSubscriptionType.durationInDays, 'days').toDate(),
				);
			}
		}
	}, [form, selectedProductSubscriptionType, subscriptionTypes]);

	const handleSubmit = async (values: AddSubscriptionForm) => {
		const loader = addLoader();
		try {
			const createdSubscription = await userService.addSubscription(userId, {
				validFrom: values.validFrom,
				validTo: selectedProductSubscriptionType?.durationInDays ? values.validTo : null,
				user: {
					connect: {
						id: userId,
					},
				},
				productSubscriptionType: {
					connect: {
						id: values.productSubscriptionTypeId,
					},
				},
			});
			onCreated(createdSubscription);
			NotificationUtils.showSuccess('Successfully created subscription', 'Success');
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create subscription');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Select
						{...form.getInputProps('productId')}
						label="Product"
						description="The product to subscribe to"
						nothingFoundMessage="No options"
						data={productsData}
						disabled={isLoading}
						withAsterisk
						clearable
						searchable
					/>
					<Select
						{...form.getInputProps('productSubscriptionTypeId')}
						label="Subscription type"
						description="The type of the subscription"
						nothingFoundMessage={form.values.productId ? 'No options' : 'Select a product first'}
						data={subscriptionTypesData}
						disabled={isLoading}
						withAsterisk
						clearable
						searchable
					/>
					<DateInput
						{...form.getInputProps('validFrom')}
						dateParser={DateTimeUtils.parse}
						label="Valid From"
						description="(DD-MM-YYYY)"
						valueFormat="DD-MM-YYYY"
						popoverProps={{ withinPortal: true }}
						disabled={isLoading}
						withAsterisk
						clearable
					/>
					{selectedProductSubscriptionType?.durationInDays ? (
						<DateInput
							{...form.getInputProps('validTo')}
							dateParser={DateTimeUtils.parse}
							label="Valid Until"
							description="(DD-MM-YYYY)"
							valueFormat="DD-MM-YYYY"
							popoverProps={{ withinPortal: true }}
							disabled={isLoading}
							withAsterisk
							clearable
						/>
					) : null}
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Add subscription {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddSubscriptionForm = Pick<UserSubscriptionCreateInput, 'validFrom' | 'validTo'> & {
	productId: string | null;
	productSubscriptionTypeId: string | null;
};
