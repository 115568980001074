import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import PaymentsList from '../../components/payments/PaymentsList';

export default function PaymentsPage() {
	return (
		<BackendPage title="Payments">
			<PaymentsList />
		</BackendPage>
	);
}
