import { useMemo } from 'react';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import hash from 'object-hash';
import {
	ResourceSingleFragment,
	ResourceListFragment,
	ResourceCreateInput,
	ResourceUpdateInput,
	ResourceQuery,
	ResourceQueryVariables,
	ResourcesQuery,
	SearchResourcesQuery,
	SearchResourcesQueryVariables,
	CreateResourceMutation,
	CreateResourceMutationVariables,
	UpdateResourceMutation,
	UpdateResourceMutationVariables,
	DeleteResourcesMutation,
	DeleteResourcesMutationVariables,
	ResourcesByCategoryIdQuery,
	ResourcesByCategoryIdQueryVariables,
	AddResourceToCategoryMutation,
	AddResourceToCategoryMutationVariables,
	RemoveResourceFromCategoryMutation,
	RemoveResourceFromCategoryMutationVariables,
	ResourceCategoryListFragment,
	UpdateResourceCategoryIsPinnedMutation,
	UpdateResourceCategoryIsPinnedMutationVariables,
	ResourceUsageFragment,
	ResourceUsagesQuery,
	ResourceUsagesQueryVariables,
	ResourceTypeListFragment,
	ResourceTypesQuery,
	ResourceTypeCreateInput,
	ResourceTypeUpdateInput,
	CreateResourceTypeMutation,
	CreateResourceTypeMutationVariables,
	DeleteResourceTypesMutation,
	DeleteResourceTypesMutationVariables,
	UpdateResourceTypeMutation,
	UpdateResourceTypeMutationVariables,
	UpdateResourceCategoryIsRequiredMutation,
	UpdateResourceCategoryIsRequiredMutationVariables,
} from '../graphql/graphql';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import { SortableTreeItem } from '../models/SortableTreeItem';
import IPublishable from '@dr-pam/common-components/Services/IPublishable';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export class ResourceSortableTreeItem<T extends ResourceCategoryListFragment> extends SortableTreeItem<T> {
	public getId(): string {
		return this.item.id;
	}
	public getSortOrder(): number {
		return this.item.sortOrder;
	}
	public setSortOrder(sortOrder: number): void {
		this.item.sortOrder = sortOrder;
	}

	public getName(): string {
		if (this.parent) {
			return `${this.parent.getName()} > ${this.item.resource.title}`;
		} else {
			return this.item.resource.title;
		}
	}

	public getHash(): string {
		return hash([this.getId(), this.parent?.getId(), this.getSortOrder()]);
	}
}

export const GQL_FRAG_RESOURCE_TYPE_LIST = gql`
	fragment ResourceTypeList on ResourceType {
		id
		name
	}
`;

export const GQL_FRAG_RESOURCE_TYPE_SINGLE = gql`
	fragment ResourceTypeSingle on ResourceType {
		id
		name
	}
`;

export const GQL_FRAG_RESOURCE_LIST = gql`
	fragment ResourceList on Resource {
		id
		resourceTypeId
		title
		slug
		filename
		url
		isPublished
	}
`;

export const GQL_FRAG_RESOURCE_SINGLE = gql`
	fragment ResourceSingle on Resource {
		id
		created
		modified
		resourceTypeId
		title
		slug
		filename
		url
		isPublished
	}
`;

export const GQL_FRAG_RESOURCE_CATEGORY_SINGLE = gql`
	${GQL_FRAG_RESOURCE_LIST}
	fragment ResourceCategorySingle on ResourceCategory {
		id
		isPinned
		sortOrder
		isRequired
		resource {
			...ResourceSingle
		}
	}
`;

export const GQL_FRAG_RESOURCE_CATEGORY_LIST = gql`
	${GQL_FRAG_RESOURCE_LIST}
	fragment ResourceCategoryList on ResourceCategory {
		id
		isPinned
		sortOrder
		isRequired
		resource {
			...ResourceList
		}
	}
`;

export const GQL_GET_RESOURCE = gql`
	${GQL_FRAG_RESOURCE_SINGLE}
	query Resource($resourceId: String!) {
		resource(where: { id: $resourceId }) {
			...ResourceSingle
		}
	}
`;

export const GQL_GET_RESOURCES_FOR_LIST = gql`
	${GQL_FRAG_RESOURCE_LIST}
	query Resources {
		resources(orderBy: { created: desc }) {
			...ResourceList
		}
	}
`;

export const GQL_GET_RESOURCE_TYPES_FOR_LIST = gql`
	${GQL_FRAG_RESOURCE_TYPE_LIST}
	query ResourceTypes {
		resourceTypes(orderBy: { name: asc }) {
			...ResourceTypeList
		}
	}
`;

export const GQL_SEARCH_RESOURCES_FOR_LIST = gql`
	${GQL_FRAG_RESOURCE_LIST}
	query SearchResources($title: String!) {
		resources(orderBy: { title: asc }, where: { title: { contains: $title } }) {
			...ResourceList
		}
	}
`;

export const GQL_GET_RESOURCES_FOR_LIST_BY_CATEGORY = gql`
	${GQL_FRAG_RESOURCE_LIST}
	query ResourcesByCategoryId($categoryIds: [String!]) {
		resourceCategories(where: { categoryId: { in: $categoryIds } }) {
			resource {
				...ResourceList
			}
		}
	}
`;

export const GQL_GET_ORPHAN_RESOURCES = gql`
	${GQL_FRAG_RESOURCE_LIST}
	query OrphanResources {
		resources(where: { resourceCategories: { none: { id: { startsWith: "" } } } }) {
			...ResourceList
		}
	}
`;

export const GQL_GET_RESOURCE_USAGES = gql`
	fragment ResourceUsage on ResourceCategory {
		id
		category {
			id
			name
			programme {
				id
				name
			}
		}
	}

	query ResourceUsages($resourceId: String!) {
		resourceCategories(where: { resourceId: { equals: $resourceId } }) {
			...ResourceUsage
		}
	}
`;

export const GQL_DELETE_RESOURCES = gql`
	mutation DeleteResources($resourceIds: [String!]) {
		deleteManyResource(where: { id: { in: $resourceIds } }) {
			count
		}
	}
`;

export const GQL_DELETE_RESOURCE_TYPES = gql`
	mutation DeleteResourceTypes($resourceTypeIds: [String!]) {
		deleteManyResourceType(where: { id: { in: $resourceTypeIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_RESOURCE = gql`
	${GQL_FRAG_RESOURCE_SINGLE}
	mutation CreateResource($resource: ResourceCreateInput!) {
		createOneResource(data: $resource) {
			...ResourceSingle
		}
	}
`;

export const GQL_CREATE_RESOURCE_TYPE = gql`
	${GQL_FRAG_RESOURCE_TYPE_SINGLE}
	mutation CreateResourceType($resourceType: ResourceTypeCreateInput!) {
		createOneResourceType(data: $resourceType) {
			...ResourceTypeSingle
		}
	}
`;

export const GQL_UPDATE_RESOURCE = gql`
	${GQL_FRAG_RESOURCE_SINGLE}
	mutation UpdateResource($resourceId: String!, $resource: ResourceUpdateInput!) {
		updateOneResource(where: { id: $resourceId }, data: $resource) {
			...ResourceSingle
		}
	}
`;

export const GQL_UPDATE_RESOURCE_TYPE = gql`
	${GQL_FRAG_RESOURCE_TYPE_SINGLE}
	mutation UpdateResourceType($resourceTypeId: String!, $resourceType: ResourceTypeUpdateInput!) {
		updateOneResourceType(where: { id: $resourceTypeId }, data: $resourceType) {
			...ResourceTypeSingle
		}
	}
`;

export const GQL_ADD_RESOURCE_TO_CATEGORY = gql`
	${GQL_FRAG_RESOURCE_CATEGORY_LIST}
	mutation AddResourceToCategory($resourceId: String!, $categoryId: String!, $sortOrder: Int) {
		upsertOneResourceCategory(
			where: { resourceCategory: { resourceId: $resourceId, categoryId: $categoryId } }
			create: {
				resource: { connect: { id: $resourceId } }
				category: { connect: { id: $categoryId } }
				sortOrder: $sortOrder
			}
			update: {
				resource: { connect: { id: $resourceId } }
				category: { connect: { id: $categoryId } }
				sortOrder: { set: $sortOrder }
			}
		) {
			...ResourceCategoryList
		}
	}
`;

export const GQL_REMOVE_RESOURCE_FROM_CATEGORY = gql`
	mutation RemoveResourceFromCategory($resourceId: String!, $categoryId: String!) {
		deleteOneResourceCategory(where: { resourceCategory: { resourceId: $resourceId, categoryId: $categoryId } }) {
			id
		}
	}
`;

export const GQL_UPDATE_RESOURCE_CATEGORY_IS_PINNED = gql`
	mutation UpdateResourceCategoryIsPinned($resourceCategoryId: String!, $isPinned: Boolean!) {
		updateOneResourceCategory(where: { id: $resourceCategoryId }, data: { isPinned: { set: $isPinned } }) {
			id
		}
	}
`;

export const GQL_UPDATE_RESOURCE_CATEGORY_IS_REQUIRED = gql`
	mutation UpdateResourceCategoryIsRequired($resourceCategoryId: String!, $isRequired: Boolean!) {
		updateOneResourceCategory(where: { id: $resourceCategoryId }, data: { isRequired: { set: $isRequired } }) {
			id
		}
	}
`;

export default class ResourceService
	implements
		ICrudService<ResourceListFragment, ResourceSingleFragment, ResourceCreateInput, ResourceUpdateInput>,
		IPublishable<ResourceListFragment>
{
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public async publish(resourceId: string): Promise<ResourceListFragment> {
		const updatedResources = await this.update(resourceId, {
			isPublished: { set: true },
		});

		return updatedResources;
	}

	public async unpublish(resourceId: string): Promise<ResourceListFragment> {
		const updatedResources = await this.update(resourceId, {
			isPublished: { set: false },
		});

		return updatedResources;
	}

	public get(resourceId: string) {
		const request = ApolloUtils.abortableQuery<
			ResourceQuery,
			ResourceSingleFragment | null,
			ResourceQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_RESOURCE,
				variables: {
					resourceId,
				},
			},
			(data) => data.resource ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getAll() {
		const request = ApolloUtils.abortableQuery<ResourcesQuery, ResourceListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_RESOURCES_FOR_LIST,
			},
			(data) => data.resources,
			this._registerAbort,
		);

		return request;
	}

	public getOrphans() {
		const request = ApolloUtils.abortableQuery<ResourcesQuery, ResourceListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_ORPHAN_RESOURCES,
			},
			(data) => data.resources,
			this._registerAbort,
		);

		return request;
	}

	public getAllTypes() {
		const request = ApolloUtils.abortableQuery<ResourceTypesQuery, ResourceTypeListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_RESOURCE_TYPES_FOR_LIST,
			},
			(data) => data.resourceTypes,
			this._registerAbort,
		);

		return request;
	}

	public searchByTitle(title: string) {
		const request = ApolloUtils.abortableQuery<
			SearchResourcesQuery,
			ResourceListFragment[],
			SearchResourcesQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_SEARCH_RESOURCES_FOR_LIST,
				variables: {
					title,
				},
			},
			(data) => data.resources,
			this._registerAbort,
		);

		return request;
	}

	public async create(resource: ResourceCreateInput) {
		const result = await this._apolloClient.mutate<CreateResourceMutation, CreateResourceMutationVariables>({
			mutation: GQL_CREATE_RESOURCE,
			variables: {
				resource,
			},
		});
		if (!result.data?.createOneResource) {
			throw new Error('Failed to create resource');
		}
		return result.data.createOneResource;
	}

	public async createType(resourceType: ResourceTypeCreateInput) {
		const result = await this._apolloClient.mutate<CreateResourceTypeMutation, CreateResourceTypeMutationVariables>(
			{
				mutation: GQL_CREATE_RESOURCE_TYPE,
				variables: {
					resourceType,
				},
			},
		);
		if (!result.data?.createOneResourceType) {
			throw new Error('Failed to create resource type');
		}
		return result.data.createOneResourceType;
	}

	public async update(resourceId: string, resource: ResourceUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateResourceMutation, UpdateResourceMutationVariables>({
			mutation: GQL_UPDATE_RESOURCE,
			variables: {
				resourceId,
				resource,
			},
		});
		if (!result.data?.updateOneResource) {
			throw new Error('Failed to update resource');
		}
		return result.data.updateOneResource;
	}

	public async updateType(resourceTypeId: string, resourceType: ResourceTypeUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateResourceTypeMutation, UpdateResourceTypeMutationVariables>(
			{
				mutation: GQL_UPDATE_RESOURCE_TYPE,
				variables: {
					resourceTypeId,
					resourceType,
				},
			},
		);
		if (!result.data?.updateOneResourceType) {
			throw new Error('Failed to update resource type');
		}
		return result.data.updateOneResourceType;
	}

	public async delete(resourceIds: string | string[]) {
		resourceIds = Array.isArray(resourceIds) ? resourceIds : [resourceIds];

		await this._apolloClient.mutate<DeleteResourcesMutation, DeleteResourcesMutationVariables>({
			mutation: GQL_DELETE_RESOURCES,
			variables: {
				resourceIds,
			},
		});
	}

	public async deleteType(resourceTypeIds: string | string[]) {
		resourceTypeIds = Array.isArray(resourceTypeIds) ? resourceTypeIds : [resourceTypeIds];

		await this._apolloClient.mutate<DeleteResourceTypesMutation, DeleteResourceTypesMutationVariables>({
			mutation: GQL_DELETE_RESOURCE_TYPES,
			variables: {
				resourceTypeIds,
			},
		});
	}

	public getAllByCategoryId(categoryIds: string | string[]) {
		if (typeof categoryIds === 'string') {
			categoryIds = [];
		}

		const request = ApolloUtils.abortableQuery<
			ResourcesByCategoryIdQuery,
			ResourceListFragment[],
			ResourcesByCategoryIdQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_RESOURCES_FOR_LIST_BY_CATEGORY,
				variables: {
					categoryIds,
				},
			},
			(data) => data.resourceCategories.map((ac) => ac.resource),
			this._registerAbort,
		);

		return request;
	}

	public getAllUsages(resourceId: string) {
		const request = ApolloUtils.abortableQuery<
			ResourceUsagesQuery,
			ResourceUsageFragment[],
			ResourceUsagesQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_RESOURCE_USAGES,
				variables: {
					resourceId,
				},
			},
			(data) => data.resourceCategories,
			this._registerAbort,
		);

		return request;
	}

	public async addResourceToCategory(
		resourceId: string,
		categoryId: string,
		sortOrder?: number,
	): Promise<ResourceCategoryListFragment> {
		const result = await this._apolloClient.mutate<
			AddResourceToCategoryMutation,
			AddResourceToCategoryMutationVariables
		>({
			mutation: GQL_ADD_RESOURCE_TO_CATEGORY,
			variables: {
				resourceId,
				categoryId,
				sortOrder: sortOrder ?? 0,
			},
		});
		if (!result.data?.upsertOneResourceCategory) {
			throw new Error('Failed to add resource to category');
		}
		return result.data.upsertOneResourceCategory;
	}

	public async removeResourceFromCategory(resourceId: string, categoryId: string): Promise<void> {
		const result = await this._apolloClient.mutate<
			RemoveResourceFromCategoryMutation,
			RemoveResourceFromCategoryMutationVariables
		>({
			mutation: GQL_REMOVE_RESOURCE_FROM_CATEGORY,
			variables: {
				resourceId,
				categoryId,
			},
		});
		if (!result.data?.deleteOneResourceCategory) {
			throw new Error('Failed to remove resource from category');
		}
	}

	public async updateIsPinned(resourceCategoryId: string, isPinned: boolean) {
		const result = await this._apolloClient.mutate<
			UpdateResourceCategoryIsPinnedMutation,
			UpdateResourceCategoryIsPinnedMutationVariables
		>({
			mutation: GQL_UPDATE_RESOURCE_CATEGORY_IS_PINNED,
			variables: {
				resourceCategoryId,
				isPinned,
			},
		});
		if (!result.data?.updateOneResourceCategory) {
			throw new Error('Failed to update resource category');
		}
		return result.data.updateOneResourceCategory;
	}

	public async updateIsRequired(resourceCategoryId: string, isRequired: boolean) {
		const result = await this._apolloClient.mutate<
			UpdateResourceCategoryIsRequiredMutation,
			UpdateResourceCategoryIsRequiredMutationVariables
		>({
			mutation: GQL_UPDATE_RESOURCE_CATEGORY_IS_REQUIRED,
			variables: {
				resourceCategoryId,
				isRequired,
			},
		});
		if (!result.data?.updateOneResourceCategory) {
			throw new Error('Failed to update resource category');
		}
		return result.data.updateOneResourceCategory;
	}
}

export function useResourceService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new ResourceService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
