import React, { useCallback } from 'react';
import { useResourceService } from '../../services/ResourceService';
import { ResourceUsageFragment } from '../../graphql/graphql';
import { ActionIcon, Button, Container, Group, Stack, Text } from '@mantine/core';
import CrudTable, { CrudTableColumn, useCrudState } from '../crud/CrudTable';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { IconEye, IconUnlink } from '@tabler/icons-react';
import { closeModal } from '@mantine/modals';
import { openConfirmModal } from '../modals/ConfirmModal';

export type ResourceUsageModalProps = {
	className?: string;
	modalId: string;
	resourceId: string;
	onNavigate: (programmeId: string) => void;
	onUnlink: (resourceId: string, categoryId: string) => Promise<void>;
};

export default function ResourceUsageModal(props: ResourceUsageModalProps) {
	const { className, modalId, resourceId, onNavigate, onUnlink } = props;

	const resourceService = useResourceService();

	const fetchResourceUsage = useCallback((): AbortableRequest<ResourceUsageFragment[]> | null => {
		return resourceService.getAllUsages(resourceId);
	}, [resourceService, resourceId]);

	const crudState = useCrudState<ResourceUsageFragment, ResourceUsageFragment>(fetchResourceUsage);

	const handleCancelClicked = () => {
		closeModal(modalId);
	};

	const handleNavigateClicked = (programmeId: string) => {
		setTimeout(() => onNavigate(programmeId), 0);
		closeModal(modalId);
	};

	const handleUnlinkClicked = async (usage: ResourceUsageFragment) => {
		await openConfirmModal({
			title: 'Unlink resource',
			danger: true,
			children: (
				<Text>
					Are you sure you want to unlink this resource from the category &quot;{usage.category.name}&quot;?
				</Text>
			),
			onConfirm: async () => {
				await onUnlink(resourceId, usage.category.id);
				crudState.delete(usage);
			},
		});
	};

	const crudColumns = (usage: ResourceUsageFragment): CrudTableColumn[] => [
		{
			children: usage.category.name,
		},
		{
			children: usage.category.programme.name,
		},
		{
			children: (
				<Group justify="flex-end">
					<ActionIcon
						color="blue"
						title="Open programme"
						variant="subtle"
						onClick={() => handleNavigateClicked(usage.category.programme.id)}
					>
						<IconEye />
					</ActionIcon>
					<ActionIcon
						color="red"
						title="Unlink resource"
						variant="subtle"
						onClick={() => handleUnlinkClicked(usage)}
					>
						<IconUnlink />
					</ActionIcon>
				</Group>
			),
		},
	];

	return (
		<Container mih="64px" className={`ResourceUsageModal ${className ?? ''}`}>
			<Stack>
				<CrudTable
					items={crudState.items}
					isLoading={crudState.isFetching}
					headers={crudHeaders}
					columns={crudColumns}
					padding={0}
					entityName="usages"
				/>
				<Group justify="flex-end">
					<Button disabled={crudState.isFetching} onClick={handleCancelClicked}>
						Close
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Category',
	},
	{
		children: 'Programme',
	},
	{
		children: '',
	},
];
