import React, { useCallback } from 'react';
import { useReportService } from '../../services/ReportService';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Divider, Loader, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import styled from '@emotion/styled';
import { DateInput } from '@mantine/dates';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import dayjs from 'dayjs';
import { FinancialReportRequest } from '@dr-pam/common-components/Models/Reports';
import CsvUtils from '@dr-pam/common-components/Utils/CsvUtils';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export type FinancialReportProps = {
	className?: string;
};

export default function FinancialReport(props: FinancialReportProps) {
	const { className } = props;

	const { isLoading, addLoader, removeLoader } = useLoadTracker();

	const { isInitialLoading } = useAuthenticatedUser();

	const reportService = useReportService();

	const fetchIncome = useCallback(
		async (startDate: string, endDate: string) => {
			const loader = addLoader();
			try {
				const request = reportService.getMonthlyProductIncome(
					dayjs(startDate).tz('UTC', true).toISOString(),
					dayjs(endDate).tz('UTC', true).toISOString(),
				);
				const response = await request.response;

				if (response.csvRows.length > 0) {
					const url = URL.createObjectURL(CsvUtils.convertToCsv(response));
					const link = document.createElement('a');
					link.href = url;
					link.download = `financial_report_from_${dayjs(startDate).format('DD-MM-YYYY')}_to_${dayjs(
						endDate,
					).format('DD-MM-YYYY')}.csv`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					URL.revokeObjectURL(url);
				} else {
					throw new Error('No records found for the selected date range');
				}
			} catch (err) {
				NotificationUtils.showError(err as Error, 'Failed to create report');
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, reportService, removeLoader],
	);

	const form = useForm<FinancialReportRequest>({
		initialValues: {
			startDate: '',
			endDate: '',
		},
		validate: {
			startDate: (value) => {
				if (value === null) {
					return 'Start date is required';
				} else {
					const isValidDate = dayjs(value).isValid();
					return isValidDate ? undefined : 'Invalid start date';
				}
			},
			endDate: (value, values) => {
				if (value === null) {
					return 'End date is required';
				} else {
					const isValidDate = dayjs(value).isValid();
					const isStartDateValid = dayjs(values.startDate).isValid();

					if (!isValidDate) {
						return 'Invalid end date';
					} else if (isStartDateValid && dayjs(value).isBefore(dayjs(values.startDate))) {
						return 'End date must be greater or equal to start date';
					}
				}

				return undefined;
			},
		},
	});

	const handleSubmit = async (values: FinancialReportRequest) => {
		await fetchIncome(values.startDate, values.endDate);
	};

	const isDisabled = isLoading || isInitialLoading;

	return (
		<StyledExportOptions className={`ExportOptions ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Text size="lg" fw={700}>
						Financial Report
					</Text>
					<Divider />
					<Text size="sm" fw={500}>
						Generate a report containing all revenue figures within the selected date range. The data will
						be grouped by month and by product.
					</Text>
					<Divider />
					<DateInput
						{...form.getInputProps('startDate')}
						label="Start Date"
						description="Enter a date in the following format: DD-MM-YYYY"
						valueFormat="DD-MM-YYYY"
						popoverProps={{ withinPortal: true }}
						disabled={isLoading}
						withAsterisk
						clearable
					/>
					<DateInput
						{...form.getInputProps('endDate')}
						label="End Date"
						description="Enter a date in the following format: DD-MM-YYYY"
						valueFormat="DD-MM-YYYY"
						popoverProps={{ withinPortal: true }}
						disabled={isLoading}
						withAsterisk
						clearable
					/>
					<Divider />
					<Button type="submit" className="flex ac jc gxs" disabled={isDisabled}>
						<span>Create Financial Report (CSV)</span>
						{(isLoading || isInitialLoading) && <Loader size="xs" ml="xs" />}
					</Button>
				</Stack>
			</form>
		</StyledExportOptions>
	);
}

const StyledExportOptions = styled.div`
	form {
		width: 420px;
	}
`;
