import React, { useState } from 'react';
import { ActionIcon, Group, LoadingOverlay, Menu, Tabs, Text, Tooltip } from '@mantine/core';
import Content from '../Content';
import {
	ProductProgramme,
	ProductSubscriptionType,
	ProductWithProgrammesAndSubscriptionTypes,
	useProductService,
} from '../../services/ProductService';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import NotFound from '../page/NotFound';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import AddProductProgrammeModal from './AddProductProgrammeModal';
import {
	ProductProgrammeFragment,
	ProductProgrammePrerequisiteFragment,
	ProductSubscriptionTypeListFragment,
} from '../../graphql/graphql';
import { IconArrowMerge, IconBooks, IconCoin, IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import AddProductSubscriptionTypeModal from './AddProductSubscriptionTypeModal';
import EditProductSubscriptionTypeModal from './EditProductSubscriptionTypeModal';
import { modals } from '@mantine/modals';
import ConfirmModal from '../modals/ConfirmModal';
import StatusBadge from '../content/StatusBadge';
import AddProductPrerequisiteModal from './AddProductPrerequisiteModal';

export type EditProductProps = {
	className?: string;
	productId: string;
};

export default function EditProduct(props: EditProductProps) {
	const { className, productId } = props;

	const productService = useProductService();

	const [selectedTab, setSelectedTab] = useState<string>('programmes');
	const [product, setProduct] = useState<ProductWithProgrammesAndSubscriptionTypes | undefined | null>();
	const programmesCrudState = useCrudState<ProductProgrammeFragment, ProductProgrammeFragment>();
	const prerequisitesCrudState = useCrudState<
		ProductProgrammePrerequisiteFragment,
		ProductProgrammePrerequisiteFragment
	>();
	const subscriptionTypesCrudState = useCrudState<ProductSubscriptionType, ProductSubscriptionType>();

	const programmesCrudHandler = useStandardCrudHandlers({
		crudState: programmesCrudState,
		entityName: 'Programme',
		nameAccessor: (pp) => pp.programme.name,
		addModalFactory: (props) => (
			<AddProductProgrammeModal
				{...props}
				productId={productId}
				excludeIds={programmesCrudState.items?.map((p) => p.programmeId)}
				onManyCreated={(productProgrammes) =>
					programmesCrudState.setItems((existing) => [...(existing ?? []), ...productProgrammes])
				}
			/>
		),
		delete: (pp) => productService.removeProgramme(productId, pp.programmeId),
	});

	const prerequisitesCrudHandler = useStandardCrudHandlers({
		crudState: prerequisitesCrudState,
		entityName: 'Prerequisite',
		nameAccessor: (prereq) => prereq.programme.name,
		addModalFactory: (props) => (
			<AddProductPrerequisiteModal
				{...props}
				productId={productId}
				onManyCreated={(prerequisites) =>
					prerequisitesCrudState.setItems((existing) => [...(existing ?? []), ...prerequisites])
				}
			/>
		),
		delete: (prereq) => productService.removePrerequisites(prereq.id),
	});

	const subscriptionTypesCrudHandler = useStandardCrudHandlers({
		crudState: subscriptionTypesCrudState,
		entityName: 'Payment/Subscription Type',
		nameAccessor: (pst) => pst.name,
		addModalFactory: (props) => <AddProductSubscriptionTypeModal {...props} productId={productId} />,
		editModalFactory: (props) => <EditProductSubscriptionTypeModal {...props} />,
		delete: (pst) => productService.deleteProductSubscriptionType(pst.id),
	});

	const isLoadingProduct = useLoadingEffect(async () => {
		try {
			const product = await productService.getWithProgrammesAndSubscriptionTypes(productId).response;
			setProduct(product);
			programmesCrudState.setItems(product?.productProgrammes ?? []);
			prerequisitesCrudState.setItems(product?.prerequisites ?? []);
			subscriptionTypesCrudState.setItems(product?.productSubscriptionTypes ?? []);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load product.');
		}
	}, [productService, productId]);

	if (product === undefined || isLoadingProduct) {
		return <LoadingOverlay visible={isLoadingProduct} overlayProps={{ blur: 2 }} />;
	}

	if (product === null) {
		return <NotFound />;
	}

	const handleTabChanged = (tab: string | null) => {
		if (!tab) {
			return;
		}

		setSelectedTab(tab);
	};

	const handleTogglePublished = (pst: ProductSubscriptionTypeListFragment) => {
		const modalId = 'publish-product-subscription-type';
		if (pst.isPublished) {
			modals.open({
				modalId,
				title: 'Un-publish subscription option',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Un-publish"
						onConfirm={async () => {
							const updatedPst = await productService.updateProductSubscriptionType(pst.id, {
								isPublished: { set: false },
							});
							subscriptionTypesCrudState.setItems(
								(subscriptionTypesCrudState.items ?? []).map((item) =>
									item.id === updatedPst.id ? updatedPst : item,
								),
							);
						}}
						danger
					>
						Are you sure you want to un-publish the subscription option &quot;{pst.name}&quot;?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Publish subscription option',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Publish"
						onConfirm={async () => {
							const updatedPst = await productService.updateProductSubscriptionType(pst.id, {
								isPublished: { set: true },
							});
							subscriptionTypesCrudState.setItems(
								(subscriptionTypesCrudState.items ?? []).map((item) =>
									item.id === updatedPst.id ? updatedPst : item,
								),
							);
						}}
					>
						Are you sure you want to publish the subscription option &quot;{pst.name}&quot;?
					</ConfirmModal>
				),
			});
		}
	};

	const programmesCrudColumns = (pp: ProductProgramme): CrudTableColumn[] => [
		{
			children: pp.programme.name,
		},
		{
			children: <StatusBadge status={pp.programme.isPublished ? 'published' : 'draft'} />,
		},
		{
			children: (
				<Group justify="flex-end">
					<Tooltip label="Remove">
						<ActionIcon color="red" variant="subtle">
							<IconTrash onClick={() => programmesCrudHandler.deleteHandler(pp)} />
						</ActionIcon>
					</Tooltip>
				</Group>
			),
		},
	];

	const prerequisitesCrudColumns = (prereq: ProductProgrammePrerequisiteFragment): CrudTableColumn[] => [
		{
			children: prereq.programme.name,
		},
		{
			children: <StatusBadge status={prereq.programme.isPublished ? 'published' : 'draft'} />,
		},
		{
			children: (
				<Group justify="flex-end">
					<Tooltip label="Remove">
						<ActionIcon color="red" variant="subtle">
							<IconTrash onClick={() => prerequisitesCrudHandler.deleteHandler(prereq)} />
						</ActionIcon>
					</Tooltip>
				</Group>
			),
		},
	];

	const subscriptionTypesCrudColumns = (pst: ProductSubscriptionType): CrudTableColumn[] => [
		{
			children: pst.name,
		},
		{
			children:
				pst.durationInDays === null ? (
					'Unlimited'
				) : (
					<>
						{pst.durationInDays} {pst.automaticRenew ? ' (renews automatically)' : ''}
					</>
				),
		},
		{
			children: <>${(pst.priceInCents / 100).toFixed(2)}</>,
		},
		{
			children: (
				<StatusBadge
					status={pst.isPublished ? 'published' : 'draft'}
					onClick={() => handleTogglePublished(pst)}
				/>
			),
		},
		{
			children: (
				<Menu shadow="md" width={200}>
					<Menu.Target>
						<Group justify="flex-end">
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Group>
					</Menu.Target>

					<Menu.Dropdown>
						<Menu.Item
							leftSection={<IconPencil />}
							onClick={() => subscriptionTypesCrudHandler.editHandler(pst)}
						>
							Edit
						</Menu.Item>
						<Menu.Item
							leftSection={<IconTrash />}
							onClick={() => subscriptionTypesCrudHandler.deleteHandler(pst)}
							color="red"
						>
							Delete
						</Menu.Item>
					</Menu.Dropdown>
				</Menu>
			),
		},
	];

	return (
		<Content
			className={`EditProduct ${className ?? ''}`}
			breadcrumbs={{ '/products': 'Products', '#': product.name }}
			title={product.name}
			fillHeight
		>
			<Tabs value={selectedTab} onChange={handleTabChanged}>
				<Tabs.List mb="md">
					<Tabs.Tab value="programmes" leftSection={<IconBooks />}>
						Included Programmes
					</Tabs.Tab>
					<Tabs.Tab value="prerequisites" leftSection={<IconArrowMerge />}>
						Prerequisite Programmes
					</Tabs.Tab>
					<Tabs.Tab value="subscription-types" leftSection={<IconCoin />}>
						Payment/Subscription Options
					</Tabs.Tab>
				</Tabs.List>
			</Tabs>
			{selectedTab === 'programmes' && (
				<>
					<Text mt="xl">
						The following programmes will be available for use when this product is purchased.
					</Text>
					<CrudTable
						items={programmesCrudState.items}
						isLoading={programmesCrudState.isFetching}
						headers={programmesCrudHeaders}
						columns={programmesCrudColumns}
						entityName="programme"
						onAddClicked={programmesCrudHandler.addHandler}
					/>
				</>
			)}
			{selectedTab === 'prerequisites' && (
				<>
					<Text mt="xl">
						This product will not be available for purchase until the following programmes have been{' '}
						<strong>completed</strong> by the user.
					</Text>
					<CrudTable
						items={prerequisitesCrudState.items}
						isLoading={prerequisitesCrudState.isFetching}
						headers={prerequisitesCrudHeaders}
						columns={prerequisitesCrudColumns}
						entityName="prerequisite"
						onAddClicked={prerequisitesCrudHandler.addHandler}
					/>
				</>
			)}
			{selectedTab === 'subscription-types' && (
				<>
					<Text mt="xl">This following payment/subscription options are available for this product.</Text>
					<CrudTable
						items={subscriptionTypesCrudState.items}
						isLoading={subscriptionTypesCrudState.isFetching}
						headers={subscriptionTypesCrudHeaders}
						columns={subscriptionTypesCrudColumns}
						entityName="payment/subscription option"
						onAddClicked={subscriptionTypesCrudHandler.addHandler}
					/>
				</>
			)}
		</Content>
	);
}

const programmesCrudHeaders: CrudTableColumn[] = [
	{
		children: 'Name',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];

const prerequisitesCrudHeaders: CrudTableColumn[] = [
	{
		children: 'Name',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];

const subscriptionTypesCrudHeaders: CrudTableColumn[] = [
	{
		children: 'Name',
	},
	{
		children: 'Duration (days)',
	},
	{
		children: 'Price',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];
