import React, { useRef, useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { Container, Group, Text } from '@mantine/core';

export type LogLevel = 'log' | 'error' | 'warn' | 'debug' | 'verbose' | 'fatal';

export type OnLogsAvailablePayload = {
	level: LogLevel;
	log: string;
	context?: string;
};

export type ExportLogProps = {
	className?: string;
};

const LOG_COLORS: Record<LogLevel, string> = {
	log: 'gray',
	error: 'red',
	warn: 'orange',
	debug: 'blue',
	verbose: 'green',
	fatal: 'purple',
};

export default function ExportLog(props: ExportLogProps) {
	const { className } = props;

	const socket = useRef<Socket | null>(null);
	const [isConnected, setIsConnected] = useState(false);
	const [logs, setLogs] = useState<OnLogsAvailablePayload[]>([]);

	const onConnect = () => {
		setIsConnected(true);
	};

	const onDisconnect = () => {
		setIsConnected(false);
	};

	const onLogsAvailable = (log: OnLogsAvailablePayload) => {
		setLogs((logs) => [log, ...logs]);
	};

	useEffect(() => {
		socket.current = io('/', {
			path: '/ws/export',
			transports: ['websocket', 'webtransport', 'polling'],
			autoConnect: true,
		});

		socket.current.on('connect', onConnect);
		socket.current.on('disconnect', onDisconnect);
		socket.current.on('onLogsAvailable', onLogsAvailable);

		return () => {
			if (socket.current) {
				socket.current.off('connect', onConnect);
				socket.current.off('disconnect', onDisconnect);
				socket.current.off('onLogsAvailable', onLogsAvailable);

				socket.current.disconnect();
				socket.current = null;
			}
		};
	}, []);

	return (
		<Container className={`ExportLog ${className ?? ''}`} p="sm" m="sm" ff="monospace">
			{isConnected ? null : <Text c="red">Not connected</Text>}
			{logs.map((log, index) => (
				<Group key={index} c={LOG_COLORS[log.level]}>
					{log.level.toUpperCase()}
					{log.context && <Text c="gray">[{log.context}]</Text>}
					{log.log}
				</Group>
			))}
		</Container>
	);
}
