import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import { useParams } from 'react-router-dom';
import NotFound from '../../components/page/NotFound';
import EditUser from '../../components/users/EditUser';

export default function UserPage() {
	const { userId } = useParams<{ userId: string }>();

	return <BackendPage>{userId ? <EditUser userId={userId} /> : <NotFound />}</BackendPage>;
}
