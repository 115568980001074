import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import ArticlesList from '../../components/articles/ArticlesList';

export default function ArticlesPage() {
	return (
		<BackendPage title="Articles">
			<ArticlesList />
		</BackendPage>
	);
}
