import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import ExportOptions from '../../components/content/ExportOptions';
import Content from '../../components/Content';

export default function ExportPage() {
	return (
		<BackendPage title="Export" centerChildren>
			<Content>
				<ExportOptions />
			</Content>
		</BackendPage>
	);
}
