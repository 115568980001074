import React, { useCallback, useState } from 'react';
import { usePaymentService } from '../../services/PaymentService';
import { Menu, ActionIcon, Tooltip, Text, Badge } from '@mantine/core';
import { IconBrandStripe, IconDotsVertical, IconFileDollar, IconMail } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { PaymentListFragment } from '../../graphql/graphql';
import CurrencyUtils from '@dr-pam/common-components/Utils/CurrencyUtils';
import CrudTable, { useCrudState, CrudTableColumn } from '../crud/CrudTable';
import DateTimeUtils, { DATE_FORMAT, DATE_TIME_FORMAT } from '@dr-pam/common-components/Utils/DateTimeUtils';
import { openConfirmModal } from '../modals/ConfirmModal';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import CouponUtils from '@dr-pam/common-components/Utils/CouponUtils';

export type PaymentsListProps = {
	className?: string;
};

export default function PaymentsList(props: PaymentsListProps) {
	const { className } = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');

	const paymentService = usePaymentService();

	const fetchPayments = useCallback(() => {
		if (searchQuery) {
			return paymentService.search(searchQuery);
		} else {
			return paymentService.getAll();
		}
	}, [paymentService, searchQuery]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const crudState = useCrudState<PaymentListFragment, PaymentListFragment>(fetchPayments);

	const handleViewInvoiceClicked = (payment: PaymentListFragment) => {
		window.open(`/api/payment/${payment.id}/invoice`, '_blank');
	};

	const handleViewInStripeClicked = (payment: PaymentListFragment) => {
		window.open(
			`https://dashboard.stripe.com/payments/${payment.stripePaymentIntents[0].paymentIntentId}`,
			'_blank',
		);
	};

	const handleResendInvoiceClicked = (payment: PaymentListFragment) => {
		openConfirmModal({
			title: 'Resend Invoice',
			children: <>Are you sure you want to resend this invoice to {payment.user.email}</>,
			confirmText: 'Resend',
			onConfirm: async () => {
				try {
					const request = paymentService.resendInvoice(payment.id);
					await request.response;
					NotificationUtils.showSuccess(`Sent to ${payment.user.email}`, 'Invoice resent');
				} catch (err) {
					NotificationUtils.showError(err as Error, 'Failed to resend invoice');
				}
			},
		});
	};

	const crudColumns = (payment: PaymentListFragment): CrudTableColumn[] => {
		const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
		const productSubscriptionType =
			payment.paymentItems.length > 0 ? payment.paymentItems[0].productSubscriptionType : null;
		const paymentIntent = payment.stripePaymentIntents[0];
		const discountInCents = payment.couponDiscountAppliedInCents;
		const chargedInCents = paymentIntent?.chargedInCents;
		const taxPaidInCents = paymentIntent?.taxPaidInCents;
		const feesPaidInCents = paymentIntent?.feesPaidInCents;
		const netInCents = paymentIntent?.netInCents;
		return [
			{
				children: (
					<Tooltip label={DateTimeUtils.format(payment.modified, DATE_TIME_FORMAT)}>
						<span>{DateTimeUtils.format(payment.modified, DATE_FORMAT)}</span>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip label={payment.user?.email}>
						<span>{payment.user?.displayName}</span>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip label={productSubscriptionType?.product?.name}>
						<span>{productSubscriptionType?.product?.shortName}</span>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip label={regionNames.of(payment.billingCountryCode)}>
						<span>{payment.billingCountryCode}</span>
					</Tooltip>
				),
			},
			{
				children:
					discountInCents && payment.coupon ? (
						<Tooltip
							label={
								<>
									{payment.coupon?.code} - {CouponUtils.couponToString(payment.coupon)}
								</>
							}
						>
							<span>{CurrencyUtils.format(discountInCents)}</span>
						</Tooltip>
					) : (
						''
					),
			},
			{
				children: chargedInCents ? (
					<Tooltip
						label={
							<Text>
								{CurrencyUtils.format(payment.amountInCents)} {payment.currency}
								{payment.taxInCents > 0 && (
									<>
										<br />
										(incl {CurrencyUtils.format(payment.taxInCents)} GST)
									</>
								)}
							</Text>
						}
					>
						<span>{CurrencyUtils.format(chargedInCents)}</span>
					</Tooltip>
				) : (
					''
				),
			},
			{
				children:
					netInCents && feesPaidInCents && taxPaidInCents ? (
						<Tooltip
							label={
								<Text>
									<strong>Stripe fees:</strong> {CurrencyUtils.format(feesPaidInCents)}
									<br />
									<strong>GST:</strong> {CurrencyUtils.format(taxPaidInCents)}
								</Text>
							}
						>
							<span>{CurrencyUtils.format(netInCents)}</span>
						</Tooltip>
					) : (
						''
					),
			},
			{
				children: (
					<Badge color={payment.isInvoiceSent ? 'green' : 'red'}>
						{payment.isInvoiceSent ? 'Yes' : 'No'}
					</Badge>
				),
			},
			{
				children: (
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								leftSection={<IconFileDollar />}
								onClick={() => handleViewInvoiceClicked(payment)}
							>
								View Invoice
							</Menu.Item>
							<Menu.Item leftSection={<IconMail />} onClick={() => handleResendInvoiceClicked(payment)}>
								Resend Invoice
							</Menu.Item>
							<Menu.Item
								leftSection={<IconBrandStripe />}
								onClick={() => handleViewInStripeClicked(payment)}
							>
								View in Stripe
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				),
			},
		];
	};

	return (
		<CrudTable
			className={className}
			items={crudState.items}
			headers={crudHeaders}
			columns={crudColumns}
			entityName="payment"
			search={{
				onSearchRequested: handleSearchRequested,
				onSearchCleared: handleSearchRequested,
				initialValue: searchQuery,
			}}
			isLoading={crudState.isFetching}
		/>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Date',
	},
	{
		children: 'User',
	},
	{
		children: 'Product',
	},
	{
		children: 'Billing Country',
	},
	{
		children: 'Discount',
	},
	{
		children: 'Amount Charged',
	},
	{
		children: 'Amount Net',
	},
	{
		children: 'Invoice Sent',
	},
	{
		children: '',
	},
];
