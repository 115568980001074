import React, { useState } from 'react';
import { Container, Button, Stack, Group } from '@mantine/core';
import { modals } from '@mantine/modals';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { QuizCategoryListFragment } from '../../graphql/graphql';
import SearchQuiz from './SearchQuiz';
import { useQuizService } from '../../services/QuizService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type SelectQuizModalProps = {
	modalId: string;
	categoryId: string;
	onCancel?: () => void;
	onSelected: (quiz: QuizCategoryListFragment) => void;
};

export default function SelectQuizModal(props: SelectQuizModalProps) {
	const { modalId, categoryId, onCancel, onSelected } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const [selectedQuizId, setSelectedQuizId] = useState<string | undefined>(undefined);

	const quizService = useQuizService();

	const handleLinkClicked = async () => {
		if (!selectedQuizId) {
			return;
		}
		const loader = addLoader();
		try {
			const linkedQuizCategory = await quizService.addQuizToCategory(selectedQuizId, categoryId);
			onSelected(linkedQuizCategory);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to link quiz');
		} finally {
			removeLoader(loader);
			modals.close(modalId);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<Stack>
				<SearchQuiz onSelected={(a) => setSelectedQuizId(a?.id)} />
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button
						type="submit"
						disabled={isLoading || selectedQuizId === undefined}
						onClick={handleLinkClicked}
					>
						Link selected quiz
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}
