import React from 'react';
import { Center, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import { useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';

export default function LogoutPage() {
	const navigate = useNavigate();
	const authService = useAuthService();

	const { user } = useAuthenticatedUser();

	useEffect(() => {
		if (user) {
			authService.signOut();
		} else {
			notifications.show({
				message: 'Successfully logged out',
			});

			navigate('/', {
				replace: true,
			});
		}
	}, [authService, navigate, user]);

	return (
		<Center h="100%">
			<Loader />
		</Center>
	);
}
