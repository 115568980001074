import React from 'react';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { AddQuizForm } from './AddQuizModal';
import useComputedSlug from '../../hooks/useComputedSlug';
import { QuizListFragment, QuizSingleFragment } from '../../graphql/graphql';
import { useQuizService } from '../../services/QuizService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';

export const validationSchema = z.object({
	title: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
});

export type EditQuizModalProps = {
	modalId: string;
	current: QuizListFragment;
	onCancel: () => void;
	onEdited: (quiz: QuizSingleFragment) => void;
};

export default function EditQuizModal(props: EditQuizModalProps) {
	const { modalId, current, onCancel, onEdited } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const quizService = useQuizService();

	const form = useForm<EditQuizForm>({
		initialValues: {
			title: current.title,
			slug: current.slug,
			description: current.description ?? '',
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditQuizForm) => {
		const loader = addLoader();

		try {
			const updatedQuiz = await quizService.update(current.id, {
				title: { set: values.title },
				slug: { set: values.slug },
				description: { set: values.description },
			});
			onEdited(updatedQuiz);
			NotificationUtils.showSuccess('Successfully updated quiz', updatedQuiz.title);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update quiz');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'title');

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...slugComputedFromProps}
						label="Title"
						description="The title of the quiz. Forms the basis of the slug."
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="The description of the quiz. This will appear before they attempt it."
						disabled={isLoading}
						withAsterisk
						rows={5}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Save quiz {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type EditQuizForm = Omit<AddQuizForm, 'categoryId'>;
