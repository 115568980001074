import React, { useCallback } from 'react';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import { ActionIcon, Button, Flex, Menu } from '@mantine/core';
import AddProgrammeModal from './AddProgrammeModal';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import EditProgrammeModal from './EditProgrammeModal';
import ConfirmModal from '../modals/ConfirmModal';
import { modals } from '@mantine/modals';
import { useProgrammeProvider } from './ProgrammesProvider';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ProgrammeListFragment, ProgrammeSingleFragment } from '../../graphql/graphql';
import { useProgrammeService } from '../../services/ProgrammeService';
import StatusBadge from '../content/StatusBadge';

export default function ProgrammesList() {
	const programmeProvider = useProgrammeProvider();
	const programmeService = useProgrammeService();

	const fetchProgrammes = useCallback(() => programmeService.getAll(), [programmeService]);

	const crudState = useCrudState<ProgrammeListFragment, ProgrammeSingleFragment>(fetchProgrammes);

	useDeepCompareEffect(() => {
		if (crudState.items !== undefined) {
			programmeProvider.setProgrammes(crudState.items);
		}
	}, [crudState.items ?? []]);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'programme',
		nameAccessor: (programme) => programme.name,
		addModalFactory: (props) => <AddProgrammeModal {...props} />,
		editModalFactory: (props) => <EditProgrammeModal {...props} />,
		delete: (programme) => programmeService.delete(programme.id),
	});

	const crudColumns = (programme: ProgrammeListFragment): CrudTableColumn[] => [
		{
			children: programme.name,
		},
		{
			children: programme.shortName,
		},
		{
			children: (
				<StatusBadge
					status={programme.isPublished ? 'published' : 'draft'}
					onClick={() => handleTogglePublished(programme)}
				/>
			),
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={`/programmes/${programme.id}`}>
						<Button variant="subtle">View</Button>
					</Link>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(programme)}>
								Edit
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(programme)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	const handleTogglePublished = (programme: ProgrammeListFragment) => {
		const modalId = 'publish-programme';
		if (programme.isPublished) {
			modals.open({
				modalId,
				title: 'Un-publish programme',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Un-publish"
						onConfirm={async () => {
							const updatedprogramme = await programmeService.update(programme.id, {
								isPublished: { set: false },
							});
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedprogramme.id ? updatedprogramme : item,
								),
							);
						}}
						danger
					>
						Are you sure you want to un-publish the programme {programme.name}?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Publish programme',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Publish"
						onConfirm={async () => {
							const updatedprogramme = await programmeService.update(programme.id, {
								isPublished: { set: true },
							});
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedprogramme.id ? updatedprogramme : item,
								),
							);
						}}
					>
						Are you sure you want to publish the programme {programme.name}?
					</ConfirmModal>
				),
			});
		}
	};

	return (
		<CrudTable
			items={crudState.items}
			headers={crudHeaders}
			columns={crudColumns}
			entityName="programme"
			onAddClicked={crudHandlers.addHandler}
		/>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Name',
	},
	{
		children: 'Short name',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];
