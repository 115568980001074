import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import ResourcesList from '../../components/resources/ResourcesList';
import { useParams } from 'react-router-dom';

export default function ResourcesPage() {
	const params = useParams<{ resourceId?: string }>();
	if (params.resourceId) {
		setTimeout(() => {
			window.location.href = `/api/resource/${params.resourceId}`;
		}, 0);
		return null;
	}
	return (
		<BackendPage title="Resources">
			<ResourcesList />
		</BackendPage>
	);
}
