import React, { useCallback } from 'react';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import { ResourceTypeListFragment } from '../../graphql/graphql';
import { useResourceService } from '../../services/ResourceService';
import { Menu, ActionIcon, Flex } from '@mantine/core';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import AddResourceTypeModal from './AddResourceTypeModal';
import EditResourceTypeModal from './EditResourceTypeModal';

export type ResourceTypeListProps = {
	className?: string;
};

export default function ResourceTypeList(props: ResourceTypeListProps) {
	const { className } = props;

	const resourceService = useResourceService();

	const fetchResources = useCallback(() => resourceService.getAllTypes(), [resourceService]);

	const crudState = useCrudState<ResourceTypeListFragment, ResourceTypeListFragment>(fetchResources);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'resource type',
		nameAccessor: (resource) => resource.name,
		addModalFactory: (props) => <AddResourceTypeModal {...props} />,
		editModalFactory: (props) => <EditResourceTypeModal {...props} />,
		delete: (resource) => resourceService.deleteType(resource.id),
	});

	const crudColumns = (resource: ResourceTypeListFragment): CrudTableColumn[] => [
		{
			children: resource.name,
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(resource)}>
								Edit
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(resource)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	return (
		<div className={`ResourceTypeList ${className ?? ''}`}>
			<CrudTable
				items={crudState.items}
				isLoading={crudState.isFetching}
				headers={crudHeaders}
				columns={crudColumns}
				entityName="resource type"
				onAddClicked={crudHandlers.addHandler}
			/>
		</div>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Name',
	},
	{
		children: '',
	},
];
