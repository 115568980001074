import { useMemo } from 'react';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import {
	CouponCreateInput,
	CouponListFragment,
	CouponQuery,
	CouponQueryVariables,
	CouponSingleFragment,
	CouponUpdateInput,
	CouponsQuery,
	CreateCouponMutation,
	CreateCouponMutationVariables,
	DeleteCouponsMutation,
	DeleteCouponsMutationVariables,
	UpdateCouponMutation,
	UpdateCouponMutationVariables,
} from '../graphql/graphql';
import IPublishable from '@dr-pam/common-components/Services/IPublishable';

export const GQL_FRAG_COUPON_LIST = gql`
	fragment CouponList on Coupon {
		id
		isPublished
		code
		description
		percentage
		fixedAmountInCents
		discountType
		validFrom
		validTo
		maxUses
	}
`;

export const GQL_FRAG_COUPON_SINGLE = gql`
	fragment CouponSingle on Coupon {
		id
		isPublished
		code
		description
		percentage
		fixedAmountInCents
		discountType
		validFrom
		validTo
		maxUses
	}
`;

export const GQL_GET_COUPON = gql`
	${GQL_FRAG_COUPON_SINGLE}
	query Coupon($couponId: String!) {
		coupon(where: { id: $couponId }) {
			...CouponSingle
		}
	}
`;

export const GQL_GET_COUPONS_FOR_LIST = gql`
	${GQL_FRAG_COUPON_LIST}
	query Coupons {
		coupons {
			...CouponList
		}
	}
`;

export const GQL_DELETE_COUPONS = gql`
	mutation DeleteCoupons($couponIds: [String!]) {
		deleteManyCoupon(where: { id: { in: $couponIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_COUPON = gql`
	${GQL_FRAG_COUPON_SINGLE}
	mutation CreateCoupon($coupon: CouponCreateInput!) {
		createOneCoupon(data: $coupon) {
			...CouponSingle
		}
	}
`;

export const GQL_UPDATE_COUPON = gql`
	${GQL_FRAG_COUPON_SINGLE}
	mutation UpdateCoupon($couponId: String!, $coupon: CouponUpdateInput!) {
		updateOneCoupon(where: { id: $couponId }, data: $coupon) {
			...CouponSingle
		}
	}
`;

export default class CouponService
	implements ICrudService<CouponListFragment, CouponSingleFragment>, IPublishable<CouponListFragment>
{
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public async publish(couponId: string): Promise<CouponListFragment> {
		return await this.update(couponId, { isPublished: { set: true } });
	}

	public async unpublish(couponId: string): Promise<CouponListFragment> {
		return await this.update(couponId, { isPublished: { set: false } });
	}

	public get(couponId: string) {
		const request = ApolloUtils.abortableQuery<CouponQuery, CouponSingleFragment | null, CouponQueryVariables>(
			this._apolloClient,
			{
				query: GQL_GET_COUPON,
				variables: {
					couponId,
				},
			},
			(data) => data.coupon ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getAll() {
		const request = ApolloUtils.abortableQuery<CouponsQuery, CouponListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_COUPONS_FOR_LIST,
			},
			(data) => data.coupons,
			this._registerAbort,
		);

		return request;
	}

	public async create(coupon: CouponCreateInput) {
		const result = await this._apolloClient.mutate<CreateCouponMutation, CreateCouponMutationVariables>({
			mutation: GQL_CREATE_COUPON,
			variables: {
				coupon,
			},
		});
		if (!result.data?.createOneCoupon) {
			throw new Error('Failed to create coupon');
		}
		return result.data.createOneCoupon;
	}

	public async update(couponId: string, coupon: CouponUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateCouponMutation, UpdateCouponMutationVariables>({
			mutation: GQL_UPDATE_COUPON,
			variables: {
				couponId,
				coupon,
			},
		});
		if (!result.data?.updateOneCoupon) {
			throw new Error('Failed to update coupon');
		}
		return result.data.updateOneCoupon;
	}

	public async delete(couponIds: string | string[]) {
		couponIds = Array.isArray(couponIds) ? couponIds : [couponIds];
		await this._apolloClient.mutate<DeleteCouponsMutation, DeleteCouponsMutationVariables>({
			mutation: GQL_DELETE_COUPONS,
			variables: {
				couponIds,
			},
		});
	}
}

export function useCouponService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new CouponService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
