import React, { useEffect, useState } from 'react';
import { CrudEditModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Container, TextInput, Button, Loader, Group, Select } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { useArticleService } from '../../services/ArticleService';
import useComputedSlug from '../../hooks/useComputedSlug';
import { AddArticleForm } from './AddArticleModal';
import { ArticleListFragment, ArticleSingleFragment, ResourceTypeListFragment } from '../../graphql/graphql';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useResourceService } from '../../services/ResourceService';
import z from 'zod';

export const validationSchema = z.object({
	title: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
});

export type EditArticleModalProps = CrudEditModalProps<ArticleListFragment, ArticleSingleFragment>;

export default function EditArticleModal(props: EditArticleModalProps) {
	const { modalId, onCancel, onEdited, current } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [resourceTypes, setResourceTypes] = useState<ResourceTypeListFragment[]>([]);

	const articleService = useArticleService();
	const resourceService = useResourceService();

	useEffect(() => {
		const loader = addLoader();
		const request = resourceService.getAllTypes();
		request.response
			.then((types) => {
				setResourceTypes(types);
			})
			.catch((err) => {
				NotificationUtils.showError(err as Error, 'Failed to load resource types');
			})
			.finally(() => {
				removeLoader(loader);
			});
	}, [addLoader, removeLoader, resourceService]);

	const form = useForm<EditArticleForm>({
		initialValues: {
			title: current.title,
			slug: current.slug,
			resourceTypeId: current.resourceTypeId,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditArticleForm) => {
		const loader = addLoader();

		try {
			const updatedArticle = await articleService.update(current.id, {
				title: { set: values.title },
				slug: { set: values.slug },
				type: values.resourceTypeId
					? {
							connect: {
								id: values.resourceTypeId,
							},
					  }
					: undefined,
			});
			onEdited(updatedArticle);
			NotificationUtils.showSuccess('Successfully updated article', updatedArticle.title);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update article');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'title');

	const resourceTypeData = resourceTypes.map((resourceType) => ({
		value: resourceType.id,
		label: resourceType.name,
	}));

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<TextInput
					{...slugComputedFromProps}
					label="Title"
					description="The title of the article. Forms the basis of the Slug"
					disabled={isLoading}
					withAsterisk
				/>
				<TextInput
					{...form.getInputProps('slug')}
					mt="md"
					label="Slug"
					description="This will appear in the browser URL. Can't be changed."
					disabled={isLoading}
					withAsterisk
				/>
				<Select
					{...form.getInputProps('resourceTypeId')}
					data={resourceTypeData}
					label="Resource type"
					placeholder={isLoading ? 'Loading...' : '--- Please select ---'}
					disabled={isLoading}
				/>
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button type="submit" disabled={isLoading}>
						Save article {isLoading && <Loader size="xs" ml="xs" />}
					</Button>
				</Group>
			</form>
		</Container>
	);
}

export type EditArticleForm = Omit<AddArticleForm, 'categoryId'>;
