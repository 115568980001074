import React from 'react';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { AddEventForm } from './AddEventModal';
import useComputedSlug from '../../hooks/useComputedSlug';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useEventService } from '../../services/EventService';
import { EventListFragment, EventSingleFragment } from '../../graphql/graphql';
import z from 'zod';

export const validationSchema = z.object({
	title: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
	description: z.string().nonempty(),
});

export type EditEventModalProps = {
	modalId: string;
	current: EventListFragment;
	onCancel: () => void;
	onEdited: (event: EventSingleFragment) => void;
};

export default function EditEventModal(props: EditEventModalProps) {
	const { modalId, current, onCancel, onEdited } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const eventService = useEventService();

	const form = useForm<EditEventForm>({
		initialValues: {
			title: current.title,
			slug: current.slug,
			description: current.description,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditEventForm) => {
		const loader = addLoader();

		try {
			const updatedEvent = await eventService.update(current.id, {
				title: { set: values.title },
				slug: { set: values.slug },
				description: { set: values.description },
			});

			onEdited(updatedEvent);
			NotificationUtils.showSuccess('Successfully updated event', updatedEvent.title);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update event');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'title');

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...slugComputedFromProps}
						label="Name"
						description="The name of the event."
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="The description of the event. Include Zoom links."
						disabled={isLoading}
						withAsterisk
						rows={5}
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Save event {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type EditEventForm = Omit<AddEventForm, 'categoryId'>;
