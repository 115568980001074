import React from 'react';
import { CrudEditModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, NumberInput, Select, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';
import { CouponDiscountType, CouponListFragment, CouponSingleFragment } from '../../graphql/graphql';
import { useCouponService } from '../../services/CouponService';
import { IconCurrencyDollar, IconPercentage } from '@tabler/icons-react';
import { AddCouponForm } from './AddCouponModal';

const validationSchema = z
	.object({
		code: z.string().nonempty('Code is required').max(512),
		description: z.string().max(512),
		discountType: z.string().nonempty(),
		percentage: z.coerce.string().length(0).or(z.coerce.number().min(0).max(100)),
		fixedAmount: z.coerce.string().length(0).or(z.coerce.number().positive()),
	})
	.refine(
		(data) => {
			if (data.discountType === CouponDiscountType.Percentage) {
				if (data.percentage !== '') {
					const parsed = parseFloat(`${data.percentage}`);
					const isValid = !isNaN(parsed) && parsed >= 0 && parsed <= 100;
					return isValid;
				}
				return false;
			}
			return true;
		},
		{
			message: 'Invalid percentage',
			path: ['percentage'],
		},
	)
	.refine(
		(data) => {
			if (data.discountType === CouponDiscountType.FixedAmount) {
				if (data.fixedAmount !== '') {
					const parsed = parseFloat(`${data.fixedAmount}`);
					const isValid = !isNaN(parsed) && parsed >= 0;
					console.log(parsed, isValid);
					return isValid;
				}
				return false;
			}
			return true;
		},
		{
			message: 'Invalid amount',
			path: ['fixedAmount'],
		},
	);

export type EditCouponModalProps = CrudEditModalProps<CouponListFragment, CouponSingleFragment>;

export default function EditCouponModal(props: EditCouponModalProps) {
	const { modalId, current, onCancel, onEdited } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const couponService = useCouponService();

	const form = useForm<EditCouponForm>({
		initialValues: {
			code: current.code,
			description: current.description ?? '',
			discountType: current.discountType,
			fixedAmount: current.fixedAmountInCents ? current.fixedAmountInCents / 100 : '',
			percentage: current.percentage ? current.percentage * 100 : '',
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditCouponForm) => {
		const loader = addLoader();
		try {
			const updatedCoupon = await couponService.update(current.id, {
				code: { set: values.code },
				description: { set: values.description },
				discountType: { set: values.discountType },
				percentage: {
					set:
						values.discountType === CouponDiscountType.Percentage
							? parseFloat(`${values.percentage}`) * 0.01
							: null,
				},
				fixedAmountInCents: {
					set:
						values.discountType === CouponDiscountType.FixedAmount
							? parseFloat(`${values.fixedAmount}`) * 100
							: null,
				},
			});
			onEdited(updatedCoupon);
			NotificationUtils.showSuccess('Successfully updated coupon', updatedCoupon.code);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update coupon');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...form.getInputProps('code')}
						type="code"
						label="Discount Code"
						description="The code to enter during checkout"
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="A description of the coupon"
						disabled={isLoading}
					/>
					<Select
						{...form.getInputProps('discountType')}
						data={Object.values(CouponDiscountType)}
						label="Discount Type"
						description="The type of discount to apply"
						placeholder={'--- Please select ---'}
						disabled={isLoading}
						withAsterisk
					/>
					{form.values.discountType === CouponDiscountType.Percentage ? (
						<NumberInput
							{...form.getInputProps('percentage')}
							label="Percentage Discount"
							description="The percentage discount amount (0 - 100)"
							min={0}
							max={100}
							step={1}
							disabled={isLoading}
							rightSection={<IconPercentage />}
							withAsterisk
						/>
					) : (
						<NumberInput
							{...form.getInputProps('fixedAmount')}
							label="Fixed Discount Price (AUD)"
							description="The discount amount in dollars"
							min={0}
							decimalScale={2}
							decimalSeparator="."
							thousandSeparator=","
							disabled={isLoading}
							leftSection={<IconCurrencyDollar />}
							withAsterisk
						/>
					)}
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Add coupon {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type EditCouponForm = AddCouponForm;
