import styled from '@emotion/styled';
import { Badge, BadgeProps, DefaultMantineColor } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';
import React from 'react';

export type StatusBadgeProps = {
	className?: string;
	status: 'draft' | 'changed' | 'published' | 'required';
	onClick?: () => void;
};

const STATUS_COLORS: Record<StatusBadgeProps['status'], DefaultMantineColor> = {
	draft: 'gray',
	changed: 'yellow',
	published: 'green',
	required: 'cyan',
};

const STATUS_TEXT: Record<StatusBadgeProps['status'], string> = {
	draft: 'Draft',
	changed: 'Changed',
	published: 'Published',
	required: 'Mandatory',
};

export default function StatusBadge(props: StatusBadgeProps) {
	const { className, status, onClick } = props;

	return (
		<StyledBadge
			className={`StatusBadge ${onClick ? 'pointer' : ''} ${className ?? ''}`}
			color={STATUS_COLORS[status]}
			onClick={onClick}
		>
			{STATUS_TEXT[status]}
		</StyledBadge>
	);
}

const StyledBadge = styled(Badge)<PolymorphicComponentProps<'div', BadgeProps>>`
	&.pointer {
		cursor: pointer;
	}
`;
