import React, { MouseEventHandler, createContext, useContext, useEffect } from 'react';
import { NavLink } from '@mantine/core';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { IconChevronRight } from '@tabler/icons-react';
import styled from '@emotion/styled';
import { useForceUpdate } from '@mantine/hooks';

export type NavigationContext = {
	isOpen: Record<string, boolean>;
};

function getNavContextFromLocalStorage() {
	const item = localStorage.getItem('navContext');
	if (item) {
		const navContext = JSON.parse(item) as NavigationContext;
		if (!navContext || typeof navContext != 'object' || !navContext.isOpen) {
			return {
				isOpen: {},
			} as NavigationContext;
		}
		return navContext;
	}
	return {
		isOpen: {},
	} as NavigationContext;
}

function setNavContextToLocalStorage(navContext: NavigationContext) {
	localStorage.setItem('navContext', JSON.stringify(navContext));
}

const navigationContext = createContext<NavigationContext>(getNavContextFromLocalStorage());

export type NavLinkProps = {
	id: string;
	label: React.ReactNode;
	icon?: React.ReactNode;
	link?: string;
	links?: NavLinkProps[];
	exactMatch?: boolean;
	onSort?: (sortedLinks: NavLinkProps[]) => Promise<void>;
	sortable?: boolean;
	sortOrder?: number;
};

export type NavigationLinkProps = {
	className?: string;
	link: NavLinkProps;
};

export default function NavigationLink(props: NavigationLinkProps) {
	const { className, link } = props;

	const location = useLocation();
	const navigate = useNavigate();
	const forceUpdate = useForceUpdate();

	const navContext = useContext(navigationContext);

	const isOpen = navContext.isOpen[link.id] ?? false;

	const [sortableChildLinks, setSortableChildLinks] = React.useState<NavLinkProps[]>([]);

	const hasChildren = link.links && link.links.length > 0;
	const areChildrenSortable = hasChildren && link.sortable;

	useEffect(() => {
		if (link.links && areChildrenSortable) {
			const sortableLinks = [...link.links];
			sortableLinks.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
			setSortableChildLinks(sortableLinks);
		}
	}, [areChildrenSortable, link.links]);

	let isActive = false;
	if (link.link) {
		if (link.exactMatch) {
			isActive = location.pathname === link.link;
		} else {
			isActive = location.pathname.startsWith(link.link);
		}
	}

	const handleParentLinkClicked: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
		if (link.link) {
			e.stopPropagation();
			e.preventDefault();
			navigate(link.link);
		}
	};

	const handleReorder = (orderedLinks: NavLinkProps[]) => {
		orderedLinks.forEach((l, i) => {
			l.sortOrder = i;
		});
		setSortableChildLinks([...orderedLinks]);
	};

	const handleReorderCompleted = async () => {
		await link.onSort?.(sortableChildLinks);
	};

	const handleToggleOpen: MouseEventHandler<SVGSVGElement> = (e) => {
		e.stopPropagation();
		e.preventDefault();
		navContext.isOpen[link.id] = !isOpen;
		setNavContextToLocalStorage(navContext);
		forceUpdate();
	};

	let childLinksContent = null;
	if (link.links) {
		if (link.sortable) {
			childLinksContent = (
				<ReactSortable
					list={sortableChildLinks}
					setList={handleReorder}
					onEnd={handleReorderCompleted}
					animation={150}
					swapThreshold={0.5}
					direction="vertical"
					handle=".reorder-handle"
				>
					{sortableChildLinks.map((l, i) => (
						<StyledNavigationLink key={i} link={l} />
					))}
				</ReactSortable>
			);
		} else {
			childLinksContent = link.links.map((l, i) => <StyledNavigationLink key={i} link={l} />);
		}
	}

	let rightSection = null;
	if (link.links) {
		rightSection = <IconChevronRight onClick={handleToggleOpen} />;
	}

	const content = (
		<NavLink
			label={link.label}
			leftSection={link.icon}
			active={isActive}
			childrenOffset="0"
			rightSection={rightSection}
			opened={isOpen}
		>
			{childLinksContent}
		</NavLink>
	);

	if (link.link) {
		return (
			<StyledLink
				to={link.link}
				className={`NavigationLink ${className ?? ''}`}
				onClick={handleParentLinkClicked}
			>
				{content}
			</StyledLink>
		);
	} else {
		return <div className={`NavigationLink ${className ?? ''}`}>{content}</div>;
	}
}

const StyledNavigationLink = styled(NavigationLink)({
	'> a': {
		padding: '0 var(--mantine-spacing-sm) 0 var(--mantine-spacing-xl)',
	},
});

const StyledLink = styled(Link)({
	textDecoration: 'none',
	color: 'unset',
});
