import React from 'react';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { Button, Checkbox, Divider, Loader, Text, Stack, TextInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import styled from '@emotion/styled';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import ExportLog from './ExportLog';

export type ExportOptionsProps = {
	className?: string;
};

export default function ExportOptions(props: ExportOptionsProps) {
	const { className } = props;

	const { isLoading, addLoader, removeLoader } = useLoadTracker();

	const { user, isInitialLoading } = useAuthenticatedUser();

	const form = useForm<ExportOptionsType>({
		initialValues: {
			exportArticles: true,
			exportQuizzes: true,
			exportResources: false,
			includeMarkdown: true,
			includePdf: true,
			email: user?.email ?? '',
		},
		validate: {
			email: isNotEmpty('Email is required'),
		},
	});

	const handleSubmit = async (values: ExportOptionsType) => {
		const loader = addLoader();
		try {
			await FetchUtils.postJson('/api/export', values).response;
			NotificationUtils.showSuccess(
				'Your export will be emailed to you when it is ready',
				'Successfully initiated export',
			);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to initiate export');
		} finally {
			removeLoader(loader);
		}
	};

	const isDisabled = isLoading || isInitialLoading;

	return (
		<StyledExportOptions className={`ExportOptions ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...form.getInputProps('email')}
						type="email"
						label="Where should the export be sent?"
						description="You will be emailed the export after it has been generated"
						autoComplete="email"
						disabled={isDisabled}
						required
					/>
					<Divider />
					<Text size="sm" fw={500}>
						What do you want to export?
						<Text size="xs" c="gray">
							At least one is required
						</Text>
					</Text>
					<Checkbox
						{...form.getInputProps('exportArticles', { type: 'checkbox' })}
						label="Export Articles"
						name="exportArticles"
						disabled={isDisabled}
					/>
					<Checkbox
						{...form.getInputProps('exportQuizzes', { type: 'checkbox' })}
						label="Export Quizzes"
						name="exportQuizzes"
						disabled={isDisabled}
					/>
					<Checkbox
						{...form.getInputProps('exportResources', { type: 'checkbox' })}
						label="Export Resources"
						name="exportResources"
						disabled={isDisabled}
					/>
					<Divider />
					<Text size="sm" fw={500}>
						For each of the above, what do you want to include?
						<Text size="xs" c="gray">
							At least one is required
						</Text>
					</Text>
					<Checkbox
						{...form.getInputProps('includeMarkdown', { type: 'checkbox' })}
						label="Include Markdown"
						name="includeMarkdown"
						disabled={isDisabled}
					/>
					<Checkbox
						{...form.getInputProps('includePdf', { type: 'checkbox' })}
						label="Include PDF"
						name="includePdf"
						disabled={isDisabled}
					/>
					<Divider />
					<Button type="submit" className="flex ac jc gxs" disabled={isDisabled}>
						<span>Initiate Export</span>
						{(isLoading || isInitialLoading) && <Loader size="xs" ml="xs" />}
					</Button>
					<Text size="xs" c="gray">
						This process can take minutes to hours depending on the size of your content.
					</Text>
				</Stack>
			</form>
			<ExportLog />
		</StyledExportOptions>
	);
}

const StyledExportOptions = styled.div`
	form {
		width: 420px;
	}
`;

export type ExportOptionsType = {
	exportArticles: boolean;
	exportQuizzes: boolean;
	exportResources: boolean;
	includeMarkdown: boolean;
	includePdf: boolean;
	email: string;
};
