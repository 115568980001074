import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import { useParams } from 'react-router-dom';
import NotFound from '../../components/page/NotFound';
import EditProduct from '../../components/products/EditProduct';

export default function ProductPage() {
	const { productId } = useParams<{ productId: string }>();

	return <BackendPage>{productId ? <EditProduct productId={productId} /> : <NotFound />}</BackendPage>;
}
