import React from 'react';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Checkbox, Container, Group, Loader, Stack, TextInput, Textarea, Text } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { AddProgrammeForm } from './AddProgrammeModal';
import useComputedSlug from '../../hooks/useComputedSlug';
import { ProgrammeListFragment, ProgrammeSingleFragment } from '../../graphql/graphql';
import { useProgrammeService } from '../../services/ProgrammeService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';

export const validationSchema = z.object({
	name: z.string().nonempty().max(512),
	shortName: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
	description: z.string().nonempty(),
});

export type EditProgrammeModalProps = {
	modalId: string;
	current: ProgrammeListFragment;
	onCancel: () => void;
	onEdited: (programme: ProgrammeSingleFragment) => void;
};

export default function EditProgrammeModal(props: EditProgrammeModalProps) {
	const { modalId, current, onCancel, onEdited } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const programmeService = useProgrammeService();

	const form = useForm<EditProgrammeForm>({
		initialValues: {
			name: current.name,
			shortName: current.shortName,
			slug: current.slug,
			description: current.description,
			isHidden: current.isHidden,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditProgrammeForm) => {
		const loader = addLoader();

		try {
			const updatedProgramme = await programmeService.update(current.id, {
				name: { set: values.name },
				shortName: { set: values.shortName },
				slug: { set: values.slug },
				description: { set: values.description },
				isHidden: { set: values.isHidden },
			});

			onEdited(updatedProgramme);
			NotificationUtils.showSuccess('Successfully updated programme', updatedProgramme.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update programme');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'name');

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)} method="post" action="/api/programmes">
				<Stack>
					<TextInput
						{...slugComputedFromProps}
						label="Name"
						description="The name of the programme. Forms the basis of the Slug."
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('shortName')}
						label="Short name"
						description="The name to display when space is limited."
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="The description of the programme."
						disabled={isLoading}
						withAsterisk
						rows={5}
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Checkbox
						{...form.getInputProps('isHidden', { type: 'checkbox' })}
						label={
							<Text fw={500} size={'sm'}>
								Hide from menus and lists
							</Text>
						}
						description="If checked, this programme will be hidden from generic menus and lists of programmes."
						disabled={isLoading}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Save programme {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type EditProgrammeForm = AddProgrammeForm;
