import React, { useEffect } from 'react';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { TextInput, PasswordInput, Button, Loader, Title, Stack, Group } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import { useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';

type LoginForm = {
	email: string;
	password: string;
};

export type LoginProps = {
	className?: string;
	email?: string;
};

export default function Login(props: LoginProps) {
	const { className, email } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const location = useLocation();
	const navigate = useNavigate();
	const authService = useAuthService();

	const { user } = useAuthenticatedUser();

	const redirectTo = location.state?.redirectTo;

	const form = useForm<LoginForm>({
		initialValues: {
			email: email ?? '',
			password: '',
		},
		validate: {
			email: isEmail('Must be a valid email address'),
			password: isNotEmpty('Required'),
		},
	});

	useEffect(() => {
		if (user) {
			notifications.show({
				color: 'green',
				title: 'Success',
				message: 'Successfully signed in!',
			});

			if (redirectTo) {
				navigate(redirectTo, { replace: true });
			} else {
				navigate('/', { replace: true });
			}
		}
	}, [authService, redirectTo, navigate, user]);

	const handleFormSubmit = async (loginForm: LoginForm) => {
		const loader = addLoader();

		try {
			const user = await authService.signIn(loginForm.email, loginForm.password);
			if (!user) {
				notifications.show({
					color: 'red',
					title: 'Error',
					message: 'Invalid email or password',
				});
				form.setFieldValue('password', '');
				removeLoader(loader);
			}
		} catch (error) {
			console.error(error);
			removeLoader(loader);
		}
	};

	return (
		<div className={`Login ${className}`}>
			<form onSubmit={form.onSubmit(handleFormSubmit)}>
				<Stack>
					<Title order={4} c="grey">
						Dr Pam
					</Title>
					<Title order={2}>Please login</Title>
					<TextInput
						{...form.getInputProps('email')}
						label="Email"
						disabled={isLoading}
						autoComplete="email"
						withAsterisk
					/>
					<PasswordInput
						{...form.getInputProps('password')}
						label="Password"
						disabled={isLoading}
						autoComplete="current-password"
						withAsterisk
					/>
					<Group justify="flex-end" mt="md">
						<Button type="submit" disabled={isLoading}>
							Login {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</div>
	);
}
