export interface Hashable {
	getHash?: () => string;
}

export type SortableTreeEvent = 'changed' | 'childAdded' | 'childRemoved' | 'childrenChanged';

const LOCALSTORAGE_KEY = 'sortable-tree-state';

export type SortableTreePersistedState = {
	isCollapsed: boolean;
};

export function saveSortableTreeState(id: string, state: SortableTreePersistedState) {
	localStorage.setItem(`${LOCALSTORAGE_KEY}-${id}`, JSON.stringify(state));
}

export function loadSortableTreeState(id: string): SortableTreePersistedState {
	const stateStr = localStorage.getItem(`${LOCALSTORAGE_KEY}-${id}`);

	if (stateStr) {
		try {
			const state = JSON.parse(stateStr) as SortableTreePersistedState;
			if (state && typeof state.isCollapsed === 'boolean') {
				return state;
			}
		} catch (e) {
			// Ignore
		}
	}

	// Otherwise return default state
	return { isCollapsed: false };
}

export abstract class SortableTreeItem<T> implements Hashable {
	public static compareBySortOrder<T>(a: SortableTreeItem<T>, b: SortableTreeItem<T>): number {
		return a.getSortOrder() - b.getSortOrder();
	}

	public item: T;
	public children: SortableTreeItem<unknown>[];
	public parent: SortableTreeItem<unknown> | null = null;
	public prevParent: SortableTreeItem<unknown> | null = null;

	private _eventListeners: Map<SortableTreeEvent, Array<() => void>> = new Map();

	private _originalHash: string | null = null;
	private _state: SortableTreePersistedState;

	constructor(item: T, parent: SortableTreeItem<unknown> | null = null) {
		this.item = item;
		this.children = [];

		if (parent) {
			parent.addChild(this, this.getSortOrder());
		}

		this._originalHash = this.getHash();

		this._state = loadSortableTreeState(this.getId());
	}

	get id() {
		return this.getId();
	}

	get root(): SortableTreeItem<unknown> {
		if (this.parent) {
			return this.parent.root;
		} else {
			return this;
		}
	}

	public addEventListener(event: SortableTreeEvent, callback: () => void) {
		if (!this._eventListeners.has(event)) {
			this._eventListeners.set(event, []);
		}
		this._eventListeners.get(event)?.push(callback);

		return () => {
			this.removeEventListener(event, callback);
		};
	}

	public removeEventListener(event: SortableTreeEvent, callback: () => void): void {
		if (this._eventListeners.has(event)) {
			const listeners = this._eventListeners.get(event);
			const index = listeners?.indexOf(callback);
			if (index !== undefined && index !== -1) {
				listeners?.splice(index, 1);
			}
		}
	}

	public dispatchEvent(event: SortableTreeEvent): void {
		if (this._eventListeners.has(event)) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			for (const callback of this._eventListeners.get(event)!) {
				callback();
			}
		}
	}

	public getDepth(): number {
		if (this.parent) {
			return 1 + this.parent.getDepth();
		} else {
			return 0;
		}
	}

	public findChild(id: string): SortableTreeItem<unknown> | null {
		if (this.getId() === id) {
			return this;
		} else {
			for (const child of this.children) {
				const result = child.findChild(id);
				if (result) {
					return result;
				}
			}
			return null;
		}
	}

	public addChild(child: SortableTreeItem<unknown>, index?: number): void {
		if (index === undefined) {
			child.setSortOrder(this.children.length);
			this.children.push(child);
		} else {
			if (this.children[index] !== undefined) {
				this.children.splice(index, 0, child);
			} else {
				this.children[index] = child;
			}
		}
		child.prevParent = child.parent;
		child.parent = this;

		// Filter out nulls/undefineds
		this.children = this.children.filter((x) => x);

		this.dispatchEvent('childAdded');
		this.dispatchEvent('childrenChanged');
	}

	public removeChild(id: string): void {
		const index = this.children.findIndex((x) => x.getId() === id);
		if (index >= 0) {
			this.children.splice(index, 1);
			for (let i = index; i < this.children.length; i++) {
				this.children[i].setSortOrder(i);
			}
			this.dispatchEvent('childRemoved');
			this.dispatchEvent('childrenChanged');
		}
	}

	public hasChanged() {
		return this._originalHash !== this.getHash();
	}

	public getChangedChildren(): SortableTreeItem<unknown>[] {
		const changedChildren: SortableTreeItem<unknown>[] = [];

		for (const child of this.children) {
			if (child.hasChanged()) {
				changedChildren.push(child);
			}
			changedChildren.push(...child.getChangedChildren());
		}

		return changedChildren;
	}

	get isCollapsed() {
		return this._state.isCollapsed;
	}

	set isCollapsed(value: boolean) {
		this._state.isCollapsed = value;

		saveSortableTreeState(this.getId(), this._state);

		// if (this.children.length > 0) {
		// 	for (const child of this.children) {
		// 		child.isCollapsed = value;
		// 	}
		// }

		this.dispatchEvent('changed');
	}

	public resetChangedState() {
		this._originalHash = this.getHash();
	}

	public abstract getId(): string;

	public abstract getHash(): string;

	public abstract getName(): string;

	public abstract getSortOrder(): number;
	public abstract setSortOrder(sortOrder: number): void;
}
