import React from 'react';
import { Button, Checkbox, FileButton, Flex, Group, Loader, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type ImportFromWordModalProps = {
	className?: string;
	modalId: string;
	onConfirm: (values: ImportFromWordForm) => Promise<void>;
};

export default function ImportFromWordModal(props: ImportFromWordModalProps) {
	const { className, modalId, onConfirm } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const form = useForm<ImportFromWordForm>({
		initialValues: {
			file: null,
			overwrite: false,
		},
	});

	const handleSubmit = async (values: ImportFromWordForm) => {
		const loader = addLoader();
		try {
			await onConfirm(values);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error');
			removeLoader(loader);
		}
	};

	const handleCancelClicked = () => {
		modals.close(modalId);
	};

	const handleFileSelected = (file: File | null) => {
		form.setFieldValue('file', file);
	};

	return (
		<div className={`ImportFromWordModal ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Flex align="flex-end" gap="sm">
						<TextInput
							value={form.values.file?.name ?? ''}
							label="Word document"
							description="The Word document to import"
							disabled={isLoading}
							style={{ flexGrow: 1 }}
							readOnly
						/>
						<FileButton accept=".docx" onChange={handleFileSelected} disabled={isLoading}>
							{(props) => <Button {...props}>Upload</Button>}
						</FileButton>
					</Flex>
					<Checkbox
						{...form.getInputProps('overwrite', { type: 'checkbox' })}
						label="Overwrite ALL existing content"
						disabled={isLoading}
					/>
				</Stack>
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancelClicked} disabled={isLoading}>
						Cancel
					</Button>
					<Button type="submit" disabled={isLoading}>
						Import Word Doc {isLoading && <Loader size="xs" ml="xs" />}
					</Button>
				</Group>
			</form>
		</div>
	);
}

type ImportFromWordForm = {
	file: File | null;
	overwrite: boolean;
};
