import StringUtils from '@dr-pam/common-components/Utils/StringUtils';
import { UseFormReturnType } from '@mantine/form';

export default function useComputedSlug<T extends object>(
	form: UseFormReturnType<T>,
	slugField: keyof T,
	computedFromField: keyof T,
) {
	const handleComputedFromChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const computedFromValue = e.currentTarget.value;

		let slugIsTouched = form.isTouched(slugField);
		if (
			slugIsTouched &&
			(form.values[slugField] === '' ||
				form.values[slugField] === StringUtils.slugifyForUrl(`${form.values[computedFromField]}`))
		) {
			form.resetTouched();
			slugIsTouched = false;
		}

		form.setValues({
			[computedFromField]: computedFromValue,
			[slugField]: slugIsTouched ? form.values[slugField] : StringUtils.slugifyForUrl(computedFromValue),
		} as Partial<T>);
	};

	const computedFromInputProps = {
		...form.getInputProps(computedFromField),
		onChange: handleComputedFromChanged,
	};

	return computedFromInputProps;
}
