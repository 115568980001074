import React from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useComputedSlug from '../../hooks/useComputedSlug';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Checkbox, Container, Group, Loader, Stack, TextInput, Textarea, Text } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { ProgrammeListFragment, ProgrammeSingleFragment } from '../../graphql/graphql';
import { useProgrammeProvider } from './ProgrammesProvider';
import { useProgrammeService } from '../../services/ProgrammeService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';

export const validationSchema = z.object({
	name: z.string().nonempty().max(512),
	shortName: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
	description: z.string().nonempty(),
});

export type AddProgrammeModalProps = CrudAddModalProps<ProgrammeListFragment, ProgrammeSingleFragment>;

export default function AddProgrammeModal(props: AddProgrammeModalProps) {
	const { modalId, onCancel, onCreated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const programmeContext = useProgrammeProvider();
	const programmeService = useProgrammeService();

	const form = useForm<AddProgrammeForm>({
		initialValues: {
			name: '',
			shortName: '',
			slug: '',
			description: '',
			isHidden: false,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: AddProgrammeForm) => {
		const loader = addLoader();

		try {
			const createdProgramme = await programmeService.create({
				...values,
				sortOrder: programmeContext.programmes.length,
			});
			onCreated(createdProgramme);
			NotificationUtils.showSuccess('Successfully created programme', createdProgramme.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create programme');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'name');

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...slugComputedFromProps}
						label="Name"
						description="The name of the programme. Forms the basis of the Slug."
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('shortName')}
						label="Short name"
						description="The name to display when space is limited."
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="The description of the programme."
						disabled={isLoading}
						withAsterisk
						rows={5}
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Checkbox
						{...form.getInputProps('isHidden', { type: 'checkbox' })}
						label={
							<Text fw={500} size={'sm'}>
								Hide from menus and lists
							</Text>
						}
						description="If checked, this programme will be hidden from generic menus and lists of programmes."
						disabled={isLoading}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Add programme {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddProgrammeForm = Pick<
	ProgrammeSingleFragment,
	'name' | 'shortName' | 'slug' | 'description' | 'isHidden'
>;
