import React from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, PasswordInput, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { UserForCreate, useUserService } from '../../services/UserService';
import type { User } from '@dr-pam/common-types/database';
import z from 'zod';

const validationSchema = z.object({
	email: z.string().nonempty('Email is required').email('Email is invalid').max(512),
	displayName: z.string().max(512),
	fullName: z.string().nonempty(),
	password: z.string().nonempty('Password is required').min(12, 'Password must be at least 12 characters long'),
});

export type AddUserModalProps = CrudAddModalProps<Omit<UserForCreate, 'password'>, User>;

export default function AddUserModal(props: AddUserModalProps) {
	const { modalId, onCancel, onCreated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const userService = useUserService();

	const form = useForm<AddUserForm>({
		initialValues: {
			email: '',
			fullName: '',
			displayName: '',
			password: '',
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: AddUserForm) => {
		const loader = addLoader();

		try {
			const request = userService.create(values);
			const createdUser = await request.response;
			onCreated(createdUser);
			NotificationUtils.showSuccess('Successfully created user', createdUser.displayName ?? createdUser.email);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create user');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...form.getInputProps('email')}
						type="email"
						label="Email"
						description="The email of the user"
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('fullName')}
						label="Full name"
						description="The full name of the user"
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('displayName')}
						label="Display name"
						description="First name or nickname"
						disabled={isLoading}
					/>
					<PasswordInput
						{...form.getInputProps('password')}
						label="Password"
						description="The password for the user. Must be at least 12 characters long."
						disabled={isLoading}
						withAsterisk
					/>
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Add user {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddUserForm = UserForCreate;
