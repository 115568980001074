import React from 'react';
import { Center, Title, Text, Stack, Button } from '@mantine/core';
import { Link } from 'react-router-dom';

export type NotAuthorisedProps = {
	className?: string;
};

export default function NotAuthorised(props: NotAuthorisedProps) {
	const { className } = props;

	return (
		<Center className={`NotAuthorised ${className ?? ''}`} h="100%">
			<Stack>
				<Title order={1}>Not Authorised</Title>
				<Text>Sorry, you don&apos;t have access to this area.</Text>
				<Link to="/logout">
					<Button>Sign out</Button>
				</Link>
			</Stack>
		</Center>
	);
}
