import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import { ArticleSortableTreeItem, GQL_FRAG_ARTICLE_CATEGORY_LIST } from './ArticleService';
import { GQL_FRAG_QUIZ_CATEGORY_LIST, QuizSortableTreeItem } from './QuizService';
import { GQL_FRAG_RESOURCE_CATEGORY_LIST, ResourceSortableTreeItem } from './ResourceService';
import hash from 'object-hash';
import {
	CategoryListWithContentFragment,
	CategoryListFragment,
	CategorySingleFragment,
	CategoryCreateInput,
	CreateCategoryMutation,
	CreateCategoryMutationVariables,
	CategoryUpdateInput,
	UpdateCategoryMutation,
	UpdateCategoryMutationVariables,
	CategoriesQuery,
	CategoryQuery,
	CategoryQueryVariables,
	CategoryListWithContentAndPrerequisitesFragment,
	DeleteCategoriesMutation,
	DeleteCategoriesMutationVariables,
	ExistingCategoryQuery,
	ExistingCategoryQueryVariables,
} from '../graphql/graphql';
import { SortableTreeItem } from '../models/SortableTreeItem';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { EventSortableTreeItem, GQL_FRAG_EVENT_CATEGORY_LIST } from './EventService';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export class CategorySortableTreeItem<
	T extends CategoryListWithContentAndPrerequisitesFragment | CategoryListFragment | CategorySingleFragment,
> extends SortableTreeItem<T> {
	public getId(): string {
		return this.item.id;
	}
	public getSortOrder(): number {
		return this.item.sortOrder;
	}
	public setSortOrder(sortOrder: number): void {
		this.item.sortOrder = sortOrder;
	}

	public getName(): string {
		if (this.parent) {
			return `${this.parent.getName()} > ${this.item.name}`;
		} else {
			return this.item.name;
		}
	}

	public getHash(): string {
		return hash([this.item.id, this.parent?.getId(), this.getSortOrder()]);
	}
}

export type CategorySortByName = Pick<CategoryListFragment, 'name'>;
export type CategorySortBySortOrder = Pick<CategoryListFragment, 'sortOrder'>;

export const GQL_FRAG_CATEGORY_LIST = gql`
	fragment CategoryList on Category {
		id
		name
		slug
		sortOrder
		parentId
	}
`;

export const GQL_FRAG_CATEGORY_SINGLE = gql`
	fragment CategorySingle on Category {
		id
		created
		modified
		name
		slug
		sortOrder
		parentId
	}
`;

export const GQL_FRAG_CATEGORY_LIST_WITH_CONTENT = gql`
	${GQL_FRAG_CATEGORY_LIST}
	${GQL_FRAG_ARTICLE_CATEGORY_LIST}
	${GQL_FRAG_QUIZ_CATEGORY_LIST}
	${GQL_FRAG_RESOURCE_CATEGORY_LIST}
	${GQL_FRAG_EVENT_CATEGORY_LIST}

	fragment CategoryListWithContent on Category {
		...CategoryList

		categoryArticles {
			...ArticleCategoryList
		}

		categoryQuizzes {
			...QuizCategoryList
		}

		categoryResources {
			...ResourceCategoryList
		}

		categoryEvents {
			...EventCategoryList
		}
	}
`;

export const GQL_GET_CATEGORY = gql`
	${GQL_FRAG_CATEGORY_SINGLE}
	query Category($categoryId: String!) {
		category(where: { id: $categoryId }) {
			...CategorySingle
		}
	}
`;

export const GQL_GET_CATEGORIES_FOR_LIST = gql`
	${GQL_FRAG_CATEGORY_LIST}
	query Categories {
		categories {
			...CategoryList
		}
	}
`;

export const GQL_EXISTING_CATEGORY_SLUG = gql`
	query ExistingCategory($id: String, $slug: String!, $programmeId: String!) {
		categories(
			where: { id: { not: { equals: $id } }, slug: { equals: $slug }, programmeId: { equals: $programmeId } }
		) {
			id
		}
	}
`;

export const GQL_DELETE_CATEGORIES = gql`
	mutation DeleteCategories($categoryIds: [String!]) {
		deleteManyCategory(where: { id: { in: $categoryIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_CATEGORY = gql`
	${GQL_FRAG_CATEGORY_SINGLE}
	mutation CreateCategory($category: CategoryCreateInput!) {
		createOneCategory(data: $category) {
			...CategorySingle
		}
	}
`;

export const GQL_UPDATE_CATEGORY = gql`
	${GQL_FRAG_CATEGORY_SINGLE}
	mutation UpdateCategory($categoryId: String!, $category: CategoryUpdateInput!) {
		updateOneCategory(where: { id: $categoryId }, data: $category) {
			...CategorySingle
		}
	}
`;

export default class CategoryService implements ICrudService<CategoryListFragment, CategorySingleFragment> {
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public get(categoryId: string) {
		const request = ApolloUtils.abortableQuery<
			CategoryQuery,
			CategorySingleFragment | null,
			CategoryQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_CATEGORY,
				variables: {
					categoryId,
				},
			},
			(data) => data.category ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getAll() {
		const request = ApolloUtils.abortableQuery<CategoriesQuery, CategoryListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_CATEGORIES_FOR_LIST,
			},
			(data) => data.categories,
			this._registerAbort,
		);

		return request;
	}

	public getAllInFlattenedTree(): AbortableRequest<CategorySortableTreeItem<CategoryListFragment>[]> {
		const request = this.getAll();
		return {
			abort: request.abort,
			response: new Promise<CategorySortableTreeItem<CategoryListFragment>[]>(async (resolve, reject) => {
				try {
					const response = await request.response;

					// Convert to a tree and back so we have a flat list of categories with parent/subcategories linked up
					const categoryTree = CategoryService.toTree(response);
					const categories = CategoryService.flattenTree(categoryTree);

					categories.sort(CategoryService.compareByTreeName);
					resolve(categories);
				} catch (err) {
					reject(err);
				}
			}),
		};
	}

	public async create(category: CategoryCreateInput) {
		const programmeId =
			category.programme?.connect?.id ??
			category.programme?.connectOrCreate?.create?.id ??
			category.programme?.connectOrCreate?.where?.id ??
			category.programme?.create?.id;
		if (!programmeId) {
			throw new Error('No programme ID provided');
		}
		const existingRequest = this.getExistingCategorySlug(null, category.slug, programmeId);
		const existing = await existingRequest.response;

		if (existing) {
			throw new Error('A category with this slug already exists in this programme');
		}

		const result = await this._apolloClient.mutate<CreateCategoryMutation, CreateCategoryMutationVariables>({
			mutation: GQL_CREATE_CATEGORY,
			variables: {
				category,
			},
		});
		if (!result.data?.createOneCategory) {
			throw new Error('Failed to create category');
		}
		return result.data.createOneCategory;
	}

	public async update(categoryId: string, category: CategoryUpdateInput) {
		if (category.programme && category.slug) {
			const programmeId =
				category.programme.connect?.id ??
				category.programme.connectOrCreate?.create?.id ??
				category.programme.create?.id ??
				category.programme.update?.data.id?.set ??
				category.programme.upsert?.create?.id ??
				category.programme.upsert?.update?.id?.set;

			if (!programmeId) {
				throw new Error('No programme ID provided');
			}

			const slug = category.slug.set;

			if (!slug) {
				throw new Error('No slug provided');
			}

			const existingRequest = this.getExistingCategorySlug(categoryId, slug, programmeId);
			const existing = await existingRequest.response;

			if (existing) {
				throw new Error('A category with this slug already exists in this programme');
			}
		}

		const result = await this._apolloClient.mutate<UpdateCategoryMutation, UpdateCategoryMutationVariables>({
			mutation: GQL_UPDATE_CATEGORY,
			variables: {
				categoryId,
				category,
			},
		});
		if (!result.data?.updateOneCategory) {
			throw new Error('Failed to update category');
		}
		return result.data.updateOneCategory;
	}

	public async delete(categoryIds: string | string[]) {
		categoryIds = Array.isArray(categoryIds) ? categoryIds : [categoryIds];
		await this._apolloClient.mutate<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>({
			mutation: GQL_DELETE_CATEGORIES,
			variables: {
				categoryIds,
			},
		});
	}

	public getExistingCategorySlug(currentId: string | null, slug: string, programmeId: string) {
		const request = ApolloUtils.abortableQuery<ExistingCategoryQuery, boolean, ExistingCategoryQueryVariables>(
			this._apolloClient,
			{
				query: GQL_EXISTING_CATEGORY_SLUG,
				variables: {
					// id: currentId,
					slug,
					programmeId,
				},
			},
			(data) => data.categories.length > 0,
			this._registerAbort,
		);
		return request;
	}

	public static compareBySortOrder(left: CategorySortBySortOrder, right: CategorySortBySortOrder) {
		return left.sortOrder - right.sortOrder;
	}

	public static compareByName(left: CategorySortByName, right: CategorySortByName) {
		return left.name.localeCompare(right.name);
	}

	public static compareBySortOrderThenName(
		left: CategorySortBySortOrder & CategorySortByName,
		right: CategorySortBySortOrder & CategorySortByName,
	) {
		return CategoryService.compareBySortOrder(left, right) || CategoryService.compareByName(left, right);
	}

	public static compareByTreeDepth(
		left: CategorySortableTreeItem<CategoryListFragment>,
		right: CategorySortableTreeItem<CategoryListFragment>,
	) {
		return left.getDepth() - right.getDepth();
	}

	public static compareByTreeName(
		left: CategorySortableTreeItem<CategoryListFragment>,
		right: CategorySortableTreeItem<CategoryListFragment>,
	) {
		return left.getName().localeCompare(right.getName());
	}

	public static flattenTree<T extends CategoryListFragment | CategorySingleFragment>(
		categories: CategorySortableTreeItem<T>[],
	) {
		const result: CategorySortableTreeItem<T>[] = [];
		for (const category of categories) {
			result.push(category);
			result.push(
				...CategoryService.flattenTree(
					category.children.filter(
						(x) => x instanceof CategorySortableTreeItem,
					) as CategorySortableTreeItem<T>[],
				),
			);
		}

		return result;
	}

	public static toTree<T extends CategoryListFragment | CategorySingleFragment | CategoryListWithContentFragment>(
		categories: T[],
	) {
		const map = new Map<string, CategorySortableTreeItem<T>>();
		const roots: CategorySortableTreeItem<T>[] = [];

		for (const category of categories) {
			map.set(category.id, new CategorySortableTreeItem(category));
		}

		for (const categorySortableTreeItem of map.values()) {
			if (categorySortableTreeItem.item.parentId && map.has(categorySortableTreeItem.item.parentId)) {
				const parent = map.get(categorySortableTreeItem.item.parentId);
				if (parent) {
					parent.addChild(categorySortableTreeItem, categorySortableTreeItem.getSortOrder());
					categorySortableTreeItem.resetChangedState();
				}
			} else {
				roots.push(categorySortableTreeItem);
				categorySortableTreeItem.resetChangedState();
			}

			if (isCategoryListWithContentFragment(categorySortableTreeItem.item)) {
				categorySortableTreeItem.item.categoryArticles.forEach((article) => {
					new ArticleSortableTreeItem(article, categorySortableTreeItem);
				});
				categorySortableTreeItem.item.categoryQuizzes.forEach((quiz) => {
					new QuizSortableTreeItem(quiz, categorySortableTreeItem);
				});
				categorySortableTreeItem.item.categoryResources.forEach((resource) => {
					new ResourceSortableTreeItem(resource, categorySortableTreeItem);
				});
				categorySortableTreeItem.item.categoryEvents.forEach((event) => {
					new EventSortableTreeItem(event, categorySortableTreeItem);
				});
			}
		}

		return roots;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCategoryListWithContentFragment(obj: any): obj is CategoryListWithContentFragment {
	return (
		obj &&
		typeof obj === 'object' &&
		'categoryArticles' in obj &&
		'categoryQuizzes' in obj &&
		'categoryResources' in obj
	);
}

export function useCategoryService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new CategoryService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
