import React, { useMemo, useState } from 'react';
import { AppShell, Avatar, Text, ScrollArea, Flex, Title, Stack, ActionIcon } from '@mantine/core';
import {
	IconBook2,
	IconBooks,
	IconCalendar,
	IconCertificate,
	IconCoin,
	IconFileDownload,
	IconFileText,
	IconGauge,
	IconUsers,
	IconArrowsMoveVertical,
	IconDatabaseExport,
	IconFileReport,
	IconTicket,
} from '@tabler/icons-react';
import NavigationLink, { NavLinkProps } from './NavigationLink';
import { Helmet } from 'react-helmet-async';
import { useProgrammeProvider } from '../programmes/ProgrammesProvider';
import RequireAuthenticated from '../auth/RequireAuthenticated';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import styled from '@emotion/styled';
import objectHash from 'object-hash';
import useRememberScrollTop from '@dr-pam/common-components/Hooks/useRememberScollTop';
import { useLocation } from 'react-router-dom';

export type BackendPageProps = {
	title?: string;
	description?: string;
	children?: React.ReactNode;
	centerChildren?: boolean;
};

export default function BackendPage(props: BackendPageProps) {
	const { title, description, children } = props;
	const centerChildren = props.centerChildren ?? true;

	const programmesProvider = useProgrammeProvider();

	const location = useLocation();

	const [scrollEl, setScrollEl] = useState<HTMLDivElement | null>(null);
	const hash = useMemo(() => objectHash([title, location.pathname]), [title, location.pathname]);
	useRememberScrollTop(scrollEl, hash);

	const handleProgrammesReordered = async (sortedNavLinks: NavLinkProps[]) => {
		await programmesProvider.updateSortOrders(
			sortedNavLinks.map((link, i) => ({ id: link.id, sortOrder: link.sortOrder ?? i })),
		);
	};

	const navLinks: NavLinkProps[] = [
		{
			id: 'dashboard',
			label: 'Dashboard',
			link: '/',
			icon: <IconGauge />,
			exactMatch: true,
		},
		{
			id: 'programmes',
			label: 'Programmes',
			link: '/programmes',
			icon: <IconBook2 />,
			onSort: handleProgrammesReordered,
			exactMatch: true,
			sortable: true,
			links: programmesProvider.programmes.map((programmes) => {
				return {
					id: programmes.id,
					sortOrder: programmes.sortOrder,
					label: (
						<div className="sortable">
							<Text className="text" size="sm" lineClamp={1}>
								{programmes.shortName}
							</Text>
							<ActionIcon className="reorder-handle" title="Drag to rearrange" variant="white">
								<IconArrowsMoveVertical />
							</ActionIcon>
						</div>
					),
					link: `/programmes/${programmes.id}`,
				};
			}),
		},
		{
			id: 'articles',
			label: 'Articles',
			link: '/articles',
			icon: <IconFileText />,
			exactMatch: true,
		},
		{
			id: 'quizzes',
			label: 'Quizzes',
			link: '/quizzes',
			icon: <IconCertificate />,
			exactMatch: true,
		},
		{
			id: 'resources',
			label: 'Resources',
			link: '/resources',
			icon: <IconFileDownload />,
			exactMatch: true,
		},
		{
			id: 'events',
			label: 'Events',
			link: '/events',
			icon: <IconCalendar />,
			exactMatch: true,
		},
		{
			id: 'products',
			label: 'Products',
			link: '/products',
			icon: <IconBooks />,
			exactMatch: true,
		},
		{
			id: 'payments',
			label: 'Payments',
			link: '/payments',
			icon: <IconCoin />,
			exactMatch: true,
		},
		{
			id: 'coupons',
			label: 'Coupons',
			link: '/coupons',
			icon: <IconTicket />,
			exactMatch: true,
		},
		{
			id: 'users',
			label: 'Users',
			link: '/users',
			icon: <IconUsers />,
			exactMatch: true,
		},
		{
			id: 'export',
			label: 'Export',
			link: '/export',
			icon: <IconDatabaseExport />,
			exactMatch: true,
		},
		{
			id: 'reports',
			label: 'Reports',
			link: '/reports',
			icon: <IconFileReport />,
			exactMatch: true,
		},
	];

	const { user } = useAuthenticatedUser();
	const userComponent = user && (
		<Flex align={'center'} gap="xs">
			<Avatar src={user.photoUrl} radius="xl" />
			<Stack gap={0}>
				{user.displayName && (
					<Text size="sm">
						<strong>{user.displayName}</strong>
					</Text>
				)}
				<Text c="dimmed" size="xs">
					{user.email}
				</Text>
			</Stack>
		</Flex>
	);

	return (
		<>
			<Helmet>
				{title && <title>{title}</title>}
				{description && <meta name="description" content={description} />}
			</Helmet>
			<RequireAuthenticated redirectNotAuthenticated="/login">
				<StyledAppShell
					padding="md"
					h="100vh"
					navbar={{ width: 200, breakpoint: 0 }}
					styles={(theme) => ({
						main: { backgroundColor: theme.colors.gray[0], height: '100vh', overflowY: 'hidden' },
					})}
				>
					<AppShell.Navbar w={{ base: 200 }} h="100%">
						<AppShell.Section p="sm">
							<Title order={3}>Dr Pam</Title>
						</AppShell.Section>
						<AppShell.Section grow component={ScrollArea} className={'primaryNavSection'}>
							<div>
								{navLinks.map((link, i) => (
									<NavigationLink key={i} link={link} />
								))}
							</div>
						</AppShell.Section>
						<AppShell.Section>
							<NavigationLink link={{ label: userComponent, link: '/profile', id: 'profile' }} />
						</AppShell.Section>
					</AppShell.Navbar>
					<AppShell.Main>
						{centerChildren ? (
							<ScrollArea.Autosize maw={1280} mx="auto" m="0" h="100%" viewportRef={setScrollEl}>
								<Stack w="100%" h="100%">
									{title && <Title order={1}>{title}</Title>}
									{children}
								</Stack>
							</ScrollArea.Autosize>
						) : (
							<>
								{title && <Title order={1}>{title}</Title>}
								{children}
							</>
						)}
					</AppShell.Main>
				</StyledAppShell>
			</RequireAuthenticated>
		</>
	);
}

const StyledAppShell = styled(AppShell)({
	'.primaryNavSection': {
		borderTop: `1px solid var(--mantine-color-gray-3)`,
		borderBottom: `1px solid var(--mantine-color-gray-3)`,
		paddingTop: 'var(--mantine-spacing-xs)',
		paddingBottom: 'var(--mantine-spacing-xs)',
	},
	'.scrollable': {
		overflowY: 'auto',
	},
	'.sortable': {
		height: 'var(--mantine-spacing-xl)',
		position: 'relative',
		'.text': {
			display: 'block',
			width: '100%',
			flexGrow: 1,
			height: 'var(--mantine-spacing-xl)',
			lineHeight: 'var(--mantine-spacing-xl)',
		},
		'.reorder-handle': {
			visibility: 'hidden',
			height: 'var(--mantine-spacing-xl)',
			position: 'absolute',
			right: 0,
			top: 0,
		},
		'&:hover .reorder-handle': {
			visibility: 'visible',
		},
	},
});
