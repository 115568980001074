import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { modals } from '@mantine/modals';
import { isNotEmpty, useForm } from '@mantine/form';
import { Stack, Text, Button, FileButton, Group, Loader, TextInput, Tooltip, ActionIcon } from '@mantine/core';
import { IconPhotoPlus, IconTrashX } from '@tabler/icons-react';
import CachedImage from '@dr-pam/common-components/Components/Content/CachedImage';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import styled from '@emotion/styled';

export type InsertImageModalProps = {
	modalId: string;
	className?: string;
	initialValues?: Partial<InsertImageForm>;
	onConfirm: (values: InsertImageForm & { fileToUpload?: File }) => Promise<void>;
};

export type InsertImageForm = {
	url?: string;
	description?: string;
	width?: string;
	height?: string;
};

export default function InsertImageModal(props: InsertImageModalProps) {
	const { className, modalId, initialValues, onConfirm } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [fileToUpload, setFileToUpload] = useState<File | null>(null);
	const [previewUrl, setPreviewUrl] = useState<string>();

	const form = useForm<InsertImageForm>({
		initialValues: {
			description: initialValues?.description ?? '',
			width: initialValues?.width ?? '',
			height: initialValues?.height ?? '',
			url: initialValues?.url ?? '',
		},
		validate: {
			description: isNotEmpty('Required'),
		},
	});

	useEffect(() => {
		if (fileToUpload) {
			setPreviewUrl(URL.createObjectURL(fileToUpload));
		} else {
			setPreviewUrl(undefined);
		}
	}, [fileToUpload]);

	const handleSubmit = async (values: InsertImageForm) => {
		const loader = addLoader();
		try {
			await onConfirm({
				...values,
				fileToUpload: fileToUpload ?? undefined,
			});
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error');
			removeLoader(loader);
		}
	};

	const handleUrlChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
		const url = e.target.value;
		form.setFieldValue('url', url);
		setPreviewUrl(url);
	};

	const handleCancelClicked = () => {
		modals.close(modalId);
	};

	const handleDeletePreviewClicked = () => {
		setFileToUpload(null);
		setPreviewUrl(undefined);
		form.setFieldValue('url', '');
	};

	return (
		<div className={`InsertImageModal ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput label="Image description" {...form.getInputProps('description')} withAsterisk />
					<Group align="flex-end">
						<TextInput
							label="Image URL"
							{...form.getInputProps('url')}
							onChange={handleUrlChanged}
							withAsterisk={fileToUpload === null}
							disabled={fileToUpload !== null}
							style={{ flexGrow: 1 }}
						/>
						<FileButton onChange={setFileToUpload} accept="image/*">
							{(props) => (
								<Tooltip label="Or upload image">
									<Button {...props}>
										<IconPhotoPlus />
									</Button>
								</Tooltip>
							)}
						</FileButton>
					</Group>
					<TextInput label="Width" {...form.getInputProps('width')} />
					<TextInput label="Height" {...form.getInputProps('height')} />
					{previewUrl ? (
						<Stack>
							<Group align="center">
								<Text fz="sm">Preview</Text>
								<Tooltip label="Delete image">
									<ActionIcon onClick={handleDeletePreviewClicked} color="red" variant="subtle">
										<IconTrashX />
									</ActionIcon>
								</Tooltip>
							</Group>

							<StyledCachedImage src={previewUrl} alt="Preview" />
						</Stack>
					) : null}
					<Group justify="flex-end">
						<Button variant="subtle" disabled={isLoading} onClick={handleCancelClicked}>
							Cancel
						</Button>
						<Button type="submit" color="blue" disabled={isLoading}>
							Insert image {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</div>
	);
}

const StyledCachedImage = styled(CachedImage)({
	maxWidth: '100%',
	maxHeight: '180px',
	objectFit: 'contain',
});
