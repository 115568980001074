import React, { ReactNode, useMemo, useRef } from 'react';
import { Stack, Breadcrumbs, Title, Container, Space, MantineSpacing, StyleProp } from '@mantine/core';
import { Link } from 'react-router-dom';
import keys from 'lodash-es/keys';
import useRememberScrollTop from '@dr-pam/common-components/Hooks/useRememberScollTop';
import objectHash from 'object-hash';
import styled from '@emotion/styled';

export type ContentProps = {
	className?: string;
	contentClassName?: string;
	breadcrumbs?: Record<string, string>;
	title?: ReactNode;
	preContent?: ReactNode;
	children?: ReactNode;
	padding?: StyleProp<MantineSpacing>;
	fillHeight?: boolean;
};

export default function Content(props: ContentProps) {
	const { className, breadcrumbs, title, preContent, children, padding, contentClassName, fillHeight } = props;

	const containerEl = useRef<HTMLDivElement>(null);

	const hash = useMemo(() => objectHash([title, className, contentClassName]), [className, contentClassName, title]);
	useRememberScrollTop(containerEl, hash);

	return (
		<StyledStack
			className={`Content container ${className ?? ''} ${fillHeight ? 'fill-height' : ''} ${
				preContent ? 'pre-content' : ''
			}`}
		>
			{breadcrumbs && (
				<Breadcrumbs>
					{keys(breadcrumbs).map((href) => (
						<Link key={href} to={href}>
							{breadcrumbs[href]}
						</Link>
					))}
				</Breadcrumbs>
			)}
			{title && <Title order={1}>{title}</Title>}
			{preContent}
			<Container
				ref={containerEl}
				className={`ContentContainer content ${contentClassName ?? ''}`}
				maw="100%"
				p={padding ?? 'lg'}
			>
				{children}
			</Container>
			<Space />
		</StyledStack>
	);
}

const StyledStack = styled(Stack)`
	.container {
		display: grid;
		grid-template-rows: auto auto auto 1fr;
		grid-template-columns: 1fr;
		overflow: hidden;
		width: 100%;
		height: 100%;

		&.pre-content {
			grid-template-rows: auto auto auto auto 1fr;
		}
		&.fill-height {
			grid-template-rows: auto auto 1fr 0;
			&.pre-content {
				grid-template-rows: auto auto auto 1fr 0;
			}
		}
	}
	.content {
		background: var(--mantine-color-white);
		width: 100%;
		height: 100%;
		margin: 0;
		overflow-y: auto;
	}
`;
