import React, { useState } from 'react';
import { Button, Group, Loader, Radio, Stack, TextInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import SearchArticle from '../articles/SearchArticle';
import SearchQuiz from '../quizzes/SearchQuiz';
import SearchResource from '../resources/SearchResource';
import { ArticleListFragment, QuizListFragment, ResourceListFragment } from '../../graphql/graphql';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export enum LinkType {
	External = 'external',
	Article = 'article',
	Quiz = 'quiz',
	Resource = 'resource',
}

export type InsertLinkForm = {
	alt: string;
	externalUrl: string;
};

export type InsertLinkModalProps = {
	modalId: string;
	className?: string;
	initialValue?: string;
	onConfirm: (markdown: string) => Promise<void>;
};

export default function InsertLinkModal(props: InsertLinkModalProps) {
	const { className, modalId, initialValue, onConfirm } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [linkType, setLinkType] = useState<LinkType>(LinkType.External);
	const [selectedArticle, setSelectedArticle] = useState<ArticleListFragment | undefined>();
	const [selectedQuiz, setSelectedQuiz] = useState<QuizListFragment | undefined>();
	const [selectedResource, setSelectedResource] = useState<ResourceListFragment | undefined>();

	const setLinkTypeFromString = (linkType: string) => {
		switch (linkType) {
			case 'article':
				setLinkType(LinkType.Article);
				break;
			case 'quiz':
				setLinkType(LinkType.Quiz);
				break;
			case 'resource':
				setLinkType(LinkType.Resource);
				break;
			case 'external':
			default:
				setLinkType(LinkType.External);
				break;
		}
	};

	const form = useForm<InsertLinkForm>({
		initialValues: {
			alt: initialValue ?? '',
			externalUrl: '',
		},
		validate: {
			alt: isNotEmpty('Required'),
			...(linkType === LinkType.External
				? {
						externalUrl: isNotEmpty('Required'),
				  }
				: undefined),
		},
	});

	const handleSubmit = async (values: InsertLinkForm) => {
		const loader = addLoader();
		try {
			switch (linkType) {
				case LinkType.Article:
					if (selectedArticle) {
						await onConfirm(
							`:article[${selectedArticle.id}]{slug="${selectedArticle.slug}" alt="${values.alt}"}`,
						);
					}
					break;
				case LinkType.Quiz:
					if (selectedQuiz) {
						await onConfirm(`:quiz[${selectedQuiz.id}]{slug="${selectedQuiz.slug}" alt="${values.alt}"}`);
					}
					break;
				case LinkType.Resource:
					if (selectedResource) {
						await onConfirm(
							`:resource[${selectedResource.id}]{slug="${selectedResource.slug}" alt="${values.alt}"}`,
						);
					}
					break;
				case LinkType.External:
				default:
					let url = values.externalUrl;
					if (!url.startsWith('http')) {
						url = `http://${url}`;
					}

					await onConfirm(`[${values.alt}](${url})`);
			}
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error');
			removeLoader(loader);
		}
	};

	const handleCancelClicked = () => {
		modals.close(modalId);
	};

	let formFields;

	if (linkType === LinkType.External) {
		formFields = (
			<TextInput {...form.getInputProps('externalUrl')} label="External URL" description="" withAsterisk />
		);
	} else if (linkType === LinkType.Article) {
		formFields = <SearchArticle onSelected={setSelectedArticle} />;
	} else if (linkType === LinkType.Quiz) {
		formFields = <SearchQuiz onSelected={setSelectedQuiz} />;
	} else if (linkType === LinkType.Resource) {
		formFields = <SearchResource onSelected={setSelectedResource} />;
	}

	return (
		<div className={`InsertLinkModal ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Radio.Group value={linkType} onChange={setLinkTypeFromString} label="Link type" withAsterisk>
						<Group gap="lg">
							<Radio value={LinkType.External} label="External" />
							<Radio value={LinkType.Article} label="Article" />
							<Radio value={LinkType.Resource} label="Resource" />
							<Radio value={LinkType.Quiz} label="Quiz" />
						</Group>
					</Radio.Group>
					<TextInput {...form.getInputProps('alt')} label="Text to display" description="" withAsterisk />
					{formFields}
					<Group justify="flex-end">
						<Button variant="subtle" disabled={isLoading} onClick={handleCancelClicked}>
							Cancel
						</Button>
						<Button type="submit" color="blue" disabled={isLoading}>
							Insert link {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</div>
	);
}
