import React, { useState } from 'react';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import { ActionIcon, Button, Flex, Menu, Tabs } from '@mantine/core';
import AddEventModal from './AddEventModal';
import { IconDotsVertical, IconFileText, IconLinkOff, IconPencil, IconTrash } from '@tabler/icons-react';
import { Link, useSearchParams } from 'react-router-dom';
import EditEventModal from './EditEventModal';
import { useCallback } from 'react';
import { useEventService } from '../../services/EventService';
import { EventListFragment, EventSingleFragment } from '../../graphql/graphql';

export default function EventsList() {
	const [filterType, setFilterType] = useState<FilterType>('all');
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');
	const [selectedTab, setSelectedTab] = useState<string>('all');

	const eventService = useEventService();

	const fetchEvents = useCallback(() => {
		if (filterType === 'orphans') {
			return eventService.getOrphans();
		} else if (searchQuery) {
			return eventService.searchByTitle(searchQuery);
		} else {
			return eventService.getAll();
		}
	}, [eventService, filterType, searchQuery]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const crudState = useCrudState<EventListFragment, EventSingleFragment>(fetchEvents);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'event',
		nameAccessor: (event) => event.title,
		addModalFactory: (props) => <AddEventModal {...props} />,
		editModalFactory: (props) => <EditEventModal {...props} />,
		delete: (event) => eventService.delete(event.id),
	});

	const crudColumns = (event: EventListFragment): CrudTableColumn[] => [
		{
			children: event.title,
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={`/events/${event.id}`}>
						<Button variant="subtle">View</Button>
					</Link>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(event)}>
								Edit
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(event)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	const handleTabChanged = (tab: string | null) => {
		if (!tab) {
			return;
		}

		setSelectedTab(tab);

		if (tab !== 'types') {
			setFilterType(tab === 'orphans' ? 'orphans' : 'all');
		}
	};

	return (
		<Tabs value={selectedTab} onChange={handleTabChanged}>
			<Tabs.List mb="md">
				<Tabs.Tab value="all" leftSection={<IconFileText />}>
					All events
				</Tabs.Tab>
				<Tabs.Tab value="orphans" leftSection={<IconLinkOff />}>
					Orphan events
				</Tabs.Tab>
			</Tabs.List>
			<CrudTable
				items={crudState.items}
				headers={crudHeaders}
				columns={crudColumns}
				title={{ children: 'Events' }}
				entityName="event"
				onAddClicked={crudHandlers.addHandler}
				search={{
					onSearchRequested: handleSearchRequested,
					onSearchCleared: handleSearchRequested,
					initialValue: searchQuery,
				}}
			/>
		</Tabs>
	);
}

type FilterType = 'all' | 'orphans';

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Title',
	},
	{
		children: '',
	},
];
