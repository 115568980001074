import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import { useParams } from 'react-router-dom';
import EditQuiz from '../../components/quizzes/EditQuiz';
import NotFound from '../../components/page/NotFound';

export default function QuizPage() {
	const { quizId } = useParams<{ quizId: string }>();

	return (
		<BackendPage centerChildren={quizId === undefined}>
			{quizId ? <EditQuiz quizId={quizId} /> : <NotFound />}
		</BackendPage>
	);
}
