import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import EditEvent from '../../components/events/EditEvent';
import NotFound from '../../components/page/NotFound';
import { useParams } from 'react-router-dom';

export default function EventPage() {
	const { eventId } = useParams<{ eventId: string }>();

	return <BackendPage>{eventId ? <EditEvent eventId={eventId} /> : <NotFound />}</BackendPage>;
}
