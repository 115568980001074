import React, { useState } from 'react';
import Content from '../Content';
import { EventWithOccurrences, useEventService } from '../../services/EventService';
import { EventOccurrenceListFragment } from '../../graphql/graphql';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { ActionIcon, Button, Flex, Group, LoadingOverlay, Menu, Select, Switch, Text } from '@mantine/core';
import NotFound from '../page/NotFound';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import AddEventOccurrenceModal from './AddEventOccurrenceModal';
import dayjs from 'dayjs';
import DateTimeUtils, { DATE_TIME_FORMAT } from '@dr-pam/common-components/Utils/DateTimeUtils';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import EditEventOccurrenceModal from './EditEventOccurrenceModal';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

export type EditEventProps = {
	className?: string;
	eventId: string;
};

export default function EditEvent(props: EditEventProps) {
	const { className, eventId } = props;

	const [event, setEvent] = useState<EventWithOccurrences | undefined | null>(undefined);
	const [timezone, setTimezone] = useState<string>(DateTimeUtils.getTimezoneInfo().name);

	const eventService = useEventService();

	const [showPastEvents, setShowPastEvents] = useState(false);

	const crudState = useCrudState<EventOccurrenceListFragment, EventOccurrenceListFragment>();

	const crudHandler = useStandardCrudHandlers({
		crudState: crudState,
		entityName: 'event occurrence',
		nameAccessor: (oe) => dayjs(oe.start).format(DATE_TIME_FORMAT + 'Z'),
		addModalFactory: (props) => (
			<AddEventOccurrenceModal
				{...props}
				eventId={eventId}
				maxRegistrations={event?.maxRegistrations ?? undefined}
			/>
		),
		editModalFactory: (props) => <EditEventOccurrenceModal {...props} />,
		delete: (eo) => eventService.deleteOccurrence(eo.id),
	});

	const isLoadingEvent = useLoadingEffect(async () => {
		try {
			const event = await eventService.getWithOccurrences(eventId).response;
			setEvent(event);
			if (event) {
				crudState.setItems(event.eventOccurrences);
			}
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load event.');
		}
	}, [eventService, eventId]);

	if (event === undefined || isLoadingEvent) {
		return <LoadingOverlay visible={isLoadingEvent} overlayProps={{ blur: 2 }} />;
	}

	if (event === null) {
		return <NotFound />;
	}

	const crudColumns = (oe: EventOccurrenceListFragment): CrudTableColumn[] => [
		{
			children: DateTimeUtils.format(oe.start, DATE_TIME_FORMAT, timezone),
		},
		{
			children: oe.end ? DateTimeUtils.format(oe.end, DATE_TIME_FORMAT, timezone) : null,
		},
		{
			children: timezone,
		},
		{
			children: (
				<>
					{oe.eventRegistrations.length} / {oe.maxRegistrations}
				</>
			),
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={`/events/${event.id}/${oe.id}`}>
						<Button variant="subtle">Registrations</Button>
					</Link>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<Group justify="flex-end">
								<ActionIcon color="dark" variant="subtle">
									<IconDotsVertical />
								</ActionIcon>
							</Group>
						</Menu.Target>

						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandler.editHandler(oe)}>
								Edit
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandler.deleteHandler(oe)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	const allTimezones = DateTimeUtils.getTimezones().map((tz) => ({ value: tz, label: tz }));

	return (
		<StyledContent
			className={`EditEvent ${className ?? ''}`}
			breadcrumbs={{ '/events': 'Events', '#': event?.title }}
			title={event.title}
			fillHeight
		>
			<CrudTable
				className={showPastEvents ? 'past-visible' : ''}
				title={{ order: 2, children: 'Occurrences' }}
				items={crudState.items}
				itemClassName={(oe) => (dayjs(oe.start).toDate() < new Date() ? 'past' : 'future')}
				isLoading={crudState.isFetching}
				headers={crudHeaders}
				columns={crudColumns}
				entityName="event occurrence"
				onAddClicked={crudHandler.addHandler}
				additionalButtons={
					<>
						<Flex direction="column" h="100%" justify="space-between">
							<Text size="sm" fw={500} mb="14px">
								Show past events
							</Text>
							<Switch
								checked={showPastEvents}
								onChange={() => setShowPastEvents(!showPastEvents)}
								size="md"
							/>
						</Flex>
						<Select
							data={allTimezones}
							value={timezone}
							onChange={(value) => (value ? setTimezone(value) : null)}
							label="View date/times in timezone"
							placeholder={'--- Please select ---'}
							nothingFoundMessage="No search results"
							searchable
							clearable
						/>
					</>
				}
			/>
		</StyledContent>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Start',
	},
	{
		children: 'End',
	},
	{
		children: 'Timezone',
	},
	{
		children: 'Registrations',
	},
	{
		children: '',
	},
];

const StyledContent = styled(Content)`
	tr.past {
		display: none;
		opacity: 0.6;
	}

	.past-visible {
		tr.past {
			display: table-row;
		}
	}
`;
