import React, { useEffect, useState } from 'react';
import { Loader, Stack, TextInput, Text, Checkbox } from '@mantine/core';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { useDebouncedValue } from '@mantine/hooks';
import { ResourceListFragment } from '../../graphql/graphql';
import { useResourceService } from '../../services/ResourceService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import styled from '@emotion/styled';

export type SearchResourceProps = {
	onSelected: (resource: ResourceListFragment | undefined) => void;
};

export default function SearchResource(props: SearchResourceProps) {
	const { onSelected } = props;

	const resourceService = useResourceService();

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [resources, setResources] = useState<ResourceListFragment[]>([]);
	const [selectedResource, setSelectedResource] = useState<ResourceListFragment | undefined>(undefined);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);

	useEffect(() => {
		if (debouncedSearchQuery) {
			const loader = addLoader();
			const request = resourceService.searchByTitle(debouncedSearchQuery);
			setSelectedResource(undefined);
			request.response
				.then((resources) => {
					setResources(resources);
				})
				.catch((err) => {
					NotificationUtils.showError(err as Error, 'Failed to search resources');
				})
				.finally(() => {
					removeLoader(loader);
				});
		}
	}, [addLoader, resourceService, debouncedSearchQuery, removeLoader]);

	const handleResourceSelected = (event: React.ChangeEvent<HTMLInputElement>, resource: ResourceListFragment) => {
		setSelectedResource(event.currentTarget.checked ? resource : undefined);
		onSelected(event.currentTarget.checked ? resource : undefined);
	};

	return (
		<Stack>
			<TextInput
				value={searchQuery}
				onChange={(event) => setSearchQuery(event.currentTarget.value)}
				label="Search resources by title"
				disabled={isLoading}
			/>
			{isLoading && (
				<Text ta="center">
					<Loader size="xs" ml="xs" />
				</Text>
			)}
			{!isLoading &&
				debouncedSearchQuery &&
				(resources.length ? (
					<Grid>
						{resources.map((a) => (
							<div key={a.id}>
								<div>{a.title}</div>
								<div>
									<Checkbox
										checked={selectedResource?.id === a.id}
										onChange={(e) => handleResourceSelected(e, a)}
									/>
								</div>
							</div>
						))}
					</Grid>
				) : (
					<Text ta="center">No search results</Text>
				))}
		</Stack>
	);
}

const Grid = styled.div`
	max-height: 500px;
	overflow-y: auto;
	> div {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr auto;
		padding: 0.5rem;
		gap: 0.5rem;
		align-items: center;
		overflow-wrap: anywhere;

		&:nth-child(even) {
			background: var(--mantine-color-gray-2);
		}
	}
`;
