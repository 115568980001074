import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import ConfirmModal, { openConfirmModal } from '../modals/ConfirmModal';
import { QuizListFragment, QuizSingleFragment } from '../../graphql/graphql';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { ActionIcon, Button, Group, Menu, Tabs, Text } from '@mantine/core';
import { modals, openModal } from '@mantine/modals';
import {
	IconCopy,
	IconDotsVertical,
	IconLink,
	IconLinkOff,
	IconPencil,
	IconCertificate,
	IconTrash,
} from '@tabler/icons-react';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AddQuizModal from './AddQuizModal';
import EditQuizModal from './EditQuizModal';
import { useQuizService } from '../../services/QuizService';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import QuizUsageModal from './QuizUsageModal';
import StatusBadge from '../content/StatusBadge';

export default function QuizzesList() {
	const [filterType, setFilterType] = useState<FilterType>('all');
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');

	const navigate = useNavigate();
	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const quizService = useQuizService();

	const fetchQuizzes = useCallback((): AbortableRequest<QuizListFragment[]> | null => {
		if (filterType === 'orphans') {
			return quizService.getOrphans();
		} else if (searchQuery) {
			return quizService.searchByTitle(searchQuery);
		} else {
			return quizService.getAll();
		}
	}, [filterType, searchQuery, quizService]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const crudState = useCrudState<QuizListFragment, QuizSingleFragment>(fetchQuizzes);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'quiz',
		nameAccessor: (quiz) => quiz.title,
		addModalFactory: (props) => <AddQuizModal {...props} />,
		editModalFactory: (props) => <EditQuizModal {...props} />,
		delete: (quiz) => quizService.delete(quiz.id),
	});

	const handleDuplicateClicked = async (quiz: QuizListFragment) => {
		await openConfirmModal({
			title: 'Duplicate quiz',
			confirmText: 'Yes',
			children: <Text>Are you sure you want to duplicate the quiz &quot;{quiz.title}&quot;?</Text>,
			onConfirm: async () => {
				const request = quizService.duplicateQuiz(quiz.id);
				const duplicatedQuiz = await request.response;
				crudState.setItems((existing) => {
					// Add the duplicated quiz after the original quiz
					const updated = [...(existing ?? [])];
					const index = updated.findIndex((item) => item.id === quiz.id);
					updated.splice(index + 1, 0, duplicatedQuiz);
					return updated;
				});
			},
		});
	};

	const handleOnNavigateToProgramme = (programmeId: string) => {
		navigate(`/programmes/${programmeId}`);
	};

	const handleUnlinkQuizFromCategory = async (quizId: string, categoryId: string) => {
		const loader = addLoader();
		try {
			await quizService.removeQuizFromCategory(quizId, categoryId);
			removeLoader(loader);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to unlink quiz from category');
			removeLoader(loader);
		}
	};

	const handleUsageClicked = async (quiz: QuizListFragment) => {
		const modalId = `quiz-usage-${quiz.id}`;
		openModal({
			modalId,
			title: 'Quiz usage',
			children: (
				<QuizUsageModal
					modalId={modalId}
					quizId={quiz.id}
					onNavigate={handleOnNavigateToProgramme}
					onUnlink={handleUnlinkQuizFromCategory}
				/>
			),
		});
	};

	const getStatusForQuiz = (quiz: QuizListFragment): 'draft' | 'published' | 'changed' => {
		if (!quiz.isPublished) return 'draft';
		const { content } = quiz;
		if (
			content?.markdownHash &&
			content.publishedMarkdownHash &&
			content.markdownHash !== content.publishedMarkdownHash
		) {
			return 'changed';
		}
		return 'published';
	};

	const crudColumns = (quiz: QuizListFragment): CrudTableColumn[] => [
		{
			children: quiz.title,
		},
		{
			children: quiz.slug,
		},
		{
			children: <StatusBadge status={getStatusForQuiz(quiz)} onClick={() => handleTogglePublished(quiz)} />,
		},
		{
			children: (
				<Group justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={`/quizzes/${quiz.id}`}>
						<Button variant="subtle">View content</Button>
					</Link>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(quiz)}>
								Edit
							</Menu.Item>
							<Menu.Item leftSection={<IconCopy />} onClick={() => handleDuplicateClicked(quiz)}>
								Duplicate
							</Menu.Item>
							<Menu.Item leftSection={<IconLink />} onClick={() => handleUsageClicked(quiz)}>
								Show usage
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(quiz)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
			),
		},
	];

	const handleTogglePublished = (quiz: QuizListFragment) => {
		const modalId = 'publish-quiz';
		if (quiz.isPublished) {
			modals.open({
				modalId,
				title: 'Un-publish quiz',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Un-publish"
						onConfirm={async () => {
							const updatedQuiz = await quizService.unpublish(quiz.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedQuiz.id ? updatedQuiz : item,
								),
							);
						}}
						danger
					>
						Are you sure you want to un-publish the quiz {quiz.title}?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Publish quiz',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Publish"
						onConfirm={async () => {
							const updatedQuiz = await quizService.publish(quiz.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedQuiz.id ? updatedQuiz : item,
								),
							);
						}}
					>
						Are you sure you want to publish the quiz {quiz.title}?
					</ConfirmModal>
				),
			});
		}
	};

	const handleTabChanged = (tab: string | null) => {
		setFilterType(tab === 'orphans' ? 'orphans' : 'all');
	};

	return (
		<Tabs value={filterType} onChange={handleTabChanged}>
			<Tabs.List mb="md">
				<Tabs.Tab value="all" leftSection={<IconCertificate />}>
					All quizzes
				</Tabs.Tab>
				<Tabs.Tab value="orphans" leftSection={<IconLinkOff />}>
					Orphan quizzes
				</Tabs.Tab>
			</Tabs.List>
			<CrudTable
				items={crudState.items}
				isLoading={crudState.isFetching || isLoading}
				headers={crudHeaders}
				columns={crudColumns}
				entityName="quiz"
				onAddClicked={crudHandlers.addHandler}
				search={{
					onSearchRequested: handleSearchRequested,
					onSearchCleared: handleSearchRequested,
					initialValue: searchQuery,
				}}
			/>
		</Tabs>
	);
}

type FilterType = 'all' | 'orphans';

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Title',
	},
	{
		children: 'Slug',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];
