import React from 'react';
import { Button, Container, Group, Loader, NumberInput, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import z from 'zod';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { QuizCategoryListFragment } from '../../graphql/graphql';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useQuizService } from '../../services/QuizService';

export const validationSchema = z.object({
	maxAttempts: z.null().or(z.coerce.number().positive().or(z.coerce.string().length(0))),
	minScore: z.null().or(z.coerce.number().positive().min(0).max(100).or(z.coerce.string().length(0))),
});

export type SetQuizRequirementsModalProps = {
	className?: string;
	modalId: string;
	quizCategory: QuizCategoryListFragment;
	onUpdated: (updated: QuizCategoryListFragment) => void;
	onCancel?: () => void;
};

export default function SetQuizRequirementsModal(props: SetQuizRequirementsModalProps) {
	const { className, modalId, quizCategory, onCancel, onUpdated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const quizService = useQuizService();

	const form = useForm<SetQuizRequirementsForm>({
		initialValues: {
			maxAttempts: quizCategory.maxAttempts ?? null,
			minScore: quizCategory.minScore ? Math.ceil(quizCategory.minScore * 100) : null,
		},
		validate: zodResolver(validationSchema),
		transformValues: (values) => ({
			maxAttempts: values.maxAttempts ? parseInt(`${values.maxAttempts}`, 10) : null,
			minScore: values.minScore ? Math.ceil(parseInt(`${values.minScore}`, 10)) / 100 : null,
		}),
	});

	const handleSubmit = async (values: SetQuizRequirementsForm) => {
		const loader = addLoader();

		try {
			const updated = await quizService.updateRequirements(quizCategory.id, {
				maxAttempts: values.maxAttempts as number | null,
				minScore: values.minScore as number | null,
			});
			onUpdated(updated);
			NotificationUtils.showSuccess('Successfully set requirements', 'Quiz');
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to set quiz requirements');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container className={`SetQuizRequirementsModal ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...form.getInputProps('maxAttempts')}
						label="Max attempts"
						description="The maximum number of times a user can attempt the quiz. Leave blank for unlimited."
						disabled={isLoading}
					/>
					<NumberInput
						{...form.getInputProps('minScore')}
						min={0}
						max={100}
						step={1}
						label="Minimum score (0-100)%"
						description="The minimum score (in percent) the user must achieve to pass the quiz. Leave blank for no minimum."
						disabled={isLoading}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Save {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type SetQuizRequirementsForm = {
	minScore: number | string | null;
	maxAttempts: number | string | null;
};
