import React, { useCallback, useState } from 'react';
import { useUserService } from '../../services/UserService';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import type { User } from '@dr-pam/common-types/database';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import { UserSubscriptionListFragment } from '../../graphql/graphql';
import { ActionIcon, Text, Group, LoadingOverlay, Menu, Flex } from '@mantine/core';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import NotFound from '../page/NotFound';
import AddSubscriptionModal from './AddSubscriptionModal';
import { DATE_FORMAT } from '@dr-pam/common-components/Utils/DateTimeUtils';
import dayjs from 'dayjs';
import Content from '../Content';
import EditSubscriptionModal from './EditSubscriptionModal';
import StatusBadge from '../content/StatusBadge';

export type EditUserProps = {
	className?: string;
	userId: string;
};

export default function EditUser(props: EditUserProps) {
	const { className, userId } = props;

	const [user, setUser] = useState<User | undefined>(undefined);

	const userService = useUserService();

	const isLoadingUser = useLoadingEffect(async () => {
		try {
			const request = userService.get(userId);
			const user = await request.response;
			setUser(user);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load user.');
		}
	}, [userService, userId]);

	const fetchSubscriptions = useCallback(() => userService.getSubscriptions(userId), [userService, userId]);

	const subscriptionsCrudState = useCrudState<UserSubscriptionListFragment, UserSubscriptionListFragment>(
		fetchSubscriptions,
	);

	const subscriptionsCrudHandlers = useStandardCrudHandlers({
		crudState: subscriptionsCrudState,
		entityName: 'Subscriptions',
		trapFocus: false,
		nameAccessor: (us) => `${us.productSubscriptionType.product?.name} - ${us.productSubscriptionType.name}`,
		addModalFactory: (props) => <AddSubscriptionModal {...props} userId={userId} />,
		editModalFactory: (props) => <EditSubscriptionModal {...props} />,
		delete: (us) => userService.removeSubscription(us.id),
	});

	if (user === undefined || isLoadingUser) {
		return <LoadingOverlay visible={isLoadingUser} overlayProps={{ blur: 2 }} />;
	}

	if (user === null) {
		return <NotFound />;
	}

	const subscriptionsCrudColumns = (us: UserSubscriptionListFragment): CrudTableColumn[] => {
		const remainingDays = us.validTo ? dayjs(us.validTo).startOf('day').diff(dayjs().startOf('day'), 'day') : null;
		return [
			{
				children: (
					<Group>
						<Text>{us.productSubscriptionType.product?.name}</Text>
						<StatusBadge status={us.productSubscriptionType.product?.isPublished ? 'published' : 'draft'} />
					</Group>
				),
			},
			{
				children: (
					<Group>
						<Text>{us.productSubscriptionType.name}</Text>
						<StatusBadge status={us.productSubscriptionType.isPublished ? 'published' : 'draft'} />
					</Group>
				),
			},
			{
				children: dayjs(us.validFrom).format(DATE_FORMAT),
			},
			{
				children: us.validTo === null ? <Text>Unlimited</Text> : dayjs(us.validTo).format(DATE_FORMAT),
			},
			{
				children:
					remainingDays === null ? (
						<Text>Unlimited</Text>
					) : remainingDays < 0 ? (
						<Text c="Red">Expired</Text>
					) : (
						<Text>{remainingDays}</Text>
					),
			},
			{
				children: (
					<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
						<Menu shadow="md" width={200}>
							<Menu.Target>
								<ActionIcon color="dark" variant="subtle">
									<IconDotsVertical />
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									leftSection={<IconPencil />}
									onClick={() => subscriptionsCrudHandlers.editHandler(us)}
								>
									Edit
								</Menu.Item>
								<Menu.Item
									leftSection={<IconTrash />}
									onClick={() => subscriptionsCrudHandlers.deleteHandler(us)}
									color="red"
								>
									Delete
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Flex>
				),
			},
		];
	};

	return (
		<Content
			className={`EditUser ${className ?? ''}`}
			breadcrumbs={{ '/users': 'Users', '#': user.displayName || user.email }}
			title={user.displayName || user.email}
			fillHeight
		>
			<CrudTable
				title={{ order: 2, children: 'Product Subscriptions' }}
				items={subscriptionsCrudState.items}
				isLoading={subscriptionsCrudState.isFetching}
				headers={subscriptionsCrudHeaders}
				columns={subscriptionsCrudColumns}
				entityName="product subscription"
				onAddClicked={subscriptionsCrudHandlers.addHandler}
			/>
		</Content>
	);
}

const subscriptionsCrudHeaders: CrudTableColumn[] = [
	{
		children: 'Product Name',
	},
	{
		children: 'Subscription Name',
	},
	{
		children: 'Valid From',
	},
	{
		children: 'Valid To',
	},
	{
		children: 'Remaining Days',
	},
	{
		children: '',
	},
];
