import React, { useState } from 'react';
import { Container, Button, Stack, Group } from '@mantine/core';
import { modals } from '@mantine/modals';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { EventCategoryListFragment } from '../../graphql/graphql';
import SearchEvent from './SearchEvent';
import { useEventService } from '../../services/EventService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type SelectEventModalProps = {
	modalId: string;
	categoryId: string;
	onCancel?: () => void;
	onSelected: (event: EventCategoryListFragment) => void;
};

export default function SelectEventModal(props: SelectEventModalProps) {
	const { modalId, categoryId, onCancel, onSelected } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const [selectedEventId, setSelectedEventId] = useState<string | undefined>(undefined);

	const eventService = useEventService();

	const handleLinkClicked = async () => {
		if (!selectedEventId) {
			return;
		}
		const loader = addLoader();
		try {
			const linkedEventCategory = await eventService.addEventToCategory(selectedEventId, categoryId);
			onSelected(linkedEventCategory);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to link event');
		} finally {
			removeLoader(loader);
			modals.close(modalId);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<Stack>
				<SearchEvent onSelected={(a) => setSelectedEventId(a?.id)} />
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button
						type="submit"
						disabled={isLoading || selectedEventId === undefined}
						onClick={handleLinkClicked}
					>
						Link selected event
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}
