import React from 'react';
import { CrudEditModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Container, TextInput, Button, Loader, Group } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { AddCategoryForm } from './AddCategoryModal';
import useComputedSlug from '../../hooks/useComputedSlug';
import { CategoryListFragment, CategorySingleFragment } from '../../graphql/graphql';
import { useCategoryService } from '../../services/CategoryService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';

export const validationSchema = z.object({
	name: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
});

export type EditCategoryModalProps = CrudEditModalProps<CategoryListFragment, CategorySingleFragment> & {
	programmeId: string;
};

export default function EditCategoryModal(props: EditCategoryModalProps) {
	const { modalId, onCancel, onEdited, current, programmeId } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const categoryService = useCategoryService();

	const form = useForm<EditCategoryForm>({
		initialValues: {
			name: current.name,
			slug: current.slug,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditCategoryForm) => {
		const loader = addLoader();

		try {
			const updatedCategory = await categoryService.update(current.id, {
				name: { set: values.name },
				slug: { set: values.slug },
				programme: { connect: { id: programmeId } },
			});
			onEdited(updatedCategory);
			NotificationUtils.showSuccess('Successfully updated category', updatedCategory.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update category');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'name');

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<TextInput
					{...slugComputedFromProps}
					label="Name"
					description="The name of the category. Forms the basis of the Slug"
					disabled={isLoading}
					withAsterisk
				/>
				<TextInput
					{...form.getInputProps('slug')}
					mt="md"
					label="Slug"
					description="This will appear in the browser URL."
					disabled={isLoading}
					withAsterisk
				/>
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button type="submit" disabled={isLoading}>
						Save category {isLoading && <Loader size="xs" ml="xs" />}
					</Button>
				</Group>
			</form>
		</Container>
	);
}

export type EditCategoryForm = AddCategoryForm;
