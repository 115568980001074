import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import QuizzesList from '../../components/quizzes/QuizzesList';

export default function QuizzesPage() {
	return (
		<BackendPage title="Quizzes">
			<QuizzesList />
		</BackendPage>
	);
}
