import React, { useCallback } from 'react';
import { useReportService } from '../../services/ReportService';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Divider, Loader, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import styled from '@emotion/styled';
import { DateInput } from '@mantine/dates';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import dayjs from 'dayjs';
import { XeroReportRequest } from '@dr-pam/common-components/Models/Reports';
import CsvUtils from '@dr-pam/common-components/Utils/CsvUtils';

export type XeroReportProps = {
	className?: string;
};

export default function XeroReport(props: XeroReportProps) {
	const { className } = props;

	const { isLoading, addLoader, removeLoader } = useLoadTracker();

	const { isInitialLoading } = useAuthenticatedUser();

	const reportService = useReportService();

	const fetch = useCallback(
		async (reportDate: string) => {
			const loader = addLoader();
			try {
				const request = reportService.getXeroReport(dayjs(reportDate).tz('UTC', true).toISOString());
				const response = await request.response;
				if (response.csvRows.length > 0) {
					const url = URL.createObjectURL(CsvUtils.convertToCsv(response));
					const link = document.createElement('a');
					link.href = url;
					link.download = `xero_journal_${dayjs(reportDate).format('DD-MM-YYYY')}.csv`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					URL.revokeObjectURL(url);
				} else {
					throw new Error('No records found for the selected date');
				}
			} catch (err) {
				NotificationUtils.showError(err as Error, 'Failed to create report');
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, reportService, removeLoader],
	);

	const form = useForm<XeroReportRequest>({
		initialValues: {
			reportDate: '',
		},
		validate: {
			reportDate: (value) => {
				if (value === null) {
					return 'Date is required';
				} else {
					const isValidDate = dayjs(value).isValid();
					return isValidDate ? undefined : 'Date is required';
				}
			},
		},
	});

	const handleSubmit = async (values: XeroReportRequest) => {
		fetch(values.reportDate);
	};

	const isDisabled = isLoading || isInitialLoading;

	return (
		<StyledExportOptions className={`ExportOptions ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Text size="lg" fw={700}>
						Xero Journal Template
					</Text>
					<Divider />
					<Text size="sm" fw={500}>
						Generate a Xero Journal Template for the selected date.
					</Text>
					<Divider />
					<DateInput
						{...form.getInputProps('reportDate')}
						label="Date"
						description="Enter a date in the following format: DD-MM-YYYY"
						valueFormat="DD-MM-YYYY"
						popoverProps={{ withinPortal: true }}
						disabled={isLoading}
						withAsterisk
						clearable
					/>
					<Divider />
					<Button type="submit" className="flex ac jc gxs" disabled={isDisabled}>
						<span>Create Xero Journal (CSV)</span>
						{(isLoading || isInitialLoading) && <Loader size="xs" ml="xs" />}
					</Button>
				</Stack>
			</form>
		</StyledExportOptions>
	);
}

const StyledExportOptions = styled.div`
	form {
		width: 420px;
	}
`;
