import React from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { ResourceTypeListFragment, ResourceTypeSingleFragment } from '../../graphql/graphql';
import { useResourceService } from '../../services/ResourceService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';

export const validationSchema = z.object({
	name: z.string().nonempty().max(512),
});

export type AddResourceTypeModalProps = CrudAddModalProps<ResourceTypeListFragment, ResourceTypeSingleFragment>;

export default function AddResourceTypeModal(props: AddResourceTypeModalProps) {
	const { modalId, onCancel, onCreated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const resourceService = useResourceService();

	const form = useForm<AddResourceTypeForm>({
		initialValues: {
			name: '',
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: AddResourceTypeForm) => {
		const loader = addLoader();

		try {
			const createdResourceType = await resourceService.createType({
				name: values.name,
			});
			onCreated(createdResourceType);
			NotificationUtils.showSuccess('Successfully created resource type', createdResourceType.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create resource type');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput {...form.getInputProps('name')} label="Name" withAsterisk />
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Add resource type {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddResourceTypeForm = Pick<ResourceTypeSingleFragment, 'name'>;
