import { useMemo } from 'react';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import {
	ContentQueryVariables,
	ContentsQuery,
	CreateContentMutationVariables,
	UpdateContentMutationVariables,
	DeleteContentsMutationVariables,
	ContentQuery,
	ContentCreateInput,
	ContentListFragment,
	ContentSingleFragment,
	ContentUpdateInput,
	CreateContentMutation,
	DeleteContentsMutation,
	UpdateContentMutation,
} from '../graphql/graphql';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export const GQL_FRAG_CONTENT_LIST = gql`
	fragment ContentList on Content {
		id
	}
`;

export const GQL_FRAG_CONTENT_SINGLE = gql`
	fragment ContentSingle on Content {
		id
		markdown
	}
`;

export const GQL_GET_CONTENT = gql`
	${GQL_FRAG_CONTENT_SINGLE}
	query Content($contentId: String!) {
		content(where: { id: $contentId }) {
			...ContentSingle
		}
	}
`;

export const GQL_GET_CONTENTS_FOR_LIST = gql`
	${GQL_FRAG_CONTENT_LIST}
	query Contents {
		contents(orderBy: { created: desc }) {
			...ContentList
		}
	}
`;

export const GQL_DELETE_CONTENTS = gql`
	mutation DeleteContents($contentIds: [String!]) {
		deleteManyContent(where: { id: { in: $contentIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_CONTENT = gql`
	${GQL_FRAG_CONTENT_SINGLE}
	mutation CreateContent($content: ContentCreateInput!) {
		createOneContent(data: $content) {
			...ContentSingle
		}
	}
`;

export const GQL_UPDATE_CONTENT = gql`
	${GQL_FRAG_CONTENT_SINGLE}
	mutation UpdateContent($contentId: String!, $content: ContentUpdateInput!) {
		updateOneContent(where: { id: $contentId }, data: $content) {
			...ContentSingle
		}
	}
`;

export default class ContentService
	implements ICrudService<ContentListFragment, ContentSingleFragment, ContentCreateInput, ContentUpdateInput>
{
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	get(contentId: string) {
		const request = ApolloUtils.abortableQuery<ContentQuery, ContentSingleFragment | null, ContentQueryVariables>(
			this._apolloClient,
			{
				query: GQL_GET_CONTENT,
				variables: {
					contentId,
				},
			},
			(data) => data.content ?? null,
			this._registerAbort,
		);

		return request;
	}

	getAll() {
		const request = ApolloUtils.abortableQuery<ContentsQuery, ContentListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_CONTENTS_FOR_LIST,
			},
			(data) => data.contents,
			this._registerAbort,
		);

		return request;
	}

	async create(content: ContentCreateInput) {
		const result = await this._apolloClient.mutate<CreateContentMutation, CreateContentMutationVariables>({
			mutation: GQL_CREATE_CONTENT,
			variables: {
				content,
			},
		});
		if (!result.data?.createOneContent) {
			throw new Error('Failed to create content');
		}
		return result.data.createOneContent;
	}

	async update(contentId: string, content: ContentUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateContentMutation, UpdateContentMutationVariables>({
			mutation: GQL_UPDATE_CONTENT,
			variables: {
				contentId,
				content,
			},
		});
		if (!result.data?.updateOneContent) {
			throw new Error('Failed to update content');
		}
		return result.data.updateOneContent;
	}

	async delete(contentIds: string | string[]) {
		contentIds = Array.isArray(contentIds) ? contentIds : [contentIds];

		await this._apolloClient.mutate<DeleteContentsMutation, DeleteContentsMutationVariables>({
			mutation: GQL_DELETE_CONTENTS,
			variables: {
				contentIds,
			},
		});
	}
}

export function useContentService() {
	const apolloClient = useApolloClient();

	return useMemo(() => new ContentService(apolloClient), [apolloClient]);
}
