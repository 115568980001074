import React, { useEffect } from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, NumberInput, Stack, Switch, TextInput, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { useProductService } from '../../services/ProductService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { ProductSubscriptionTypeListFragment, ProductSubscriptionTypeSingleFragment } from '../../graphql/graphql';
import z from 'zod';
import { IconCurrencyDollar } from '@tabler/icons-react';

const validationSchema = z.object({
	name: z.string().nonempty('Name is required').max(512),
	price: z.coerce
		.string()
		.nonempty('Price is required')
		.pipe(z.coerce.number().gte(0, 'Price must be greater than (or equal to) 0')),
	duration: z.coerce.number().positive('Duration must be a positive number').or(z.coerce.string().length(0)),
});

export type AddProductSubscriptionTypeModalProps = CrudAddModalProps<
	ProductSubscriptionTypeListFragment,
	ProductSubscriptionTypeSingleFragment
> & {
	productId: string;
};

export default function AddProductSubscriptionTypeModal(props: AddProductSubscriptionTypeModalProps) {
	const { modalId, productId, onCancel, onCreated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const productService = useProductService();

	const form = useForm<AddProductSubscriptionTypeForm>({
		initialValues: {
			name: '',
			price: '',
			duration: '',
			automaticRenew: true,
		},
		validate: zodResolver(validationSchema),
	});

	useEffect(() => {
		if (form.values.price === 0) {
			form.setFieldValue('duration', '');
			form.setFieldValue('automaticRenew', false);
		}
	}, [form, form.values.price]);

	const handleSubmit = async (values: AddProductSubscriptionTypeForm) => {
		const loader = addLoader();
		try {
			const createdProductSubscriptionType = await productService.addProductSubscriptionType({
				name: values.name,
				description: values.description,
				automaticRenew: values.automaticRenew,
				priceInCents: Math.floor(parseFloat(`${values.price}`) * 100),
				durationInDays: values.duration === '' ? null : parseInt(`${values.duration}`, 10),
				product: {
					connect: {
						id: productId,
					},
				},
			});
			onCreated(createdProductSubscriptionType);
			NotificationUtils.showSuccess(
				'Successfully created subscription option',
				createdProductSubscriptionType.name,
			);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create product');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...form.getInputProps('name')}
						label="Name"
						description="The name of the subscription type."
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="An optional description of the subscription type"
						disabled={isLoading}
					/>
					<NumberInput
						{...form.getInputProps('price')}
						label="Price (AUD)"
						description="The recurring price of the subscription type (set to 0 for free)."
						decimalScale={2}
						decimalSeparator="."
						thousandSeparator=","
						disabled={isLoading}
						leftSection={<IconCurrencyDollar />}
						withAsterisk
					/>
					<NumberInput
						{...form.getInputProps('duration')}
						label="Duration (days)"
						description="The duration of the subscription type in days (leave blank for unlimited)."
						decimalScale={0}
						thousandSeparator=","
						disabled={isLoading || form.values.price === 0}
					/>
					<Switch
						{...form.getInputProps('automaticRenew', { type: 'checkbox' })}
						label="Renews automatically"
						description="Should the subscription automatically renew?"
						disabled={isLoading || form.values.price === 0}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Add subscription option {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddProductSubscriptionTypeForm = Pick<ProductSubscriptionTypeSingleFragment, 'name' | 'automaticRenew'> & {
	price: number | '';
	duration: number | '';
	description?: string;
};
