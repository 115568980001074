import React from 'react';
import { CrudEditModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, PasswordInput, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useUserService } from '../../services/UserService';
import type { User } from '@dr-pam/common-types/database';
import z from 'zod';

const validationSchema = z.object({
	password: z.string().min(12),
});

export type ResetPasswordModalProps = CrudEditModalProps<User, User>;

export default function ResetPasswordModal(props: ResetPasswordModalProps) {
	const { modalId, onCancel, onEdited, current } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const userService = useUserService();

	const form = useForm<ResetPasswordForm>({
		initialValues: {
			password: '',
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: ResetPasswordForm) => {
		const loader = addLoader();

		try {
			const request = userService.update(current.id, {
				id: current.id,
				fullName: current.fullName,
				password: values.password,
			});
			const updatedUser = await request.response;
			onEdited(updatedUser);
			NotificationUtils.showSuccess(
				'Successfully reset password for user',
				updatedUser.displayName ?? updatedUser.email,
			);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to reset password for user');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<PasswordInput
						{...form.getInputProps('password')}
						label="Password"
						description="The password for the user. Must be at least 12 characters long."
						disabled={isLoading}
					/>
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Reset password {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type ResetPasswordForm = {
	password: string;
};
