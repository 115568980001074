import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import { ArticleSortableTreeItem } from './ArticleService';
import { QuizSortableTreeItem } from './QuizService';
import { ResourceSortableTreeItem } from './ResourceService';
import {
	CategoryListWithContentAndPrerequisitesFragment,
	CreateProgrammeMutation,
	CreateProgrammeMutationVariables,
	DeleteProgrammesMutation,
	DeleteProgrammesMutationVariables,
	ProgrammeCreateInput,
	ProgrammeListFragment,
	ProgrammeQuery,
	ProgrammeQueryVariables,
	ProgrammeSingleFragment,
	ProgrammeUpdateInput,
	ProgrammesQuery,
	ProgrammesWithContentQuery,
	ProgrammesWithContentQueryVariables,
	SearchProgrammesQuery,
	SearchProgrammesQueryVariables,
	UpdateProgrammeMutation,
	UpdateProgrammeMutationVariables,
} from '../graphql/graphql';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { SortableTreeItem } from '../models/SortableTreeItem';
import { GQL_FRAG_CATEGORY_LIST_WITH_CONTENT, CategorySortableTreeItem } from './CategoryService';
import IPublishable from '@dr-pam/common-components/Services/IPublishable';
import { EventSortableTreeItem } from './EventService';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

const runtimeGql = gql;

export type ProgrammeWithContent = ProgrammesWithContentQuery['programme'] & {
	categories: CategoryListWithContentAndPrerequisitesFragment[];
};

export const GQL_FRAG_PROGRAMME_LIST = gql`
	fragment ProgrammeList on Programme {
		id
		name
		shortName
		description
		slug
		sortOrder
		isPublished
		isHidden
	}
`;

export const GQL_FRAG_PROGRAMME_SINGLE = gql`
	fragment ProgrammeSingle on Programme {
		id
		created
		modified
		name
		shortName
		description
		slug
		sortOrder
		isPublished
		isHidden
	}
`;

export const GQL_FRAG_CATEGORY_LIST_WITH_CONTENT_AND_PREREQUISITES = gql`
	${GQL_FRAG_CATEGORY_LIST_WITH_CONTENT}
	fragment CategoryListWithContentAndPrerequisites on Category {
		...CategoryListWithContent
		prerequisites {
			categoryId
			prerequisiteId
		}
	}
`;

export const GQL_GET_PROGRAMME = gql`
	${GQL_FRAG_PROGRAMME_SINGLE}
	query Programme($programmeId: String!) {
		programme(where: { id: $programmeId }) {
			...ProgrammeSingle
		}
	}
`;

export const GQL_GET_PROGRAMMES_FOR_LIST = gql`
	${GQL_FRAG_PROGRAMME_LIST}
	query Programmes {
		programmes(orderBy: { name: asc }) {
			...ProgrammeList
		}
	}
`;

export const GQL_SEARCH_PROGRAMMES_FOR_LIST = gql`
	${GQL_FRAG_PROGRAMME_LIST}
	query SearchProgrammes($name: String!, $excludeIds: [String!]) {
		programmes(
			orderBy: { name: asc }
			where: {
				OR: [{ name: { contains: $name } }, { shortName: { contains: $name } }]
				id: { notIn: $excludeIds }
			}
		) {
			...ProgrammeList
		}
	}
`;

export const GQL_DELETE_PROGRAMMES = gql`
	mutation DeleteProgrammes($programmeIds: [String!]) {
		deleteManyProgramme(where: { id: { in: $programmeIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_PROGRAMME = gql`
	${GQL_FRAG_PROGRAMME_SINGLE}
	mutation CreateProgramme($programme: ProgrammeCreateInput!) {
		createOneProgramme(data: $programme) {
			...ProgrammeSingle
		}
	}
`;

export const GQL_UPDATE_PROGRAMME = gql`
	${GQL_FRAG_PROGRAMME_SINGLE}
	mutation UpdateProgramme($programmeId: String!, $programme: ProgrammeUpdateInput!) {
		updateOneProgramme(where: { id: $programmeId }, data: $programme) {
			...ProgrammeSingle
		}
	}
`;

export const GQL_QUERY_PROGRAMMES_WITH_CONTENT = gql`
	${GQL_FRAG_PROGRAMME_SINGLE}
	${GQL_FRAG_CATEGORY_LIST_WITH_CONTENT_AND_PREREQUISITES}
	query ProgrammesWithContent($programmeId: String!) {
		programme(where: { id: $programmeId }) {
			...ProgrammeSingle

			categories {
				...CategoryListWithContentAndPrerequisites
			}
		}
	}
`;

export default class ProgrammeService
	implements
		ICrudService<ProgrammeListFragment, ProgrammeSingleFragment, ProgrammeCreateInput, ProgrammeUpdateInput>,
		IPublishable<ProgrammeListFragment>
{
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public async publish(programmeId: string) {
		const updatedResources = await this.update(programmeId, {
			isPublished: { set: true },
		});

		return updatedResources;
	}

	public async unpublish(programmeId: string) {
		const updatedResources = await this.update(programmeId, {
			isPublished: { set: false },
		});

		return updatedResources;
	}

	public get(programmeId: string) {
		const request = ApolloUtils.abortableQuery<
			ProgrammeQuery,
			ProgrammeSingleFragment | null,
			ProgrammeQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_PROGRAMME,
				variables: {
					programmeId,
				},
			},
			(data) => data.programme ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getAll() {
		const request = ApolloUtils.abortableQuery<ProgrammesQuery, ProgrammeListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_PROGRAMMES_FOR_LIST,
			},
			(data) => data.programmes,
			this._registerAbort,
		);

		return request;
	}

	public searchByName(name: string, excludeIds?: string[]) {
		const request = ApolloUtils.abortableQuery<
			SearchProgrammesQuery,
			ProgrammeListFragment[],
			SearchProgrammesQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_SEARCH_PROGRAMMES_FOR_LIST,
				variables: {
					name,
					excludeIds: excludeIds ?? [],
				},
			},
			(data) => data.programmes,
			this._registerAbort,
		);

		return request;
	}

	public async create(programme: ProgrammeCreateInput) {
		const result = await this._apolloClient.mutate<CreateProgrammeMutation, CreateProgrammeMutationVariables>({
			mutation: GQL_CREATE_PROGRAMME,
			variables: {
				programme,
			},
		});
		if (!result.data?.createOneProgramme) {
			throw new Error('Failed to create programme');
		}
		return result.data.createOneProgramme;
	}

	public async update(programmeId: string, programme: ProgrammeUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateProgrammeMutation, UpdateProgrammeMutationVariables>({
			mutation: GQL_UPDATE_PROGRAMME,
			variables: {
				programmeId,
				programme,
			},
		});
		if (!result.data?.updateOneProgramme) {
			throw new Error('Failed to update programme');
		}
		return result.data.updateOneProgramme;
	}

	public async delete(programmeIds: string | string[]) {
		programmeIds = Array.isArray(programmeIds) ? programmeIds : [programmeIds];

		await this._apolloClient.mutate<DeleteProgrammesMutation, DeleteProgrammesMutationVariables>({
			mutation: GQL_DELETE_PROGRAMMES,
			variables: {
				programmeIds,
			},
		});
	}

	public getWithContent(programmeId: string): AbortableRequest<ProgrammeWithContent | null> {
		const request = ApolloUtils.abortableQuery<
			ProgrammesWithContentQuery,
			ProgrammeWithContent | null,
			ProgrammesWithContentQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_QUERY_PROGRAMMES_WITH_CONTENT,
				variables: {
					programmeId,
				},
			},
			(data) => {
				return data.programme ?? null;
			},
			this._registerAbort,
		);

		return request;
	}

	public async updateSortOrders(programmes: Pick<ProgrammeListFragment, 'id' | 'sortOrder'>[]) {
		const mutation = runtimeGql`mutation updateSortOrders {
			${programmes
				.map((programme, i) => {
					return `mutation${i} : updateOneProgramme(where: { id: "${programme.id}" }, data: { sortOrder: { set: ${programme.sortOrder} } }) { id }`;
				})
				.join('\n')}
		}`;

		await this._apolloClient.mutate({
			mutation,
		});
	}

	public async updateChildItemSortOrdersAndParentIds(childItems: SortableTreeItem<unknown>[]) {
		const mutation = runtimeGql`mutation updateChildItemSortOrdersAndParentIds {
			${childItems
				.map((childItem, i) => {
					if (childItem.parent === null) {
						console.warn('Child item has no parent, failed to update', childItem);
						return '';
					}
					if (childItem instanceof ArticleSortableTreeItem) {
						return `mutation${i} : updateOneArticleCategory(where: { id: "${
							childItem.id
						}" }, data: { sortOrder: { set: ${childItem.getSortOrder()} }, category: { connect: { id: "${
							childItem.parent.id
						}" } } }) { id }`;
					} else if (childItem instanceof QuizSortableTreeItem) {
						return `mutation${i} : updateOneQuizCategory(where: { id: "${
							childItem.id
						}" }, data: { sortOrder: { set: ${childItem.getSortOrder()} }, category: { connect: { id: "${
							childItem.parent.id
						}" } } }) { id }`;
					} else if (childItem instanceof ResourceSortableTreeItem) {
						return `mutation${i} : updateOneResourceCategory(where: { id: "${
							childItem.id
						}" }, data: { sortOrder: { set: ${childItem.getSortOrder()} }, category: { connect: { id: "${
							childItem.parent.id
						}" } } }) { id }`;
					} else if (childItem instanceof EventSortableTreeItem) {
						return `mutation${i} : updateOneEventCategory(where: { id: "${
							childItem.id
						}" }, data: { sortOrder: { set: ${childItem.getSortOrder()} }, category: { connect: { id: "${
							childItem.parent.id
						}" } } }) { id }`;
					} else if (childItem instanceof CategorySortableTreeItem) {
						// Always update sort order
						const data = [
							`sortOrder: {
								set: ${childItem.getSortOrder()}
							}`,
						];
						// If the new parent is root, don't connect to it. We want the equivalent of parentId: null
						if (childItem.parent.id === 'root') {
							// If the previous parent is not null and not root, explicitly disconnect from it
							if (childItem.prevParent !== null && childItem.prevParent.id !== 'root') {
								data.push(`parentCategory: {
									disconnect: {
										id: {
											equals: "${childItem.prevParent.id}"
										}
									}
								}`);
							}
							// If the previous parent is null, do nothing
							// Note: { disconnect: true } does not work here, prisma documentation says it should
							// but the types generated by the codegen don't have it. It fails validation
							// clientside and when you force this query to run, the server fails to parse it.
							// The disconnect field will only take a CategoryWhereInput, not a boolean.
						} else {
							data.push(`parentCategory: {
								connect: {
									id: "${childItem.parent.id}"
								}
							}`);
						}
						return `mutation${i} : updateOneCategory(where: {
							id: "${childItem.id}"
						}, data: {
							${data.join(',')}
						}) { id }`;
					}
				})
				.join('\n')}
		}`;

		await this._apolloClient.mutate({
			mutation,
		});
	}

	// public updateSortOrders(sortOrders: Pick<Programme, "id" | "sortOrder" | "categoryId">[]) {
	// 	return FetchUtils.post("/api/programme/sortOrders", sortOrders);
	// }

	// public static compareBySortOrder(left: Programme, right: Programme) {
	// 	return left.sortOrder - right.sortOrder;
	// }

	// public static compareByTitle(left: Programme, right: Programme) {
	// 	return left.title.localeCompare(right.title);
	// }

	// public static compare(left: Programme, right: Programme) {
	// 	return ProgrammeService.compareBySortOrder(left, right) || ProgrammeService.compareByTitle(left, right);
	// }
}

export function useProgrammeService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new ProgrammeService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
