import React, { useMemo } from 'react';
import { Button, Container, Group, Loader, Stack, TextInput } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useForm, isNotEmpty, UseFormInput } from '@mantine/form';
import keys from 'lodash-es/keys';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type PromptInput = {
	label: string;
	initialValue?: string;
	required?: boolean;
};

export type PromptModalProps<T> = {
	modalId: string;
	prompts: T;
	children?: React.ReactNode;
	danger?: boolean;
	cancelText?: string;
	confirmText?: string;
	onConfirm: (answers: Record<keyof T, string>) => Promise<void>;
};

export default function PromptModal<T extends Record<string, PromptInput>>(props: PromptModalProps<T>) {
	const { modalId, prompts, danger, children, cancelText, confirmText, onConfirm } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const formOptions = useMemo(() => {
		const options: UseFormInput<Partial<Record<keyof T, string>>> = {
			initialValues: {},
			validate: {},
		};

		keys(prompts).forEach((key: keyof T) => {
			const prompt = prompts[key];
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			options.initialValues![key] = prompt.initialValue ?? '';
			if (prompt.required) {
				(options.validate as Record<keyof T, unknown>)[key] = isNotEmpty('Required');
			}
		});

		return options;
	}, [prompts]);

	const form = useForm(formOptions);

	const handleSubmit = async (values: Partial<Record<keyof T, string>>) => {
		const loader = addLoader();
		try {
			await onConfirm(values as Record<keyof T, string>);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error');
			removeLoader(loader);
		}
	};

	const handleCancelClicked = () => {
		modals.close(modalId);
	};

	return (
		<Container>
			<Stack>
				{children}
				<form onSubmit={form.onSubmit(handleSubmit)}>
					<Stack>
						{keys(prompts).map((key) => (
							<TextInput key={key} label={prompts[key].label} {...form.getInputProps(key)} />
						))}
						<Group justify="flex-end">
							<Button variant="subtle" disabled={isLoading} onClick={handleCancelClicked}>
								{cancelText ?? 'Cancel'}
							</Button>
							<Button type="submit" color={danger ? 'red' : 'blue'} disabled={isLoading}>
								{confirmText ?? 'Confirm'} {isLoading && <Loader size="xs" ml="xs" />}
							</Button>
						</Group>
					</Stack>
				</form>
			</Stack>
		</Container>
	);
}
