import React, { useEffect, useState } from 'react';
import { Loader, Stack, TextInput, Table, Text, Checkbox } from '@mantine/core';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { useDebouncedValue } from '@mantine/hooks';
import { ProgrammeListFragment } from '../../graphql/graphql';
import { useProgrammeService } from '../../services/ProgrammeService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type SearchProgrammeProps = {
	allowMultiple?: boolean;
	onSelected: (programmes: ProgrammeListFragment[]) => void;
	excludeIds?: string[];
};

export default function SearchProgramme(props: SearchProgrammeProps) {
	const { onSelected, allowMultiple, excludeIds } = props;

	const programmeService = useProgrammeService();

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [programmes, setProgrammes] = useState<ProgrammeListFragment[]>([]);
	const [selectedProgrammes, setSelectedProgrammes] = useState<ProgrammeListFragment[]>([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);

	useEffect(() => {
		if (debouncedSearchQuery) {
			const loader = addLoader();
			const request = programmeService.searchByName(debouncedSearchQuery, excludeIds);
			setSelectedProgrammes([]);
			request.response
				.then((programmes) => {
					setProgrammes(programmes);
				})
				.catch((err) => {
					NotificationUtils.showError(err as Error, 'Failed to search programmes');
				})
				.finally(() => {
					removeLoader(loader);
				});
		}
	}, [addLoader, programmeService, debouncedSearchQuery, removeLoader, excludeIds]);

	const handleProgrammeSelected = (event: React.ChangeEvent<HTMLInputElement>, programme: ProgrammeListFragment) => {
		const index = selectedProgrammes.findIndex((p) => p.id === programme.id);

		let updatedProgrammes: ProgrammeListFragment[] = [];

		if (allowMultiple) {
			if (event.currentTarget.checked && index === -1) {
				updatedProgrammes = [...selectedProgrammes, programme];
			} else if (!event.currentTarget.checked && index >= 0) {
				updatedProgrammes = [...selectedProgrammes.slice(0, index), ...selectedProgrammes.slice(index + 1)];
			}
		} else {
			if (event.currentTarget.checked) {
				updatedProgrammes = [programme];
			} else {
				updatedProgrammes = [];
			}
		}

		setSelectedProgrammes(updatedProgrammes);
		onSelected(updatedProgrammes);
	};

	return (
		<Stack>
			<TextInput
				value={searchQuery}
				onChange={(event) => setSearchQuery(event.currentTarget.value)}
				label="Search programmes by name"
				disabled={isLoading}
			/>
			{isLoading && (
				<Text ta="center">
					<Loader size="xs" ml="xs" />
				</Text>
			)}
			{!isLoading &&
				debouncedSearchQuery &&
				(programmes.length ? (
					<Table>
						<tbody>
							{programmes.map((a) => (
								<tr key={a.id}>
									<td width="100%">{a.name}</td>
									<td align="right">
										<Checkbox
											checked={selectedProgrammes.findIndex((p) => p.id === a.id) >= 0}
											onChange={(e) => handleProgrammeSelected(e, a)}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				) : (
					<Text ta="center">No search results</Text>
				))}
		</Stack>
	);
}
