import React, { useCallback, useState } from 'react';
import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import ConfirmModal from '../modals/ConfirmModal';
import { ProductListFragment, ProductSingleFragment } from '../../graphql/graphql';
import { useProductService } from '../../services/ProductService';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { ActionIcon, Button, Flex, Menu } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import { Link, useSearchParams } from 'react-router-dom';
import AddProductModal from './AddProductModal';
import EditProductModal from './EditProductModal';
import StatusBadge from '../content/StatusBadge';

export default function ProductsList() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');

	const productService = useProductService();

	const fetchProducts = useCallback((): AbortableRequest<ProductListFragment[]> | null => {
		if (searchQuery) {
			return productService.searchByName(searchQuery);
		} else {
			return productService.getAll();
		}
	}, [productService, searchQuery]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const crudState = useCrudState<ProductListFragment, ProductSingleFragment>(fetchProducts);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'product',
		nameAccessor: (product) => product.name,
		addModalFactory: (props) => <AddProductModal {...props} />,
		editModalFactory: (props) => <EditProductModal {...props} />,
		delete: (product) => productService.delete(product.id),
	});

	const crudColumns = (product: ProductListFragment): CrudTableColumn[] => [
		{
			children: product.name,
		},
		{
			children: product.slug,
		},
		{
			children: (
				<StatusBadge
					status={product.isPublished ? 'published' : 'draft'}
					onClick={() => handleTogglePublished(product)}
				/>
			),
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={`/products/${product.id}`}>
						<Button variant="subtle">View</Button>
					</Link>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>

						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(product)}>
								Edit
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(product)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	const handleTogglePublished = (product: ProductListFragment) => {
		const modalId = 'publish-product';
		if (product.isPublished) {
			modals.open({
				modalId,
				title: 'Un-publish product',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Un-publish"
						onConfirm={async () => {
							const updatedProduct = await productService.unpublish(product.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedProduct.id ? updatedProduct : item,
								),
							);
						}}
						danger
					>
						Are you sure you want to un-publish the product {product.name}?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Publish product',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Publish"
						onConfirm={async () => {
							const updatedProduct = await productService.publish(product.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedProduct.id ? updatedProduct : item,
								),
							);
						}}
					>
						Are you sure you want to publish the product {product.name}?
					</ConfirmModal>
				),
			});
		}
	};

	return (
		<CrudTable
			items={crudState.items}
			isLoading={crudState.isFetching}
			headers={crudHeaders}
			columns={crudColumns}
			entityName="product"
			onAddClicked={crudHandlers.addHandler}
			search={{
				onSearchRequested: handleSearchRequested,
				onSearchCleared: handleSearchRequested,
				initialValue: searchQuery,
			}}
		/>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Name',
	},
	{
		children: 'Slug',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];
