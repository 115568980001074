import NotFound from '../page/NotFound';
import useDebouncedFunction from '@dr-pam/common-components/Hooks/useDebouncedFunction';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import { Breadcrumbs, Container, Flex, Group, Loader, LoadingOverlay, Stack, Title, Tooltip } from '@mantine/core';
import { IconAlertCircle, IconAlertTriangle, IconCheck } from '@tabler/icons-react';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useContentService } from '../../services/ContentService';
import { QuizWithContent, useQuizService } from '../../services/QuizService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import MarkdownEditor from '../markdown/MarkdownEditor';
import styled from '@emotion/styled';

const AUTOSAVE_INTERVAL_SEC = 2;

export type EditQuizProps = {
	quizId: string;
};

export default function EditQuiz(props: EditQuizProps) {
	const { quizId } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const [requiresSave, setRequiresSave] = useState(false);
	const [saveError, setSaveError] = useState<Error | null>(null);

	const [quiz, setQuiz] = useState<QuizWithContent | undefined | null>();

	const quizService = useQuizService();
	const contentService = useContentService();

	const isLoadingQuiz = useLoadingEffect(async () => {
		try {
			const article = await quizService.getWithContent(quizId).response;
			setQuiz(article);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load article.');
		}
	}, [quizService]);

	const saveQuizContent = useCallback(
		async (quiz: QuizWithContent, value: string) => {
			const loader = addLoader();
			try {
				if (quiz.content?.id) {
					quiz.content = await contentService.update(quiz.content.id, {
						markdown: { set: value },
					});
				} else {
					quiz.content = await contentService.create({
						markdown: value,
						quiz: {
							connect: {
								id: quiz.id,
							},
						},
					});
				}
				removeLoader(loader);
				setRequiresSave(false);
			} catch (err) {
				NotificationUtils.showError(err as Error, 'Failed to save quiz content.');
				removeLoader(loader);
				setSaveError(err as Error);
			}
		},
		[addLoader, removeLoader, contentService],
	);

	const saveQuizContentDebounced = useDebouncedFunction(saveQuizContent, AUTOSAVE_INTERVAL_SEC * 1000);

	const handleOnMarkdownChanged = (value: string) => {
		if (!quiz) {
			return;
		}
		setRequiresSave(true);
		setSaveError(null);
		saveQuizContentDebounced?.(quiz, value);
	};

	const handleSaveClicked = () => {
		saveQuizContentDebounced?.flush();
	};

	let saveMessage = (
		<Group justify="flex-end" grow>
			Saved <IconCheck color="green" />
		</Group>
	);
	if (saveError) {
		saveMessage = (
			<Tooltip label={saveError.message}>
				<Group justify="flex-end" grow>
					Failed to save <IconAlertCircle color="red" />
				</Group>
			</Tooltip>
		);
	} else if (isLoading) {
		saveMessage = (
			<Group justify="flex-end" grow>
				Saving changes...
				<Loader size={'sm'} />
			</Group>
		);
	} else if (requiresSave) {
		saveMessage = (
			<Tooltip
				label={`Will automatically save within ${AUTOSAVE_INTERVAL_SEC} seconds. Alternatively, click the icon to save immediately.`}
			>
				<Group onClick={handleSaveClicked} justify="flex-end" grow>
					Unsaved changes <IconAlertTriangle color="orange" />
				</Group>
			</Tooltip>
		);
	}

	if (quiz === undefined || isLoadingQuiz) {
		return <LoadingOverlay visible={isLoadingQuiz} overlayProps={{ blur: 2 }} />;
	}

	if (quiz === null) {
		return <NotFound />;
	}

	return (
		<StyledContainer>
			<Breadcrumbs>
				<Link to="/quizzes">Quizzes</Link>
				<Link to="#">{quiz?.title}</Link>
			</Breadcrumbs>
			<Title order={1}>Quiz - {quiz?.title}</Title>
			<StyledContent maw="100%">
				<MarkdownEditor
					uploadFileNamePrefix={`quizzes/${quiz.id}`}
					initialValue={quiz.content?.markdown}
					onChange={handleOnMarkdownChanged}
					toolbarChildren={<StyledFlex justify="flex-end">{saveMessage}</StyledFlex>}
					showQuestionsInToolbar
					showDocxImport
				/>
			</StyledContent>
		</StyledContainer>
	);
}

const StyledContainer = styled(Stack)({
	display: 'grid',
	gridTemplateRows: 'auto auto 1fr',
	gridTemplateColumns: '1fr',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
});

const StyledContent = styled(Container)({
	background: 'var(--mantine-color-white)',
	display: 'grid',
	gridTemplateRows: '1fr',
	gridTemplateColumns: '1fr',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
	padding: 0,
	margin: 0,
});

const StyledFlex = styled(Flex)`
	flex-grow: 1;
` as unknown as typeof Flex;
