import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import { useParams } from 'react-router-dom';
import NotFound from '../../components/page/NotFound';
import EditProgramme from '../../components/programmes/EditProgramme';

export default function ProgrammePage() {
	const { programmeId } = useParams<{ programmeId: string }>();

	return <BackendPage>{programmeId ? <EditProgramme programmeId={programmeId} /> : <NotFound />}</BackendPage>;
}
