import React, { useCallback } from 'react';
import { useQuizService } from '../../services/QuizService';
import { QuizUsageFragment } from '../../graphql/graphql';
import { ActionIcon, Button, Container, Group, Stack, Text } from '@mantine/core';
import CrudTable, { CrudTableColumn, useCrudState } from '../crud/CrudTable';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { IconEye, IconUnlink } from '@tabler/icons-react';
import { closeModal } from '@mantine/modals';
import { openConfirmModal } from '../modals/ConfirmModal';

export type QuizUsageModalProps = {
	className?: string;
	modalId: string;
	quizId: string;
	onNavigate: (programmeId: string) => void;
	onUnlink: (quizId: string, categoryId: string) => Promise<void>;
};

export default function QuizUsageModal(props: QuizUsageModalProps) {
	const { className, modalId, quizId, onNavigate, onUnlink } = props;

	const quizService = useQuizService();

	const fetchQuizUsage = useCallback((): AbortableRequest<QuizUsageFragment[]> | null => {
		return quizService.getAllUsages(quizId);
	}, [quizService, quizId]);

	const crudState = useCrudState<QuizUsageFragment, QuizUsageFragment>(fetchQuizUsage);

	const handleCancelClicked = () => {
		closeModal(modalId);
	};

	const handleNavigateClicked = (programmeId: string) => {
		setTimeout(() => onNavigate(programmeId), 0);
		closeModal(modalId);
	};

	const handleUnlinkClicked = async (usage: QuizUsageFragment) => {
		await openConfirmModal({
			title: 'Unlink quiz',
			danger: true,
			children: (
				<Text>
					Are you sure you want to unlink this quiz from the category &quot;{usage.category.name}&quot;?
				</Text>
			),
			onConfirm: async () => {
				await onUnlink(quizId, usage.category.id);
				crudState.delete(usage);
			},
		});
	};

	const crudColumns = (usage: QuizUsageFragment): CrudTableColumn[] => [
		{
			children: usage.category.name,
		},
		{
			children: usage.category.programme.name,
		},
		{
			children: (
				<Group justify="flex-end">
					<ActionIcon
						color="blue"
						title="Open programme"
						variant="subtle"
						onClick={() => handleNavigateClicked(usage.category.programme.id)}
					>
						<IconEye />
					</ActionIcon>
					<ActionIcon
						color="red"
						title="Unlink quiz"
						variant="subtle"
						onClick={() => handleUnlinkClicked(usage)}
					>
						<IconUnlink />
					</ActionIcon>
				</Group>
			),
		},
	];

	return (
		<Container mih="64px" className={`QuizUsageModal ${className ?? ''}`}>
			<Stack>
				<CrudTable
					items={crudState.items}
					isLoading={crudState.isFetching}
					headers={crudHeaders}
					columns={crudColumns}
					padding={0}
					entityName="usages"
				/>
				<Group justify="flex-end">
					<Button disabled={crudState.isFetching} onClick={handleCancelClicked}>
						Close
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Category',
	},
	{
		children: 'Programme',
	},
	{
		children: '',
	},
];
