import React, { useState } from 'react';
import { Container, Button, Stack, Group } from '@mantine/core';
import { modals } from '@mantine/modals';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { ArticleCategoryListFragment } from '../../graphql/graphql';
import SearchArticle from './SearchArticle';
import { useArticleService } from '../../services/ArticleService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type SelectArticleModalProps = {
	modalId: string;
	categoryId: string;
	onCancel?: () => void;
	onSelected: (article: ArticleCategoryListFragment) => void;
};

export default function SelectArticleModal(props: SelectArticleModalProps) {
	const { modalId, categoryId, onCancel, onSelected } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const [selectedArticleId, setSelectedArticleId] = useState<string | undefined>(undefined);

	const articleService = useArticleService();

	const handleLinkClicked = async () => {
		if (!selectedArticleId) {
			return;
		}
		const loader = addLoader();
		try {
			const linkedArticleCategory = await articleService.addArticleToCategory(selectedArticleId, categoryId);
			onSelected(linkedArticleCategory);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to link article');
		} finally {
			removeLoader(loader);
			modals.close(modalId);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<Stack>
				<SearchArticle onSelected={(a) => setSelectedArticleId(a?.id)} />
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button
						type="submit"
						disabled={isLoading || selectedArticleId === undefined}
						onClick={handleLinkClicked}
					>
						Link selected article
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}
