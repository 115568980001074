import React, { ReactNode, forwardRef, useEffect, useMemo, useRef } from 'react';
import { ActionIcon, Text, Menu, Button, useMantineTheme, MenuItemProps, Table, TableProps, Flex } from '@mantine/core';
import {
	IconFilePlus,
	IconPencil,
	IconTrash,
	IconFileText,
	IconCertificate,
	IconFileDownload,
	IconBinaryTree,
	IconArrowsMoveVertical,
	IconLink,
	IconUnlink,
	IconPin,
	IconPinnedOff,
	IconCopy,
	IconDotsVertical,
	IconChevronDown,
	IconChevronUp,
	IconCalendar,
	IconAsterisk,
	IconSettings,
	IconArrowMerge,
} from '@tabler/icons-react';
import hash from 'object-hash';
import { ReactSortable, SortableEvent } from 'react-sortablejs';
import { Link } from 'react-router-dom';
import { openConfirmModal } from '../modals/ConfirmModal';
import { ArticleSortableTreeItem } from '../../services/ArticleService';
import {
	CategoryListWithContentAndPrerequisitesFragment,
	ArticleListFragment,
	QuizListFragment,
	ResourceListFragment,
	ArticleCategoryListFragment,
	QuizCategoryListFragment,
	ResourceCategoryListFragment,
	EventCategoryListFragment,
	EventListFragment,
} from '../../graphql/graphql';
import { useForceUpdate } from '@mantine/hooks';
import { SortableTreeItem } from '../../models/SortableTreeItem';
import { CategorySortableTreeItem } from '../../services/CategoryService';
import { QuizSortableTreeItem } from '../../services/QuizService';
import { ResourceSortableTreeItem } from '../../services/ResourceService';
import styled from '@emotion/styled';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';
import StatusBadge from '../content/StatusBadge';
import { EventSortableTreeItem } from '../../services/EventService';

export type SortableTreeProps = {
	className?: string;
	category: CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>;
	children?: ReactNode;
	onAddSubCategoryRequested: (
		categoryId: string,
	) => Promise<CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null>;
	onAddArticleRequested: (categoryId: string) => Promise<ArticleCategoryListFragment | null>;
	onAddQuizRequested: (categoryId: string) => Promise<QuizCategoryListFragment | null>;
	onAddResourceRequested: (categoryId: string) => Promise<ResourceCategoryListFragment | null>;
	onAddEventRequested: (categoryId: string) => Promise<EventCategoryListFragment | null>;
	onLinkArticleRequested: (categoryId: string) => Promise<ArticleCategoryListFragment | null>;
	onLinkQuizRequested: (categoryId: string) => Promise<QuizCategoryListFragment | null>;
	onLinkResourceRequested: (categoryId: string) => Promise<ResourceCategoryListFragment | null>;
	onLinkEventRequested: (categoryId: string) => Promise<EventCategoryListFragment | null>;
	onDeleteCategoryRequested: (categoryId: string) => Promise<void>;
	onUnlinkArticleRequested: (articleId: string, categoryId: string) => Promise<void>;
	onUnlinkQuizRequested: (quizId: string, categoryId: string) => Promise<void>;
	onUnlinkResourceRequested: (resourceId: string, categoryId: string) => Promise<void>;
	onUnlinkEventRequested: (eventId: string, categoryId: string) => Promise<void>;
	onUpdateCategoryRequested: (
		category: CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>,
	) => Promise<CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null>;
	onSetCategoryPrerequisitesRequested: (
		category: CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>,
	) => Promise<CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null>;
	onUpdateArticleRequested: (article: ArticleListFragment) => Promise<ArticleListFragment | null>;
	onUpdateQuizRequested: (quiz: QuizListFragment) => Promise<QuizListFragment | null>;
	onUpdateResourceRequested: (resource: ResourceListFragment) => Promise<ResourceListFragment | null>;
	onUpdateEventRequested: (event: EventListFragment) => Promise<EventListFragment | null>;
	onPinArticleCategoryRequested: (articleCategoryId: string, isPinned: boolean) => Promise<void>;
	onPinResourceCategoryRequested: (resourceCategoryId: string, isPinned: boolean) => Promise<void>;
	onPinEventCategoryRequested: (eventCategoryId: string, isPinned: boolean) => Promise<void>;
	onPinQuizCategoryRequested: (quizCategoryId: string, isPinned: boolean) => Promise<void>;
	onRequiredArticleCategoryRequested: (articleCategoryId: string, isRequired: boolean) => Promise<void>;
	onRequiredResourceCategoryRequested: (resourceCategoryId: string, isRequired: boolean) => Promise<void>;
	onRequiredEventCategoryRequested: (eventCategoryId: string, isRequired: boolean) => Promise<void>;
	onRequiredQuizCategoryRequested: (quizCategoryId: string, isRequired: boolean) => Promise<void>;
	onPublishArticleRequested: (articleId: string) => Promise<void>;
	onRepublishArticleRequested: (articleId: string) => Promise<ArticleListFragment>;
	onUnpublishArticleRequested: (articleId: string) => Promise<void>;
	onPublishQuizRequested: (quizId: string) => Promise<void>;
	onRepublishQuizRequested: (quizId: string) => Promise<QuizListFragment>;
	onUnpublishQuizRequested: (quizId: string) => Promise<void>;
	onPublishResourceRequested: (resourceId: string) => Promise<void>;
	onUnpublishResourceRequested: (resourceId: string) => Promise<void>;
	onSetQuizRequirementsRequested: (quiz: QuizCategoryListFragment) => Promise<QuizCategoryListFragment | null>;
	onSetEventRequirementsRequested: (event: EventCategoryListFragment) => Promise<EventCategoryListFragment | null>;
	onTreeModified: () => Promise<void>;
	onSelfDeleted?: (categoryId: string) => void; // Required if `isRootCategory` is not true
	onDuplicateArticleRequested: (
		articleId: string,
		categoryId: string,
		sortOrder?: number,
	) => Promise<ArticleCategoryListFragment | null>;
	onDuplicateQuizRequested: (
		quizId: string,
		categoryId: string,
		sortOrder?: number,
	) => Promise<QuizCategoryListFragment | null>;
	findItemById: (id: string) => SortableTreeItem<ChildItem> | null;
	isRootCategory?: boolean;
};

export default function SortableTree(props: SortableTreeProps) {
	const {
		className,
		category,
		onAddSubCategoryRequested,
		onAddArticleRequested,
		onAddQuizRequested,
		onAddResourceRequested,
		onAddEventRequested,
		onLinkArticleRequested,
		onLinkQuizRequested,
		onLinkResourceRequested,
		onLinkEventRequested,
		onDeleteCategoryRequested,
		onUnlinkArticleRequested,
		onUnlinkQuizRequested,
		onUnlinkResourceRequested,
		onUnlinkEventRequested,
		onUpdateCategoryRequested,
		onSetCategoryPrerequisitesRequested,
		onUpdateArticleRequested,
		onUpdateQuizRequested,
		onUpdateResourceRequested,
		onUpdateEventRequested,
		onTreeModified,
		onSelfDeleted,
		onPinArticleCategoryRequested,
		onPinResourceCategoryRequested,
		onPinEventCategoryRequested,
		onPinQuizCategoryRequested,
		onRequiredArticleCategoryRequested,
		onRequiredResourceCategoryRequested,
		onRequiredEventCategoryRequested,
		onRequiredQuizCategoryRequested,
		onDuplicateArticleRequested,
		onDuplicateQuizRequested,
		onPublishArticleRequested,
		onRepublishArticleRequested,
		onUnpublishArticleRequested,
		onPublishQuizRequested,
		onRepublishQuizRequested,
		onUnpublishQuizRequested,
		onPublishResourceRequested,
		onUnpublishResourceRequested,
		onSetQuizRequirementsRequested,
		onSetEventRequirementsRequested,
		findItemById,
		isRootCategory,
	} = props;

	const theme = useMantineTheme();

	const originalOrders = useRef(new Map<string, number>());

	const sortedChildItems = useChildItems(category);

	const persistSortOrders = async () => {
		// Make sure all children have their sort order updated to the new order
		category.children.forEach((child, i) => {
			child.setSortOrder(i);
		});
		await onTreeModified();
	};

	const handleOnAddSubCategoryRequested = async () => {
		const newCategory = await onAddSubCategoryRequested(category.getId());
		if (newCategory) {
			category.addChild(newCategory);
			newCategory.resetChangedState();
			await persistSortOrders();
		}
	};
	const handleOnAddArticleRequested = async () => {
		const newArticleCategory = await onAddArticleRequested(category.getId());
		if (newArticleCategory) {
			new ArticleSortableTreeItem(
				{
					...newArticleCategory,
					sortOrder: sortedChildItems.length,
				},
				category,
			);
			await persistSortOrders();
		}
	};
	const handleOnAddQuizRequested = async () => {
		const newQuizCategory = await onAddQuizRequested(category.getId());
		if (newQuizCategory) {
			new QuizSortableTreeItem(
				{
					...newQuizCategory,
					sortOrder: sortedChildItems.length,
				},
				category,
			);
			await persistSortOrders();
		}
	};
	const handleOnAddResourceRequested = async () => {
		const newResourceCategory = await onAddResourceRequested(category.getId());
		if (newResourceCategory) {
			new ResourceSortableTreeItem(
				{
					...newResourceCategory,
					sortOrder: sortedChildItems.length,
				},
				category,
			);
			await persistSortOrders();
		}
	};
	const handleOnAddEventRequested = async () => {
		const newEventCategory = await onAddEventRequested(category.getId());
		if (newEventCategory) {
			new EventSortableTreeItem(
				{
					...newEventCategory,
					sortOrder: sortedChildItems.length,
				},
				category,
			);
			await persistSortOrders();
		}
	};

	const handleOnLinkArticleRequested = async () => {
		const linkedArticleCategory = await onLinkArticleRequested(category.getId());
		if (linkedArticleCategory) {
			new ArticleSortableTreeItem(
				{
					...linkedArticleCategory,
					sortOrder: sortedChildItems.length,
				},
				category,
			);
			await persistSortOrders();
		}
	};
	const handleOnLinkQuizRequested = async () => {
		const linkedQuizCategory = await onLinkQuizRequested(category.getId());
		if (linkedQuizCategory) {
			new QuizSortableTreeItem(
				{
					...linkedQuizCategory,
					sortOrder: sortedChildItems.length,
				},
				category,
			);
			await persistSortOrders();
		}
	};
	const handleOnLinkResourceRequested = async () => {
		const linkedResourceCategory = await onLinkResourceRequested(category.getId());
		if (linkedResourceCategory) {
			new ResourceSortableTreeItem(
				{
					...linkedResourceCategory,
					sortOrder: sortedChildItems.length,
				},
				category,
			);
		}
		await persistSortOrders();
	};
	const handleOnLinkEventRequested = async () => {
		const linkedEventCategory = await onLinkEventRequested(category.getId());
		if (linkedEventCategory) {
			new EventSortableTreeItem(
				{
					...linkedEventCategory,
					sortOrder: sortedChildItems.length,
				},
				category,
			);
		}
		await persistSortOrders();
	};

	const getArticleStatus = (item: ArticleCategoryListFragment): 'draft' | 'published' | 'changed' => {
		if (!item.article.isPublished) {
			return 'draft';
		}
		if (
			item.article.content?.markdownHash &&
			item.article.content.publishedMarkdownHash &&
			item.article.content.markdownHash !== item.article.content.publishedMarkdownHash
		) {
			return 'changed';
		}
		return 'published';
	};

	const getQuizStatus = (item: QuizCategoryListFragment): 'draft' | 'published' | 'changed' => {
		if (!item.quiz.isPublished) {
			return 'draft';
		}
		if (
			item.quiz.content?.markdownHash &&
			item.quiz.content.publishedMarkdownHash &&
			item.quiz.content.markdownHash !== item.quiz.content.publishedMarkdownHash
		) {
			return 'changed';
		}
		return 'published';
	};

	const handleTogglePublished = async (childItem: SortableTreeItem<ChildItem>) => {
		if (isArticleSortableTreeItem(childItem)) {
			const status = getArticleStatus(childItem.item);
			if (status === 'changed') {
				const updated = await onRepublishArticleRequested(childItem.item.article.id);
				childItem.item.article = updated;
			} else if (childItem.item.article.isPublished) {
				await onUnpublishArticleRequested(childItem.item.article.id);
				childItem.item.article.isPublished = false;
			} else {
				await onPublishArticleRequested(childItem.item.article.id);
				childItem.item.article.isPublished = true;
			}
		} else if (isQuizSortableTreeItem(childItem)) {
			const status = getQuizStatus(childItem.item);
			if (status === 'changed') {
				const updated = await onRepublishQuizRequested(childItem.item.quiz.id);
				childItem.item.quiz = updated;
			} else if (childItem.item.quiz.isPublished) {
				await onUnpublishQuizRequested(childItem.item.quiz.id);
				childItem.item.quiz.isPublished = false;
			} else {
				await onPublishQuizRequested(childItem.item.quiz.id);
				childItem.item.quiz.isPublished = true;
			}
		} else if (isResourceSortableTreeItem(childItem)) {
			if (childItem.item.resource.isPublished) {
				await onUnpublishResourceRequested(childItem.item.resource.id);
				childItem.item.resource.isPublished = false;
			} else {
				await onPublishResourceRequested(childItem.item.resource.id);
				childItem.item.resource.isPublished = true;
			}
		} else {
			throw new Error('Unknown child item type');
		}
		category.dispatchEvent('childrenChanged');
	};

	const handleChildItemReorder = (reorderedChildItems: SortableTreeItem<ChildItem>[]) => {
		reorderedChildItems.forEach((child, index) => {
			child.setSortOrder(index);
		});
	};

	const handleChildItemReorderStarted = async () => {
		category.children.forEach((child, i) => {
			originalOrders.current.set(child.id, i);
		});
	};

	const handleChildItemReorderCompleted = async () => {
		category.dispatchEvent('childrenChanged');
		// Need a small timeout to allow the dispatch to re-render the component
		setTimeout(async () => {
			await persistSortOrders();
		}, 5);
	};

	const handleChildItemAdded = async (event: SortableEvent) => {
		const itemId = event.item.dataset.id;
		if (itemId === undefined) {
			debugger;
			return;
		}

		const addedToParentCategoryId = event.to.parentElement?.parentElement?.parentElement?.dataset.id;
		if (addedToParentCategoryId === undefined) {
			debugger;
			return;
		}

		const addedToParentCategory = findItemById(
			addedToParentCategoryId,
		) as SortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null;
		if (!addedToParentCategory) {
			debugger;
			return;
		}

		if (event.item.dataset.categoryId) {
			// Is some sort of content contained in a category
			const existingChild = findItemById(itemId);

			if (existingChild) {
				addedToParentCategory.addChild(existingChild, event.newIndex);
			}
		} else {
			// assumed to be a category
			const addedCategory = itemId === category?.id ? category : findItemById(itemId);
			if (addedCategory) {
				addedToParentCategory.addChild(addedCategory, event.newIndex);
			}
		}
		await persistSortOrders();
	};

	const handleChildItemRemoved = async (event: SortableEvent) => {
		const itemId = event.item.dataset.id;
		if (itemId === undefined) {
			debugger;
			return;
		}

		const removedFromParentCategoryId = event.from.parentElement?.parentElement?.parentElement?.dataset.id;
		if (removedFromParentCategoryId === undefined) {
			debugger;
			return;
		}

		const removedFromParentCategory = findItemById(removedFromParentCategoryId);
		if (!removedFromParentCategory) {
			debugger;
			return;
		}

		if (event.item.dataset.categoryId) {
			// Is some sort of content contained in a category
			removedFromParentCategory.removeChild(itemId);
		} else {
			// assumed to be a category
			const removedCategory = itemId === category?.id ? category : findItemById(itemId);
			if (removedCategory) {
				removedFromParentCategory.removeChild(removedCategory.id);
			}
		}
		await persistSortOrders();
	};

	const handleDeleteCategoryRequested = async () => {
		await openConfirmModal({
			title: 'Delete Category',
			danger: true,
			children: (
				<Text>
					Are you sure you want to delete the category &quot;{category.item.name}&quot;?
					<br />
					This will delete all sub-categories and unlink all articles, quizzes and resources.
				</Text>
			),
			onConfirm: async () => {
				await onDeleteCategoryRequested(category.id);
				// This should bubble up to the parent component, which should trigger a re-render of this component
				onSelfDeleted?.(category.id);
			},
		});
	};

	const handleOnSetCategoryPrerequisitesRequested = async () => {
		const updatedCategory = await onSetCategoryPrerequisitesRequested(category);
		if (updatedCategory) {
			category.dispatchEvent('changed');
		}
	};

	const handleUnlinkArticleRequested = async (childItem: ArticleSortableTreeItem<ArticleCategoryListFragment>) => {
		await openConfirmModal({
			title: 'Unlink article',
			danger: true,
			children: (
				<Text>Are you sure you want to unlink the article &quot;{childItem.item.article.title}&quot;?</Text>
			),
			onConfirm: async () => {
				await onUnlinkArticleRequested(childItem.item.article.id, category.id);
				category.removeChild(childItem.id);
				await persistSortOrders();
			},
		});
	};

	const handleUnlinkQuizRequested = async (childItem: QuizSortableTreeItem<QuizCategoryListFragment>) => {
		await openConfirmModal({
			title: 'Unlink quiz',
			danger: true,
			children: <Text>Are you sure you want to unlink the quiz &quot;{childItem.item.quiz.title}&quot;?</Text>,
			onConfirm: async () => {
				await onUnlinkQuizRequested(childItem.item.quiz.id, category.id);
				category.removeChild(childItem.id);
				await persistSortOrders();
			},
		});
	};

	const handleUnlinkResourceRequested = async (childItem: ResourceSortableTreeItem<ResourceCategoryListFragment>) => {
		await openConfirmModal({
			title: 'Unlink resource',
			danger: true,
			children: (
				<Text>Are you sure you want to unlink the resource &quot;{childItem.item.resource.title}&quot;?</Text>
			),
			onConfirm: async () => {
				await onUnlinkResourceRequested(childItem.item.resource.id, category.id);
				category.removeChild(childItem.id);
				await persistSortOrders();
			},
		});
	};

	const handleUnlinkEventRequested = async (childItem: EventSortableTreeItem<EventCategoryListFragment>) => {
		await openConfirmModal({
			title: 'Unlink event',
			danger: true,
			children: <Text>Are you sure you want to unlink the event &quot;{childItem.item.event.title}&quot;?</Text>,
			onConfirm: async () => {
				await onUnlinkEventRequested(childItem.item.event.id, category.id);
				category.removeChild(childItem.id);
				await persistSortOrders();
			},
		});
	};

	const handleOnChildCategoryDeleted = async (childCategoryId: string) => {
		category.removeChild(childCategoryId);
		await persistSortOrders();
	};

	const handleOnUpdateCategoryRequested = async () => {
		const updatedCategory = await onUpdateCategoryRequested(category);
		if (updatedCategory) {
			category.dispatchEvent('changed');
		}
	};
	const handleOnUpdateArticleRequested = async (
		articleChildItem: ArticleSortableTreeItem<ArticleCategoryListFragment>,
	) => {
		const updatedArticle = await onUpdateArticleRequested(articleChildItem.item.article);
		if (updatedArticle) {
			articleChildItem.item.article = updatedArticle;
			category.dispatchEvent('childrenChanged');
		}
	};
	const handleOnUpdateQuizRequested = async (quizChildItem: QuizSortableTreeItem<QuizCategoryListFragment>) => {
		const updatedQuiz = await onUpdateQuizRequested(quizChildItem.item.quiz);
		if (updatedQuiz) {
			quizChildItem.item.quiz = updatedQuiz;
			category.dispatchEvent('childrenChanged');
		}
	};
	const handleOnUpdateResourceRequested = async (
		resourceChildItem: ResourceSortableTreeItem<ResourceCategoryListFragment>,
	) => {
		const updatedResource = await onUpdateResourceRequested(resourceChildItem.item.resource);
		if (updatedResource) {
			resourceChildItem.item.resource = updatedResource;
			category.dispatchEvent('childrenChanged');
		}
	};
	const handleOnUpdateEventRequested = async (eventChildItem: EventSortableTreeItem<EventCategoryListFragment>) => {
		const updatedEvent = await onUpdateEventRequested(eventChildItem.item.event);
		if (updatedEvent) {
			eventChildItem.item.event = updatedEvent;
			category.dispatchEvent('childrenChanged');
		}
	};

	const handleOnPinArticleCategoryRequested = async (
		articleChildItem: ArticleSortableTreeItem<ArticleCategoryListFragment>,
	) => {
		articleChildItem.item.isPinned = !articleChildItem.item.isPinned;
		category.dispatchEvent('childrenChanged');

		await onPinArticleCategoryRequested(articleChildItem.id, articleChildItem.item.isPinned);
	};

	const handleOnPinResourceCategoryRequested = async (
		resourceChildItem: ResourceSortableTreeItem<ResourceCategoryListFragment>,
	) => {
		resourceChildItem.item.isPinned = !resourceChildItem.item.isPinned;
		await onPinResourceCategoryRequested(resourceChildItem.id, resourceChildItem.item.isPinned);
		category.dispatchEvent('childrenChanged');
	};

	const handleOnPinEventCategoryRequested = async (
		eventChildItem: EventSortableTreeItem<EventCategoryListFragment>,
	) => {
		eventChildItem.item.isPinned = !eventChildItem.item.isPinned;
		await onPinEventCategoryRequested(eventChildItem.id, eventChildItem.item.isPinned);
		category.dispatchEvent('childrenChanged');
	};

	const handleOnPinQuizCategoryRequested = async (quizChildItem: QuizSortableTreeItem<QuizCategoryListFragment>) => {
		quizChildItem.item.isPinned = !quizChildItem.item.isPinned;
		await onPinQuizCategoryRequested(quizChildItem.id, quizChildItem.item.isPinned);
		category.dispatchEvent('childrenChanged');
	};

	const handleOnRequiredArticleCategoryRequested = async (
		articleChildItem: ArticleSortableTreeItem<ArticleCategoryListFragment>,
	) => {
		articleChildItem.item.isRequired = !articleChildItem.item.isRequired;
		category.dispatchEvent('childrenChanged');

		await onRequiredArticleCategoryRequested(articleChildItem.id, articleChildItem.item.isRequired);
	};

	const handleOnRequiredResourceCategoryRequested = async (
		resourceChildItem: ResourceSortableTreeItem<ResourceCategoryListFragment>,
	) => {
		resourceChildItem.item.isRequired = !resourceChildItem.item.isRequired;
		await onRequiredResourceCategoryRequested(resourceChildItem.id, resourceChildItem.item.isRequired);
		category.dispatchEvent('childrenChanged');
	};

	const handleOnRequiredEventCategoryRequested = async (
		eventChildItem: EventSortableTreeItem<EventCategoryListFragment>,
	) => {
		eventChildItem.item.isRequired = !eventChildItem.item.isRequired;
		await onRequiredEventCategoryRequested(eventChildItem.id, eventChildItem.item.isRequired);
		category.dispatchEvent('childrenChanged');
	};

	const handleOnRequiredQuizCategoryRequested = async (
		quizChildItem: QuizSortableTreeItem<QuizCategoryListFragment>,
	) => {
		quizChildItem.item.isRequired = !quizChildItem.item.isRequired;
		await onRequiredQuizCategoryRequested(quizChildItem.id, quizChildItem.item.isRequired);
		category.dispatchEvent('childrenChanged');
	};

	const handleOnDuplicateArticleRequested = async (
		articleChildItem: ArticleSortableTreeItem<ArticleCategoryListFragment>,
	) => {
		await openConfirmModal({
			title: 'Duplicate article',
			confirmText: 'Yes',
			children: (
				<Text>
					Are you sure you want to duplicate the article &quot;{articleChildItem.item.article.title}&quot;?
				</Text>
			),
			onConfirm: async () => {
				const duplicatedArticle = await onDuplicateArticleRequested(
					articleChildItem.item.article.id,
					category.id,
					articleChildItem.getSortOrder(),
				);
				if (duplicatedArticle) {
					new ArticleSortableTreeItem(
						{
							...duplicatedArticle,
						},
						category,
					);
					await persistSortOrders();
				}
			},
		});
	};

	const handleOnDuplicateQuizRequested = async (quizChildItem: QuizSortableTreeItem<QuizCategoryListFragment>) => {
		await openConfirmModal({
			title: 'Duplicate quiz',
			confirmText: 'Yes',
			children: (
				<Text>Are you sure you want to duplicate the quiz &quot;{quizChildItem.item.quiz.title}&quot;?</Text>
			),
			onConfirm: async () => {
				const duplicatedQuiz = await onDuplicateQuizRequested(
					quizChildItem.item.quiz.id,
					category.id,
					quizChildItem.getSortOrder(),
				);
				if (duplicatedQuiz) {
					new QuizSortableTreeItem(
						{
							...duplicatedQuiz,
						},
						category,
					);
					await persistSortOrders();
				}
			},
		});
	};

	const handleOnSetQuizRequirementsRequested = async (
		quizChildItem: QuizSortableTreeItem<QuizCategoryListFragment>,
	) => {
		const updated = await onSetQuizRequirementsRequested(quizChildItem.item);
		if (updated) {
			quizChildItem.item = updated;
			category.dispatchEvent('childrenChanged');
		}
	};

	const handleOnSetEventRequirementsRequested = async (
		eventChildItem: EventSortableTreeItem<EventCategoryListFragment>,
	) => {
		const updated = await onSetEventRequirementsRequested(eventChildItem.item);
		if (updated) {
			eventChildItem.item = updated;
			category.dispatchEvent('childrenChanged');
		}
	};

	const handleToggleCollapsed = (item: SortableTreeItem<unknown>) => {
		item.isCollapsed = !item.isCollapsed;
	};

	return (
		<StyledSortableTree
			className={`SortableTree ${className ?? ''} ${isRootCategory ? 'root' : ''}`}
			data-id={category.id}
		>
			<Table.Tr className={`category ${isRootCategory ? 'root' : ''}`} m="0" py="sm">
				<Table.Td>
					{!isRootCategory && (
						<Flex wrap="nowrap">
							<ActionIcon
								className="reorder-handle"
								title="Drag to rearrange"
								variant="subtle"
								color="gray"
							>
								<IconArrowsMoveVertical />
							</ActionIcon>
							<ActionIcon
								variant="subtle"
								color="gray"
								title={category.isCollapsed ? 'Expand' : 'Collapse'}
								onClick={() => handleToggleCollapsed(category)}
							>
								{category.isCollapsed ? <IconChevronDown /> : <IconChevronUp />}
							</ActionIcon>
						</Flex>
					)}
				</Table.Td>
				<Table.Td>
					<Text>
						<strong>{category.item.name}</strong>
					</Text>
				</Table.Td>
				<Table.Td className="action-buttons">
					<div>
						<Menu shadow="md" width={200} withinPortal={false}>
							<Menu.Target>
								<ActionIcon
									className="action-button"
									title={`Add in ${category.item.name}`}
									color="blue"
									variant="subtle"
								>
									<IconFilePlus />
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item leftSection={<IconBinaryTree />} onClick={handleOnAddSubCategoryRequested}>
									Sub-category
								</Menu.Item>
								{!isRootCategory && (
									<Menu.Item leftSection={<IconFileText />}>
										<Menu shadow="md" trigger="hover" position="left" withinPortal={false}>
											<Menu.Target>
												<Text>Article</Text>
											</Menu.Target>
											<Menu.Dropdown>
												<SubMenuItem
													leftSection={<IconFileText />}
													onClick={handleOnAddArticleRequested}
												>
													New article
												</SubMenuItem>
												<SubMenuItem
													leftSection={<IconLink />}
													onClick={handleOnLinkArticleRequested}
												>
													Use existing
												</SubMenuItem>
											</Menu.Dropdown>
										</Menu>
									</Menu.Item>
								)}
								{!isRootCategory && (
									<Menu.Item leftSection={<IconCertificate />}>
										<Menu shadow="md" trigger="hover" position="left" withinPortal={false}>
											<Menu.Target>
												<Text>Quiz</Text>
											</Menu.Target>
											<Menu.Dropdown>
												<SubMenuItem
													leftSection={<IconCertificate />}
													onClick={handleOnAddQuizRequested}
												>
													New quiz
												</SubMenuItem>
												<SubMenuItem
													leftSection={<IconLink />}
													onClick={handleOnLinkQuizRequested}
												>
													Use existing
												</SubMenuItem>
											</Menu.Dropdown>
										</Menu>
									</Menu.Item>
								)}
								{!isRootCategory && (
									<Menu.Item leftSection={<IconFileDownload />}>
										<Menu shadow="md" trigger="hover" position="left" withinPortal={false}>
											<Menu.Target>
												<Text>Resource</Text>
											</Menu.Target>
											<Menu.Dropdown>
												<SubMenuItem
													leftSection={<IconCertificate />}
													onClick={handleOnAddResourceRequested}
												>
													New resource
												</SubMenuItem>
												<SubMenuItem
													leftSection={<IconLink />}
													onClick={handleOnLinkResourceRequested}
												>
													Use existing
												</SubMenuItem>
											</Menu.Dropdown>
										</Menu>
									</Menu.Item>
								)}
								{!isRootCategory && (
									<Menu.Item leftSection={<IconCalendar />}>
										<Menu shadow="md" trigger="hover" position="left" withinPortal={false}>
											<Menu.Target>
												<Text>Event</Text>
											</Menu.Target>
											<Menu.Dropdown>
												<SubMenuItem
													leftSection={<IconFileText />}
													onClick={handleOnAddEventRequested}
												>
													New event
												</SubMenuItem>
												<SubMenuItem
													leftSection={<IconLink />}
													onClick={handleOnLinkEventRequested}
												>
													Use existing
												</SubMenuItem>
											</Menu.Dropdown>
										</Menu>
									</Menu.Item>
								)}
							</Menu.Dropdown>
						</Menu>
						{!isRootCategory && (
							<Menu shadow="md" width={200} withinPortal={false}>
								<Menu.Target>
									<ActionIcon color="dark" className="action-button" variant="subtle">
										<IconDotsVertical />
									</ActionIcon>
								</Menu.Target>
								<Menu.Dropdown>
									<Menu.Item leftSection={<IconPencil />} onClick={handleOnUpdateCategoryRequested}>
										Edit category
									</Menu.Item>
									{category.item.parentId ? null : (
										<Menu.Item
											leftSection={<IconArrowMerge />}
											onClick={handleOnSetCategoryPrerequisitesRequested}
										>
											Set prerequisites
										</Menu.Item>
									)}
									<Menu.Item
										leftSection={<IconTrash />}
										onClick={handleDeleteCategoryRequested}
										color="red"
									>
										Delete category
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						)}
					</div>
				</Table.Td>
			</Table.Tr>
			{(isRootCategory || !category.isCollapsed) && (
				<Table.Tr m="0" className="children">
					<Table.Td colSpan={3}>
						<ReactSortable<SortableTreeItem<ChildItem>>
							tag={ForwardedTable}
							list={sortedChildItems}
							setList={handleChildItemReorder}
							onAdd={handleChildItemAdded}
							onRemove={handleChildItemRemoved}
							onStart={handleChildItemReorderStarted}
							onEnd={handleChildItemReorderCompleted}
							animation={150}
							direction="vertical"
							swapThreshold={0.5}
							group={{
								name: 'sortable',
								put: (to, _, dragEl) => {
									const isContent =
										dragEl.classList.contains('article') ||
										dragEl.classList.contains('quiz') ||
										dragEl.classList.contains('resource');

									// If it's content, only allow putting it into a subcategory (ie a parent element that has an id)
									if (isContent) {
										return to.el.parentElement?.parentElement?.dataset.id !== 'root';
									} else {
										return true;
									}
								},
							}}
							handle=".reorder-handle"
							data-id={category.item.id}
						>
							{sortedChildItems.length ? (
								sortedChildItems.map((childItem) => {
									if (childItem instanceof CategorySortableTreeItem) {
										return (
											<Table.Tr key={childItem.getId()}>
												<Table.Td colSpan={5}>
													<SortableTree
														category={childItem}
														onAddSubCategoryRequested={onAddSubCategoryRequested}
														onAddArticleRequested={onAddArticleRequested}
														onAddQuizRequested={onAddQuizRequested}
														onAddResourceRequested={onAddResourceRequested}
														onAddEventRequested={onAddEventRequested}
														onLinkArticleRequested={onLinkArticleRequested}
														onLinkQuizRequested={onLinkQuizRequested}
														onLinkResourceRequested={onLinkResourceRequested}
														onLinkEventRequested={onLinkEventRequested}
														onDeleteCategoryRequested={onDeleteCategoryRequested}
														onUnlinkArticleRequested={onUnlinkArticleRequested}
														onUnlinkQuizRequested={onUnlinkQuizRequested}
														onUnlinkResourceRequested={onUnlinkResourceRequested}
														onUnlinkEventRequested={onUnlinkEventRequested}
														onUpdateCategoryRequested={onUpdateCategoryRequested}
														onSetCategoryPrerequisitesRequested={
															onSetCategoryPrerequisitesRequested
														}
														onUpdateArticleRequested={onUpdateArticleRequested}
														onUpdateQuizRequested={onUpdateQuizRequested}
														onUpdateResourceRequested={onUpdateResourceRequested}
														onUpdateEventRequested={onUpdateEventRequested}
														onPinArticleCategoryRequested={onPinArticleCategoryRequested}
														onPinResourceCategoryRequested={onPinResourceCategoryRequested}
														onPinEventCategoryRequested={onPinEventCategoryRequested}
														onPinQuizCategoryRequested={onPinQuizCategoryRequested}
														onRequiredArticleCategoryRequested={
															onRequiredArticleCategoryRequested
														}
														onRequiredResourceCategoryRequested={
															onRequiredResourceCategoryRequested
														}
														onRequiredEventCategoryRequested={
															onRequiredEventCategoryRequested
														}
														onRequiredQuizCategoryRequested={
															onRequiredQuizCategoryRequested
														}
														onTreeModified={onTreeModified}
														onDuplicateArticleRequested={onDuplicateArticleRequested}
														onDuplicateQuizRequested={onDuplicateQuizRequested}
														onPublishArticleRequested={onPublishArticleRequested}
														onRepublishArticleRequested={onRepublishArticleRequested}
														onUnpublishArticleRequested={onUnpublishArticleRequested}
														onPublishQuizRequested={onPublishQuizRequested}
														onRepublishQuizRequested={onRepublishQuizRequested}
														onUnpublishQuizRequested={onUnpublishQuizRequested}
														onPublishResourceRequested={onPublishResourceRequested}
														onUnpublishResourceRequested={onUnpublishResourceRequested}
														onSetQuizRequirementsRequested={onSetQuizRequirementsRequested}
														onSetEventRequirementsRequested={
															onSetEventRequirementsRequested
														}
														onSelfDeleted={handleOnChildCategoryDeleted}
														findItemById={findItemById}
													>
														{childItem.item.name}
													</SortableTree>
												</Table.Td>
											</Table.Tr>
										);
									} else if (isQuizSortableTreeItem(childItem)) {
										return (
											<Table.Tr
												key={childItem.id}
												data-category-id={category.id}
												data-type="quiz"
												className="quiz"
												m="0"
											>
												<Table.Td>
													<ActionIcon
														className="reorder-handle"
														title="Drag to rearrange"
														variant="subtle"
														color="gray"
													>
														<IconArrowsMoveVertical />
													</ActionIcon>
												</Table.Td>
												<Table.Td>
													<IconCertificate />
												</Table.Td>
												<Table.Td w="100%">
													<Text>{childItem.item.quiz.title}</Text>
												</Table.Td>
												<Table.Td className="right">
													{childItem.item.isRequired && <StatusBadge status="required" />}
													<StatusBadge status={getQuizStatus(childItem.item)} />
												</Table.Td>
												<Table.Td className="action-buttons">
													<div>
														{childItem.item.isRequired && <StatusBadge status="required" />}
														<StatusBadge
															status={getQuizStatus(childItem.item)}
															onClick={() => handleTogglePublished(childItem)}
														/>
														{childItem.item.isPinned && (
															<IconPin color={theme.colors.blue[7]} />
														)}

														<Link
															className="action-button"
															to={`/quizzes/${childItem.item.quiz.id}`}
														>
															<Button variant="subtle" size="small">
																Edit content
															</Button>
														</Link>
														<Menu shadow="md" width={200} withinPortal={false}>
															<Menu.Target>
																<ActionIcon
																	color="dark"
																	className="action-button"
																	variant="subtle"
																>
																	<IconDotsVertical />
																</ActionIcon>
															</Menu.Target>
															<Menu.Dropdown>
																<Menu.Item
																	leftSection={<IconPencil />}
																	onClick={() =>
																		handleOnUpdateQuizRequested(childItem)
																	}
																>
																	Edit quiz
																</Menu.Item>
																<Menu.Item
																	leftSection={
																		childItem.item.isPinned ? (
																			<IconPinnedOff />
																		) : (
																			<IconPin />
																		)
																	}
																	onClick={() =>
																		handleOnPinQuizCategoryRequested(childItem)
																	}
																>
																	{childItem.item.isPinned
																		? 'Un-pin quiz'
																		: 'Pin quiz'}
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconAsterisk />}
																	onClick={() =>
																		handleOnRequiredQuizCategoryRequested(childItem)
																	}
																>
																	{childItem.item.isRequired
																		? 'Make optional'
																		: 'Make mandatory'}
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconCopy />}
																	onClick={() =>
																		handleOnDuplicateQuizRequested(childItem)
																	}
																>
																	Duplicate quiz
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconSettings />}
																	onClick={() =>
																		handleOnSetQuizRequirementsRequested(childItem)
																	}
																>
																	Set requirements
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconUnlink />}
																	onClick={() => handleUnlinkQuizRequested(childItem)}
																	color="red"
																>
																	Unlink quiz
																</Menu.Item>
															</Menu.Dropdown>
														</Menu>
													</div>
												</Table.Td>
											</Table.Tr>
										);
									} else if (isResourceSortableTreeItem(childItem)) {
										return (
											<Table.Tr
												key={childItem.id}
												data-category-id={category.id}
												data-type="resource"
												className="resource"
												m="0"
											>
												<Table.Td>
													<ActionIcon
														className="reorder-handle"
														title="Drag to rearrange"
														variant="subtle"
														color="gray"
													>
														<IconArrowsMoveVertical />
													</ActionIcon>
												</Table.Td>
												<Table.Td>
													<IconFileDownload />
												</Table.Td>
												<Table.Td w="100%">
													<Text>{childItem.item.resource.title}</Text>
												</Table.Td>
												<Table.Td className="right">
													{childItem.item.isRequired && <StatusBadge status="required" />}
													<StatusBadge
														status={
															childItem.item.resource.isPublished ? 'published' : 'draft'
														}
													/>
												</Table.Td>
												<Table.Td className="action-buttons">
													<div>
														{childItem.item.isRequired && <StatusBadge status="required" />}
														<StatusBadge
															status={
																childItem.item.resource.isPublished
																	? 'published'
																	: 'draft'
															}
															onClick={() => handleTogglePublished(childItem)}
														/>
														{childItem.item.isPinned && (
															<IconPin color={theme.colors.blue[7]} />
														)}

														<Link
															className="action-button"
															to={childItem.item.resource.url}
															target="_blank"
														>
															<Button variant="subtle" size="small">
																View
															</Button>
														</Link>
														<Menu shadow="md" width={200} withinPortal={false}>
															<Menu.Target>
																<ActionIcon
																	color="dark"
																	className="action-button"
																	variant="subtle"
																>
																	<IconDotsVertical />
																</ActionIcon>
															</Menu.Target>
															<Menu.Dropdown>
																<Menu.Item
																	leftSection={<IconPencil />}
																	onClick={() =>
																		handleOnUpdateResourceRequested(childItem)
																	}
																>
																	Edit resource
																</Menu.Item>
																<Menu.Item
																	leftSection={
																		childItem.item.isPinned ? (
																			<IconPinnedOff />
																		) : (
																			<IconPin />
																		)
																	}
																	onClick={() =>
																		handleOnPinResourceCategoryRequested(childItem)
																	}
																>
																	{childItem.item.isPinned
																		? 'Un-pin resource'
																		: 'Pin resource'}
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconAsterisk />}
																	onClick={() =>
																		handleOnRequiredResourceCategoryRequested(
																			childItem,
																		)
																	}
																>
																	{childItem.item.isRequired
																		? 'Make optional'
																		: 'Make mandatory'}
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconUnlink />}
																	onClick={() =>
																		handleUnlinkResourceRequested(childItem)
																	}
																	color="red"
																>
																	Unlink resource
																</Menu.Item>
															</Menu.Dropdown>
														</Menu>
													</div>
												</Table.Td>
											</Table.Tr>
										);
									} else if (isEventSortableTreeItem(childItem)) {
										return (
											<Table.Tr
												key={childItem.id}
												data-category-id={category.id}
												data-type="event"
												className="event"
												m="0"
											>
												<Table.Td>
													<ActionIcon
														className="reorder-handle"
														title="Drag to rearrange"
														variant="subtle"
														color="gray"
													>
														<IconArrowsMoveVertical />
													</ActionIcon>
												</Table.Td>
												<Table.Td>
													<IconCalendar />
												</Table.Td>
												<Table.Td w="100%">
													<Text>{childItem.item.event.title}</Text>
												</Table.Td>
												<Table.Td>
													{childItem.item.isRequired && <StatusBadge status="required" />}
												</Table.Td>
												<Table.Td className="action-buttons">
													<div>
														{childItem.item.isRequired && <StatusBadge status="required" />}
														{childItem.item.isPinned && (
															<IconPin color={theme.colors.blue[7]} />
														)}

														<Link
															className="action-button"
															to={`/events/${childItem.item.event.id}`}
															target="_blank"
														>
															<Button variant="subtle" size="small">
																View
															</Button>
														</Link>
														<Menu shadow="md" width={200} withinPortal={false}>
															<Menu.Target>
																<ActionIcon
																	color="dark"
																	className="action-button"
																	variant="subtle"
																>
																	<IconDotsVertical />
																</ActionIcon>
															</Menu.Target>
															<Menu.Dropdown>
																<Menu.Item
																	leftSection={<IconPencil />}
																	onClick={() =>
																		handleOnUpdateEventRequested(childItem)
																	}
																>
																	Edit event
																</Menu.Item>
																<Menu.Item
																	leftSection={
																		childItem.item.isPinned ? (
																			<IconPinnedOff />
																		) : (
																			<IconPin />
																		)
																	}
																	onClick={() =>
																		handleOnPinEventCategoryRequested(childItem)
																	}
																>
																	{childItem.item.isPinned
																		? 'Un-pin event'
																		: 'Pin event'}
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconAsterisk />}
																	onClick={() =>
																		handleOnRequiredEventCategoryRequested(
																			childItem,
																		)
																	}
																>
																	{childItem.item.isRequired
																		? 'Make optional'
																		: 'Make mandatory'}
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconSettings />}
																	onClick={() =>
																		handleOnSetEventRequirementsRequested(childItem)
																	}
																>
																	Set requirements
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconUnlink />}
																	onClick={() =>
																		handleUnlinkEventRequested(childItem)
																	}
																	color="red"
																>
																	Unlink event
																</Menu.Item>
															</Menu.Dropdown>
														</Menu>
													</div>
												</Table.Td>
											</Table.Tr>
										);
									} else if (isArticleSortableTreeItem(childItem)) {
										return (
											<Table.Tr
												key={childItem.id}
												data-category-id={category.id}
												data-type="article"
												className="article"
												m="0"
											>
												<Table.Td>
													<ActionIcon
														className="reorder-handle"
														title="Drag to rearrange"
														variant="subtle"
														color="gray"
													>
														<IconArrowsMoveVertical />
													</ActionIcon>
												</Table.Td>
												<Table.Td>
													<IconFileText />
												</Table.Td>
												<Table.Td w="100%">
													<Text>{childItem.item.article.title}</Text>
												</Table.Td>
												<Table.Td className="right">
													{childItem.item.isRequired && <StatusBadge status="required" />}
													<StatusBadge status={getArticleStatus(childItem.item)} />
												</Table.Td>
												<Table.Td className="action-buttons">
													<div>
														{childItem.item.isRequired && <StatusBadge status="required" />}
														<StatusBadge
															status={getArticleStatus(childItem.item)}
															onClick={() => handleTogglePublished(childItem)}
														/>
														{childItem.item.isPinned && (
															<IconPin color={theme.colors.blue[7]} />
														)}

														<Link
															className="action-button"
															to={`/articles/${childItem.item.article.id}`}
														>
															<Button variant="subtle" size="small">
																Edit content
															</Button>
														</Link>
														<Menu shadow="md" width={200} withinPortal={false}>
															<Menu.Target>
																<ActionIcon
																	color="dark"
																	className="action-button"
																	variant="subtle"
																>
																	<IconDotsVertical />
																</ActionIcon>
															</Menu.Target>
															<Menu.Dropdown>
																<Menu.Item
																	leftSection={<IconPencil />}
																	onClick={() =>
																		handleOnUpdateArticleRequested(childItem)
																	}
																>
																	Edit article
																</Menu.Item>
																<Menu.Item
																	leftSection={
																		childItem.item.isPinned ? (
																			<IconPinnedOff />
																		) : (
																			<IconPin />
																		)
																	}
																	onClick={() =>
																		handleOnPinArticleCategoryRequested(childItem)
																	}
																>
																	{childItem.item.isPinned
																		? 'Un-pin article'
																		: 'Pin article'}
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconAsterisk />}
																	onClick={() =>
																		handleOnRequiredArticleCategoryRequested(
																			childItem,
																		)
																	}
																>
																	{childItem.item.isRequired
																		? 'Make optional'
																		: 'Make mandatory'}
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconCopy />}
																	onClick={() =>
																		handleOnDuplicateArticleRequested(childItem)
																	}
																>
																	Duplicate article
																</Menu.Item>
																<Menu.Item
																	leftSection={<IconUnlink />}
																	onClick={() =>
																		handleUnlinkArticleRequested(childItem)
																	}
																	color="red"
																>
																	Unlink article
																</Menu.Item>
															</Menu.Dropdown>
														</Menu>
													</div>
												</Table.Td>
											</Table.Tr>
										);
									} else {
										console.error(`Unknown child item type: ${childItem}`);
									}
								})
							) : (
								<Text ta="center" c="grey">
									Category is empty
								</Text>
							)}
						</ReactSortable>
					</Table.Td>
				</Table.Tr>
			)}
		</StyledSortableTree>
	);
}

const ForwardedTable = forwardRef<HTMLTableElement, TableProps>(function ForwardedTable(props, ref) {
	return (
		<Table
			ref={ref}
			withTableBorder={false}
			withRowBorders={false}
			withColumnBorders={false}
			verticalSpacing={0}
			horizontalSpacing={0}
			{...props}
		/>
	);
});

const StyledSortableTree = styled(ForwardedTable)`
	&,
	.mantine-Table-table {
		border: 1px dashed var(--mantine-color-gray-5);
	}

	.children > td > table {
		border: none;
	}

	.right {
		text-align: right;
	}

	.center {
		text-align: center;
	}

	td {
		padding: var(--mantine-spacing-xs);
		&:first-child {
			width: 0;
		}
	}

	.action-buttons {
		position: relative;

		> div {
			padding: var(--mantine-spacing-xs);
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			gap: var(--mantine-spacing-xs);
			background: var(--mantine-color-white);
		}
	}

	.category,
	.article,
	.quiz,
	.resource,
	.event {
		//min-height: 36px;
		// 'a.action-button': {
		// 	textDecoration: 'none',
		// },
		&:not(.root) {
			.action-buttons > div {
				visibility: hidden;
			}
			&:hover {
				background: var(--mantine-color-gray-1);
				.action-buttons > div {
					background: var(--mantine-color-gray-1);
					visibility: visible;
				}
			}
		}
	}
`;

const SubMenuItem = styled(Menu.Item)<PolymorphicComponentProps<'button', MenuItemProps>>`
	background-color: var(--mantine-color-white);

	&:hover {
		background: var(--mantine-color-gray-1);
	}
`;

function isArticleSortableTreeItem(
	item: SortableTreeItem<ChildItem>,
): item is ArticleSortableTreeItem<ArticleCategoryListFragment> {
	return item instanceof ArticleSortableTreeItem;
}

function isQuizSortableTreeItem(
	item: SortableTreeItem<ChildItem>,
): item is QuizSortableTreeItem<QuizCategoryListFragment> {
	return item instanceof QuizSortableTreeItem;
}

function isResourceSortableTreeItem(
	item: SortableTreeItem<ChildItem>,
): item is ResourceSortableTreeItem<ResourceCategoryListFragment> {
	return item instanceof ResourceSortableTreeItem;
}

function isEventSortableTreeItem(
	item: SortableTreeItem<ChildItem>,
): item is EventSortableTreeItem<EventCategoryListFragment> {
	return item instanceof EventSortableTreeItem;
}

export type ChildItem =
	| CategoryListWithContentAndPrerequisitesFragment
	| ArticleCategoryListFragment
	| QuizCategoryListFragment
	| ResourceCategoryListFragment
	| EventCategoryListFragment;

function useChildItems(category: CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>) {
	const forceUpdate = useForceUpdate();

	// const categoryHash = category.getHash();
	const childHash = hash(category.children.map((child, i) => `${i}${child.getHash()}`));

	useEffect(() => {
		const unsubscribeChildrenChanged = category.addEventListener('childrenChanged', forceUpdate);
		const unsubscribeChanged = category.addEventListener('changed', forceUpdate);
		return () => {
			unsubscribeChildrenChanged();
			unsubscribeChanged();
		};
	}, [category, forceUpdate]);

	const sortedChildItems = useMemo(
		() => {
			return [
				...(category.children.sort(SortableTreeItem.compareBySortOrder) as SortableTreeItem<ChildItem>[]),
			].filter((x) => x !== undefined && x !== null);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[childHash],
	);

	return sortedChildItems;
}
