import React, { useState } from 'react';
import Content from '../Content';
import { useEventService } from '../../services/EventService';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import { EventOccurrenceRegistrationsQuery, EventRegistrationFragment } from '../../graphql/graphql';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import DateTimeUtils, { DATE_TIME_FORMAT } from '@dr-pam/common-components/Utils/DateTimeUtils';
import CrudTable from '../crud/CrudTable';

export type EventOccurrencesListProps = {
	className?: string;
	eventId: string;
	eventOccurrenceId: string;
};

export default function EventOccurrencesList(props: EventOccurrencesListProps) {
	const { className, eventOccurrenceId } = props;

	const eventService = useEventService();
	eventService.getOccurrenceWithRegistrations;

	const [eventOccurrence, setEventOccurrence] =
		useState<EventOccurrenceRegistrationsQuery['eventOccurrence']>(undefined);

	const isLoadingEventOccurrence = useLoadingEffect(async () => {
		try {
			const request = eventService.getOccurrenceWithRegistrations(eventOccurrenceId);
			const response = await request.response;
			setEventOccurrence(response);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load event occurrence.');
		}
	}, [eventService, eventOccurrenceId]);

	const title = DateTimeUtils.format(eventOccurrence?.start, DATE_TIME_FORMAT) ?? 'Event Occurrence';

	const breadcrumbs = {
		'/events': 'Events',
		[`/events/${eventOccurrence?.event.id}`]: eventOccurrence?.event.title ?? 'Event',
		'#': title,
	};

	const items: EventRegistrationFragment[] = eventOccurrence?.eventRegistrations ?? [];

	const crudHeaders = [{ children: 'Name' }, { children: 'Email' }, { children: `User's local timezone` }];
	const crudColumns = (registration: EventRegistrationFragment) => [
		{ children: registration.user.fullName },
		{ children: registration.user.email },
		{ children: registration.userTimezone },
	];

	return (
		<Content
			className={`EventOccurrencesList ${className ?? ''}`}
			breadcrumbs={breadcrumbs}
			title={title}
			fillHeight
		>
			<CrudTable<EventRegistrationFragment>
				title={{ order: 2, children: 'Occurrences' }}
				items={items}
				isLoading={isLoadingEventOccurrence}
				headers={crudHeaders}
				columns={crudColumns}
				entityName="event registration"
			/>
		</Content>
	);
}
