import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { modals } from '@mantine/modals';
import { useForm } from '@mantine/form';
import { Stack, Button, FileButton, Group, Loader, TextInput, Tooltip, ActionIcon, Text } from '@mantine/core';
import { IconFilePlus, IconTrashX } from '@tabler/icons-react';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type InsertAudioModalProps = {
	modalId: string;
	className?: string;
	initialValues?: Partial<InsertAudioForm>;
	onConfirm: (values: InsertAudioForm & { fileToUpload?: File }) => Promise<void>;
};

export type InsertAudioForm = {
	url?: string;
};

export default function InsertAudioModal(props: InsertAudioModalProps) {
	const { className, modalId, initialValues, onConfirm } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [fileToUpload, setFileToUpload] = useState<File | null>(null);
	const [previewUrl, setPreviewUrl] = useState<string>();

	const form = useForm<InsertAudioForm>({
		initialValues: {
			url: initialValues?.url ?? '',
		},
	});

	useEffect(() => {
		if (fileToUpload) {
			setPreviewUrl(URL.createObjectURL(fileToUpload));
		} else {
			setPreviewUrl(undefined);
		}
	}, [fileToUpload]);

	const handleSubmit = async (values: InsertAudioForm) => {
		const loader = addLoader();
		try {
			await onConfirm({
				...values,
				fileToUpload: fileToUpload ?? undefined,
			});
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error');
			removeLoader(loader);
		}
	};

	const handleUrlChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
		const url = e.target.value;
		form.setFieldValue('url', url);
		setPreviewUrl(url);
	};

	const handleCancelClicked = () => {
		modals.close(modalId);
	};

	const handleDeletePreviewClicked = () => {
		setFileToUpload(null);
		setPreviewUrl(undefined);
		form.setFieldValue('url', '');
	};

	return (
		<div className={`InsertAudioModal ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Group align="flex-end">
						<TextInput
							label="MP3 URL"
							{...form.getInputProps('url')}
							onChange={handleUrlChanged}
							withAsterisk={fileToUpload === null}
							disabled={fileToUpload !== null}
							style={{ flexGrow: 1 }}
						/>
						<FileButton onChange={setFileToUpload} accept="audio/mpeg">
							{(props) => (
								<Tooltip label="Or upload MP3">
									<Button {...props}>
										<IconFilePlus />
									</Button>
								</Tooltip>
							)}
						</FileButton>
					</Group>
					{previewUrl ? (
						<Stack>
							<Group align="center">
								<Text fz="sm">Preview</Text>
								<Tooltip label="Delete image">
									<ActionIcon onClick={handleDeletePreviewClicked} color="red" variant="subtle">
										<IconTrashX />
									</ActionIcon>
								</Tooltip>
							</Group>
							<Group align="center" justify="center">
								<audio controls>
									<source src={previewUrl} type="audio/mpeg" />
								</audio>
							</Group>
						</Stack>
					) : null}
					<Group justify="flex-end">
						<Button variant="subtle" disabled={isLoading} onClick={handleCancelClicked}>
							Cancel
						</Button>
						<Button type="submit" color="blue" disabled={isLoading}>
							Insert audio {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</div>
	);
}
