import React from 'react';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { ResourceTypeListFragment, ResourceTypeSingleFragment } from '../../graphql/graphql';
import { useResourceService } from '../../services/ResourceService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { AddResourceTypeForm } from './AddResourceTypeModal';
import z from 'zod';

export const validationSchema = z.object({
	name: z.string().nonempty().max(512),
});

export type EditResourceTypeModalProps = {
	modalId: string;
	current: ResourceTypeListFragment;
	onCancel: () => void;
	onEdited: (resource: ResourceTypeSingleFragment) => void;
};

export default function EditResourceTypeModal(props: EditResourceTypeModalProps) {
	const { modalId, current, onCancel, onEdited } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const resourceService = useResourceService();

	const form = useForm<EditResourceTypeForm>({
		initialValues: {
			name: current.name,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditResourceTypeForm) => {
		const loader = addLoader();

		try {
			const updatedResourceType = await resourceService.updateType(current.id, {
				name: { set: values.name },
			});
			onEdited(updatedResourceType);
			NotificationUtils.showSuccess('Successfully updated resource type', updatedResourceType.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update resource type');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<TextInput {...form.getInputProps('name')} label="Name" disabled={isLoading} withAsterisk />
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button type="submit" disabled={isLoading}>
						Save resource type {isLoading && <Loader size="xs" ml="xs" />}
					</Button>
				</Group>
			</form>
		</Container>
	);
}

export type EditResourceTypeForm = AddResourceTypeForm;
