import React from 'react';
import { Center, Title, Text, Stack } from '@mantine/core';

export type NotAuthenticatedProps = {
	className?: string;
};

export default function NotAuthenticated(props: NotAuthenticatedProps) {
	const { className } = props;

	return (
		<Center className={`NotAuthenticated ${className ?? ''}`} h="100%">
			<Stack>
				<Title order={1}>Not Authenticated</Title>
				<Text>Sorry, must be logged in to access to this area.</Text>
			</Stack>
		</Center>
	);
}
