import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import CouponsList from '../../components/coupons/CouponsList';

export default function CouponsPage() {
	return (
		<BackendPage title="Coupons">
			<CouponsList />
		</BackendPage>
	);
}
