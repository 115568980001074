import React from 'react';
import { Button, Container, Group, Loader, NumberInput, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import z from 'zod';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { EventCategoryListFragment } from '../../graphql/graphql';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useEventService } from '../../services/EventService';

export const validationSchema = z.object({
	minHours: z.null().or(z.coerce.number().positive().min(0).or(z.coerce.string().length(0))),
	minContributions: z.null().or(z.coerce.number().positive().min(0).or(z.coerce.string().length(0))),
});

export type SetEventRequirementsModalProps = {
	className?: string;
	modalId: string;
	eventCategory: EventCategoryListFragment;
	onUpdated: (updated: EventCategoryListFragment) => void;
	onCancel?: () => void;
};

export default function SetEventRequirementsModal(props: SetEventRequirementsModalProps) {
	const { className, modalId, eventCategory, onCancel, onUpdated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const eventService = useEventService();

	const form = useForm<SetEventRequirementsForm>({
		initialValues: {
			minHours: eventCategory.minHours ?? '',
			minContributions: eventCategory.minContributions ?? '',
			contributionName: eventCategory.contributionName ?? '',
		},
		validate: zodResolver(validationSchema),
		transformValues: (values) => ({
			minHours: values.minHours ? parseInt(`${values.minHours}`, 10) : null,
			minContributions: values.minContributions ? parseInt(`${values.minContributions}`, 10) : null,
			contributionName: values.contributionName,
		}),
	});

	const handleSubmit = async (values: SetEventRequirementsForm) => {
		const loader = addLoader();

		try {
			const updated = await eventService.updateRequirements(eventCategory.id, {
				minHours: values.minHours as number | null,
				minContributions: values.minContributions as number | null,
				contributionName: values.contributionName,
			});
			onUpdated(updated);
			NotificationUtils.showSuccess('Successfully set requirements', 'Event');
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to set event requirements.');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container className={`SetEventRequirementsModal ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<NumberInput
						{...form.getInputProps('minHours')}
						label="Minimum Hours"
						description="The minimum number of hours that must be attended for this event. Leave blank for no requirement."
						disabled={isLoading}
					/>
					<NumberInput
						{...form.getInputProps('minContributions')}
						label="Minimum number of contributions"
						description="The minimum number of 'contributions' that must ba made during this event. Leave blank for no requirement."
						disabled={isLoading}
					/>
					<TextInput
						{...form.getInputProps('contributionName')}
						label="Contribution Label"
						description={
							<>
								The user will be asked the question: <br />
								<strong>Did you contribute [contribution label]?</strong>
								<br />
								Leave blank if not setting contributions.
							</>
						}
						disabled={isLoading}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Save {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type SetEventRequirementsForm = {
	minHours: number | string | null;
	minContributions: number | string | null;
	contributionName: string | null;
};
