import React from 'react';
import { Button, Container, Group, Loader, Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { v4 as uuid } from 'uuid';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';

export type ConfirmModalProps = {
	modalId: string;
	onConfirm: () => Promise<void>;
	onCancel?: () => Promise<void>;
	children?: React.ReactNode;
	danger?: boolean;
	cancelText?: string;
	confirmText?: string;
};

export default function ConfirmModal(props: ConfirmModalProps) {
	const { modalId, onConfirm, onCancel, danger, children, cancelText, confirmText } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const handleConfirmClicked = async () => {
		const loader = addLoader();
		try {
			await onConfirm();
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error');
			removeLoader(loader);
		}
	};

	const handleCancelClicked = async () => {
		const loader = addLoader();
		try {
			await onCancel?.();
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error');
			removeLoader(loader);
		}
	};

	return (
		<Container>
			<Stack>
				{children}
				<Group justify="flex-end">
					<Button variant="subtle" disabled={isLoading} onClick={handleCancelClicked}>
						{cancelText ?? 'Cancel'}
					</Button>
					<Button color={danger ? 'red' : 'blue'} onClick={handleConfirmClicked} disabled={isLoading}>
						{confirmText ?? 'Confirm'} {isLoading && <Loader size="xs" ml="xs" />}
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}

export async function openConfirmModal(props: Partial<ConfirmModalProps> & { title?: string }): Promise<boolean> {
	return new Promise((resolve) => {
		const modalId = uuid();
		modals.open({
			modalId,
			title: props.title,
			onClose: () => resolve(false),
			children: (
				<ConfirmModal
					{...props}
					modalId={modalId}
					onConfirm={async () => {
						await props?.onConfirm?.();
						resolve(true);
					}}
					onCancel={async () => {
						await props?.onCancel?.();
					}}
				/>
			),
		});
	});
}
