import React from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Container, TextInput, Button, Loader, Group } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import useComputedSlug from '../../hooks/useComputedSlug';
import { CategoryListFragment, CategorySingleFragment } from '../../graphql/graphql';
import { useCategoryService } from '../../services/CategoryService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';

export const validationSchema = z.object({
	name: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
});

export type AddCategoryModalProps = CrudAddModalProps<CategoryListFragment, CategorySingleFragment> & {
	programmeId: string;
	parentId?: string;
};

export default function AddCategoryModal(props: AddCategoryModalProps) {
	const { modalId, onCancel, onCreated, programmeId, parentId } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const categoryService = useCategoryService();

	const form = useForm<AddCategoryForm>({
		initialValues: {
			name: '',
			slug: '',
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: AddCategoryForm) => {
		const loader = addLoader();

		try {
			const createdCategory = await categoryService.create({
				...values,
				programme: {
					connect: {
						id: programmeId,
					},
				},
				...(parentId
					? {
							parentCategory: {
								connect: {
									id: parentId,
								},
							},
					  }
					: {}),
			});
			onCreated(createdCategory);
			NotificationUtils.showSuccess('Successfully created category', createdCategory.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create category');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'name');

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<TextInput
					{...slugComputedFromProps}
					label="Name"
					description="The name of the category. Forms the basis of the Slug"
					disabled={isLoading}
					withAsterisk
				/>
				<TextInput
					{...form.getInputProps('slug')}
					mt="md"
					label="Slug"
					description="This will appear in the browser URL."
					disabled={isLoading}
					withAsterisk
				/>
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button type="submit" disabled={isLoading}>
						Add category {isLoading && <Loader size="xs" ml="xs" />}
					</Button>
				</Group>
			</form>
		</Container>
	);
}

export type AddCategoryForm = Pick<CategorySingleFragment, 'name' | 'slug'>;
