import React from 'react';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import BackendPage from '../../components/page/BackendPage';

export default function ProfilePage() {
	return (
		<BackendPage title="Profile">
			Coming soon...
			<Link to="/logout">
				<Button>Logout</Button>
			</Link>
		</BackendPage>
	);
}
