import React, { useState } from 'react';
import { CategoryListFragment, CategoryListWithContentAndPrerequisitesFragment } from '../../graphql/graphql';
import { Button, Checkbox, Container, Group, Loader, Stack, Table, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useCategoryService } from '../../services/CategoryService';

export type CategoryPrerequisitesModalProps = {
	className?: string;
	modalId: string;
	current: CategoryListWithContentAndPrerequisitesFragment;
	categories: CategoryListFragment[];
	onEdited: (category: CategoryListWithContentAndPrerequisitesFragment) => void;
	onCancel: () => void;
};

export default function CategoryPrerequisitesModal(props: CategoryPrerequisitesModalProps) {
	const { className, modalId, current, categories, onEdited, onCancel } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const categoryService = useCategoryService();

	const [selectedPrerequisites, setSelectedPrerequisites] = useState<CategoryListFragment[]>(
		categories.filter((c) => current.prerequisites.map((p) => p.prerequisiteId).indexOf(c.id) >= 0),
	);

	const handlePrerequisiteSelected = (
		event: React.ChangeEvent<HTMLInputElement>,
		prerequisite: CategoryListFragment,
	) => {
		const index = selectedPrerequisites.findIndex((p) => p.id === prerequisite.id);

		let updatedPrerequisites: CategoryListFragment[] = [];

		if (event.currentTarget.checked && index === -1) {
			updatedPrerequisites = [...selectedPrerequisites, prerequisite];
		} else if (!event.currentTarget.checked && index >= 0) {
			updatedPrerequisites = [
				...selectedPrerequisites.slice(0, index),
				...selectedPrerequisites.slice(index + 1),
			];
		}

		setSelectedPrerequisites(updatedPrerequisites);
	};

	const handleSubmit = async () => {
		const loader = addLoader();

		try {
			const prerequisitesToUpdate = selectedPrerequisites.map((p) => ({
				categoryId: current.id,
				prerequisiteId: p.id,
			}));
			// Delete the existing prerequisites
			await categoryService.update(current.id, {
				prerequisites: {
					deleteMany: [
						{
							categoryId: { equals: current.id },
						},
					],
				},
			});
			// Add the new prerequisites
			if (prerequisitesToUpdate.length > 0) {
				await categoryService.update(current.id, {
					prerequisites: {
						createMany: {
							data: prerequisitesToUpdate.map((p) => ({ prerequisiteId: p.prerequisiteId })),
						},
					},
				});
			}
			onEdited({
				...current,
				prerequisites: prerequisitesToUpdate,
			});
			NotificationUtils.showSuccess('Successfully set prerequisites', current.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update category');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<div className={`CategoryPrerequisitesModal ${className ?? ''}`}>
			<Container>
				<Stack>
					<Text>
						Which categories must be completed before <strong>{current.name}</strong> can be started?
					</Text>
					<Table>
						<tbody>
							{categories.map((c) => (
								<tr key={c.id}>
									<td width="100%">{c.id === current.id ? <strong>{c.name}</strong> : c.name}</td>
									<td align="right">
										<Checkbox
											checked={selectedPrerequisites.findIndex((p) => p.id === c.id) >= 0}
											onChange={(e) => handlePrerequisiteSelected(e, c)}
											disabled={c.id === current.id}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading} onClick={handleSubmit}>
							Save prerequisites {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</Container>
		</div>
	);
}
