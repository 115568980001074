import useDebouncedImmediateState from '@dr-pam/common-components/Hooks/useDebouncedImmediateState';
import { ActionIcon, Input } from '@mantine/core';
import { IconCircleXFilled } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

export type SearchInputProps = {
	placeholder?: string;
	debounceMs?: number;
	initialValue?: string;
	onSearchRequested: (query: string) => void;
	onSearchCleared?: () => void;
};

export default function SearchInput(props: SearchInputProps) {
	const { placeholder, debounceMs, initialValue, onSearchRequested, onSearchCleared } = props;

	const [hasInteracted, setHasInteracted] = useState(false);
	const [searchQuery, setSearchQuery] = useState(initialValue ?? '');
	const [debouncedSearchQuery, setDebouncedSearchQuery, setDebouncedSearchQueryImmediately] =
		useDebouncedImmediateState(initialValue, debounceMs ?? 500);

	useEffect(() => {
		if (!hasInteracted) {
			return;
		}
		if (debouncedSearchQuery) {
			if (debouncedSearchQuery !== initialValue) {
				onSearchRequested?.(debouncedSearchQuery);
			}
		} else {
			onSearchCleared?.();
		}
	}, [debouncedSearchQuery, initialValue, onSearchCleared, onSearchRequested, hasInteracted]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = event.target.value;
		setSearchQuery(val);
		if (val) {
			setDebouncedSearchQuery(val);
			setHasInteracted(true);
		} else {
			setDebouncedSearchQueryImmediately('');
		}
	};

	const handleClearSearchClicked = () => {
		setHasInteracted(true);
		setSearchQuery('');
		setDebouncedSearchQueryImmediately('');
	};

	return (
		<Input
			value={searchQuery}
			onChange={handleSearchChange}
			placeholder={placeholder ?? 'Search'}
			w="100%"
			rightSectionPointerEvents="all"
			rightSection={
				searchQuery ? (
					<ActionIcon onClick={handleClearSearchClicked} variant="subtle" color="gray">
						<IconCircleXFilled />
					</ActionIcon>
				) : null
			}
		/>
	);
}
