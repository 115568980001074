import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import NotFound from '../../components/page/NotFound';
import { useParams } from 'react-router-dom';
import EventOccurrencesList from '../../components/events/EventOccurrencesList';

export default function EventRegistrationsPage() {
	const { eventId, eventOccurrenceId } = useParams<{ eventId: string; eventOccurrenceId: string }>();

	return (
		<BackendPage>
			{eventId && eventOccurrenceId ? (
				<EventOccurrencesList eventId={eventId} eventOccurrenceId={eventOccurrenceId} />
			) : (
				<NotFound />
			)}
		</BackendPage>
	);
}
