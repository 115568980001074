import NotFound from '../page/NotFound';
import useDebouncedFunction from '@dr-pam/common-components/Hooks/useDebouncedFunction';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import { ArticleWithContent, useArticleService } from '../../services/ArticleService';
import { Breadcrumbs, Container, Flex, Group, Loader, LoadingOverlay, Stack, Title, Tooltip } from '@mantine/core';
import { IconAlertCircle, IconAlertTriangle, IconCheck } from '@tabler/icons-react';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useContentService } from '../../services/ContentService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import MarkdownEditor from '../markdown/MarkdownEditor';
import styled from '@emotion/styled';

const AUTOSAVE_INTERVAL_SEC = 2;

export type EditArticleProps = {
	articleId: string;
};

export default function EditArticle(props: EditArticleProps) {
	const { articleId } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const [requiresSave, setRequiresSave] = useState(false);
	const [saveError, setSaveError] = useState<Error | null>(null);

	const [article, setArticle] = useState<ArticleWithContent | undefined | null>();

	const articleService = useArticleService();
	const contentService = useContentService();

	const isLoadingArticle = useLoadingEffect(async () => {
		try {
			const article = await articleService.getWithContent(articleId).response;
			setArticle(article);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load article.');
		}
	}, [articleService]);

	const saveArticleContent = useCallback(
		async (article: ArticleWithContent, value: string) => {
			const loader = addLoader();
			try {
				if (article.content?.id) {
					article.content = await contentService.update(article.content.id, {
						markdown: { set: value },
					});
				} else {
					article.content = await contentService.create({
						markdown: value,
						article: {
							connect: {
								id: article.id,
							},
						},
					});
				}
				removeLoader(loader);
				setRequiresSave(false);
			} catch (err) {
				NotificationUtils.showError(err as Error, 'Failed to save article content.');
				removeLoader(loader);
				setSaveError(err as Error);
			}
		},
		[addLoader, removeLoader, contentService],
	);

	const saveArticleContentDebounced = useDebouncedFunction(saveArticleContent, AUTOSAVE_INTERVAL_SEC * 1000);

	const handleOnMarkdownChanged = (value: string) => {
		if (!article) {
			return;
		}
		setRequiresSave(true);
		setSaveError(null);
		saveArticleContentDebounced?.(article, value);
	};

	const handleSaveClicked = () => {
		saveArticleContentDebounced?.flush();
	};

	let saveMessage = (
		<Group justify="flex-end" grow>
			Saved <IconCheck color="green" />
		</Group>
	);
	if (saveError) {
		saveMessage = (
			<Tooltip label={saveError.message}>
				<Group justify="flex-end" grow>
					Failed to save <IconAlertCircle color="red" />
				</Group>
			</Tooltip>
		);
	} else if (isLoading) {
		saveMessage = (
			<Group justify="flex-end" grow>
				Saving changes...
				<Loader size={'sm'} />
			</Group>
		);
	} else if (requiresSave) {
		saveMessage = (
			<Tooltip
				label={`Will automatically save within ${AUTOSAVE_INTERVAL_SEC} seconds. Alternatively, click the icon to save immediately.`}
			>
				<Group onClick={handleSaveClicked} justify="flex-end" grow>
					Unsaved changes <IconAlertTriangle color="orange" />
				</Group>
			</Tooltip>
		);
	}

	if (article === undefined || isLoadingArticle) {
		return <LoadingOverlay visible={isLoadingArticle} overlayProps={{ blur: 2 }} />;
	}

	if (article === null) {
		return <NotFound />;
	}

	return (
		<StyledContainer>
			<Breadcrumbs>
				<Link to="/articles">Articles</Link>
				<Link to="#">{article?.title}</Link>
			</Breadcrumbs>
			<Title order={1}>{article?.title}</Title>
			<StyledContent maw="100%">
				<MarkdownEditor
					uploadFileNamePrefix={`articles/${article?.id}`}
					initialValue={article?.content?.markdown}
					onChange={handleOnMarkdownChanged}
					toolbarChildren={<StyledToolbarChildren justify="flex-end">{saveMessage}</StyledToolbarChildren>}
					showDocxImport
				/>
			</StyledContent>
		</StyledContainer>
	);
}

const StyledContainer = styled(Stack)({
	display: 'grid',
	gridTemplateRows: 'auto auto 1fr',
	gridTemplateColumns: '1fr',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
});
const StyledContent = styled(Container)({
	background: 'var(--mantine-color-white)',
	display: 'grid',
	gridTemplateRows: '1fr',
	gridTemplateColumns: '1fr',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
	padding: 0,
	margin: 0,
});

const StyledToolbarChildren = styled(Flex)({
	flexGrow: 1,
}) as unknown as typeof Flex;
