import React, { useCallback } from 'react';
import { useArticleService } from '../../services/ArticleService';
import { ArticleUsageFragment } from '../../graphql/graphql';
import { ActionIcon, Button, Container, Group, Stack, Text } from '@mantine/core';
import CrudTable, { CrudTableColumn, useCrudState } from '../crud/CrudTable';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { IconEye, IconUnlink } from '@tabler/icons-react';
import { closeModal } from '@mantine/modals';
import { openConfirmModal } from '../modals/ConfirmModal';
import styled from '@emotion/styled';

export type ArticleUsageModalProps = {
	className?: string;
	modalId: string;
	articleId: string;
	onNavigate: (programmeId: string) => void;
	onUnlink: (articleId: string, categoryId: string) => Promise<void>;
};

export default function ArticleUsageModal(props: ArticleUsageModalProps) {
	const { className, modalId, articleId, onNavigate, onUnlink } = props;

	const articleService = useArticleService();

	const fetchArticleUsage = useCallback((): AbortableRequest<ArticleUsageFragment[]> | null => {
		return articleService.getAllUsages(articleId);
	}, [articleService, articleId]);

	const crudState = useCrudState<ArticleUsageFragment, ArticleUsageFragment>(fetchArticleUsage);

	const handleCancelClicked = () => {
		closeModal(modalId);
	};

	const handleNavigateClicked = (programmeId: string) => {
		setTimeout(() => onNavigate(programmeId), 0);
		closeModal(modalId);
	};

	const handleUnlinkClicked = async (usage: ArticleUsageFragment) => {
		await openConfirmModal({
			title: 'Unlink article',
			danger: true,
			children: (
				<Text>
					Are you sure you want to unlink this article from the category &quot;{usage.category.name}&quot;?
				</Text>
			),
			onConfirm: async () => {
				await onUnlink(articleId, usage.category.id);
				crudState.delete(usage);
			},
		});
	};

	const crudColumns = (usage: ArticleUsageFragment): CrudTableColumn[] => [
		{
			children: usage.category.name,
		},
		{
			children: usage.category.programme.name,
		},
		{
			children: (
				<Group justify="flex-end">
					<ActionIcon
						color="blue"
						title="Open programme"
						variant="subtle"
						onClick={() => handleNavigateClicked(usage.category.programme.id)}
					>
						<IconEye />
					</ActionIcon>
					<ActionIcon
						color="red"
						title="Unlink article"
						variant="subtle"
						onClick={() => handleUnlinkClicked(usage)}
					>
						<IconUnlink />
					</ActionIcon>
				</Group>
			),
		},
	];

	return (
		<StyledContainer className={`ArticleUsageModal ${className ?? ''}`}>
			<Stack>
				<CrudTable
					items={crudState.items}
					isLoading={crudState.isFetching}
					headers={crudHeaders}
					columns={crudColumns}
					padding={0}
					entityName="usages"
				/>
				<Group justify="flex-end">
					<Button disabled={crudState.isFetching} onClick={handleCancelClicked}>
						Close
					</Button>
				</Group>
			</Stack>
		</StyledContainer>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Category',
	},
	{
		children: 'Programme',
	},
	{
		children: '',
	},
];

const StyledContainer = styled(Container)({
	minHeight: '64px',
});
