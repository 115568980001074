import React from 'react';
import { modals } from '@mantine/modals';
import { isNotEmpty, useForm } from '@mantine/form';
import { Stack, Button, Loader, TextInput, Checkbox, ActionIcon, Text, Flex, Group } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { v4 as uuid } from 'uuid';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { MultiChoiceQuestionOption } from '@dr-pam/markdown';
import styled from '@emotion/styled';

export type InsertMultiChoiceQuestionModalProps = {
	modalId: string;
	className?: string;
	initialValues?: Partial<InsertMultiChoiceQuestionForm>;
	onConfirm: (values: InsertMultiChoiceQuestionForm) => Promise<void>;
};

export type InsertMultiChoiceQuestionForm = {
	question: string;
	options: Partial<MultiChoiceQuestionOption & { key: string }>[];
};

export default function InsertMultiChoiceQuestionModal(props: InsertMultiChoiceQuestionModalProps) {
	const { className, modalId, initialValues, onConfirm } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const form = useForm<InsertMultiChoiceQuestionForm>({
		initialValues: {
			question: initialValues?.question ?? '',
			options: [],
		},
		validate: {
			question: isNotEmpty('Required'),
			options: (options) => {
				if (!options.length) {
					return 'At least one option is required';
				}
				if (!options.some((option) => option.isAnswer)) {
					return 'At least one option must be marked as the answer';
				}
			},
		},
	});

	const handleSubmit = async (values: InsertMultiChoiceQuestionForm) => {
		const loader = addLoader();
		try {
			await onConfirm({
				...values,
			});
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error');
			removeLoader(loader);
		}
	};

	const handleCancelClicked = () => {
		modals.close(modalId);
	};

	const handleAddOptionClicked = () => {
		form.insertListItem('options', {
			text: '',
			isAnswer: false,
			explanation: '',
			key: uuid(),
		});
	};

	return (
		<div className={`InsertMultiChoiceQuestionModal ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput label="Question" {...form.getInputProps('question')} withAsterisk />
					{form.values.options.length ? (
						<StyledTable>
							<thead>
								<tr>
									<th>Option</th>
									<th>Explanation</th>
									<th className="small">Answer</th>
									<th className="small"></th>
								</tr>
							</thead>
							<tbody>
								{form.values.options.map((option, index) => (
									<tr key={option.key}>
										<td>
											<TextInput {...form.getInputProps(`options.${index}.text`)} />
										</td>
										<td>
											<TextInput {...form.getInputProps(`options.${index}.explanation`)} />
										</td>
										<td>
											<Checkbox
												{...form.getInputProps(`options.${index}.isAnswer`, {
													type: 'checkbox',
												})}
												size="xl"
												color="green"
											/>
										</td>
										<td>
											<ActionIcon
												color="red"
												onClick={() => form.removeListItem('options', index)}
											>
												<IconTrash size="lg" />
											</ActionIcon>
										</td>
									</tr>
								))}
							</tbody>
						</StyledTable>
					) : null}
					<Flex direction="column" gap="xxs">
						<Button variant="outline" onClick={handleAddOptionClicked}>
							Add option
						</Button>
						{form.errors.options ? (
							<Text c="red" size="xs">
								{form.errors.options}
							</Text>
						) : null}
					</Flex>
					<Group justify="flex-end">
						<Button variant="subtle" disabled={isLoading} onClick={handleCancelClicked}>
							Cancel
						</Button>
						<Button type="submit" color="blue" disabled={isLoading}>
							Insert question {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</div>
	);
}

const StyledTable = styled.table({
	border: '1px dashed var(--mantine-color-gray-6)',
	borderRadius: 'var(--mantine-radius-sm)',
	padding: 'var(--mantine-spacing-xs)',
	marginTop: 'var(--mantine-spacing-sm)',
	th: {
		textAlign: 'left',
		color: 'var(--mantine-color-gray-9)',
		fontSize: 'var(--mantine-font-size-sm)',
		fontWeight: 500,
		width: '45%',

		'&.small': {
			textAlign: 'right',
			width: '5%',
			maxWidth: '64px',
		},
	},
	'.mantine-Checkbox-body': {
		justifyContent: 'flex-end',
	},
	'.mantine-ActionIcon-root': {
		marginLeft: 'auto',
	},
});
