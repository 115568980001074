import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import hash from 'object-hash';
import {
	AddArticleToCategoryMutation,
	AddArticleToCategoryMutationVariables,
	ArticleCategoryListFragment,
	ArticleContentFragment,
	ArticleCreateInput,
	ArticleListFragment,
	ArticleQuery,
	ArticleQueryVariables,
	ArticleSingleFragment,
	ArticleUpdateInput,
	ArticleUsageFragment,
	ArticleUsagesQuery,
	ArticleUsagesQueryVariables,
	ArticleWithContentQuery,
	ArticleWithContentQueryVariables,
	ArticlesByCategoryIdQuery,
	ArticlesByCategoryIdQueryVariables,
	ArticlesQuery,
	CreateArticleMutation,
	CreateArticleMutationVariables,
	DeleteArticlesMutation,
	DeleteArticlesMutationVariables,
	RemoveArticleFromCategoryMutation,
	RemoveArticleFromCategoryMutationVariables,
	SearchArticlesQuery,
	SearchArticlesQueryVariables,
	UpdateArticleCategoryIsPinnedMutation,
	UpdateArticleCategoryIsPinnedMutationVariables,
	UpdateArticleCategoryIsRequiredMutation,
	UpdateArticleCategoryIsRequiredMutationVariables,
	UpdateArticleMutation,
	UpdateArticleMutationVariables,
} from '../graphql/graphql';
import { SortableTreeItem } from '../models/SortableTreeItem';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import IPublishable from '@dr-pam/common-components/Services/IPublishable';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export type ArticleWithContent = ArticleSingleFragment & ArticleContentFragment;

export class ArticleSortableTreeItem<T extends ArticleCategoryListFragment> extends SortableTreeItem<T> {
	public getId(): string {
		return this.item.id;
	}
	public getSortOrder(): number {
		return this.item.sortOrder;
	}
	public setSortOrder(sortOrder: number): void {
		this.item.sortOrder = sortOrder;
	}

	public getName(): string {
		if (this.parent) {
			return `${this.parent.getName()} > ${this.item.article.title}`;
		} else {
			return this.item.article.title;
		}
	}

	public getHash(): string {
		return hash([this.getId(), this.parent?.getId(), this.getSortOrder()]);
	}
}

export const GQL_FRAG_ARTICLE_LIST = gql`
	fragment ArticleList on Article {
		id
		title
		slug
		isPublished
		resourceTypeId
		content {
			id
			markdownHash
			publishedMarkdownHash
		}
	}
`;

export const GQL_FRAG_ARTICLE_SINGLE = gql`
	fragment ArticleSingle on Article {
		id
		created
		modified
		title
		slug
		isPublished
		resourceTypeId
	}
`;

export const GQL_FRAG_ARTICLE_CONTENT = gql`
	fragment ArticleContent on Article {
		content {
			id
			markdown
			markdownHash
			publishedMarkdownHash
		}
	}
`;

export const GQL_FRAG_ARTICLE_CATEGORY_SINGLE = gql`
	${GQL_FRAG_ARTICLE_SINGLE}
	fragment ArticleCategorySingle on ArticleCategory {
		id
		isPinned
		sortOrder
		isRequired
		article {
			...ArticleSingle
		}
	}
`;

export const GQL_FRAG_ARTICLE_CATEGORY_LIST = gql`
	${GQL_FRAG_ARTICLE_LIST}
	fragment ArticleCategoryList on ArticleCategory {
		id
		isPinned
		sortOrder
		isRequired
		article {
			...ArticleList
		}
	}
`;

export const GQL_GET_ARTICLE = gql`
	${GQL_FRAG_ARTICLE_SINGLE}
	query Article($articleId: String!) {
		article(where: { id: $articleId }) {
			...ArticleSingle
		}
	}
`;

export const GQL_GET_ARTICLES_FOR_LIST = gql`
	${GQL_FRAG_ARTICLE_LIST}
	query Articles {
		articles(orderBy: { created: desc }) {
			...ArticleList
		}
	}
`;

export const GQL_SEARCH_ARTICLES_FOR_LIST = gql`
	${GQL_FRAG_ARTICLE_LIST}
	query SearchArticles($title: String!) {
		articles(orderBy: { title: asc }, where: { title: { contains: $title } }) {
			...ArticleList
		}
	}
`;

export const GQL_GET_ARTICLES_FOR_LIST_BY_CATEGORY = gql`
	${GQL_FRAG_ARTICLE_LIST}
	query ArticlesByCategoryId($categoryIds: [String!]) {
		articleCategories(where: { categoryId: { in: $categoryIds } }) {
			article {
				...ArticleList
			}
		}
	}
`;

export const GQL_GET_ARTICLE_WITH_CONTENT = gql`
	${GQL_FRAG_ARTICLE_SINGLE}
	${GQL_FRAG_ARTICLE_CONTENT}
	query ArticleWithContent($articleId: String!) {
		article(where: { id: $articleId }) {
			...ArticleSingle
			...ArticleContent
		}
	}
`;

export const GQL_GET_ORPHAN_ARTICLES = gql`
	${GQL_FRAG_ARTICLE_LIST}
	query OrphanArticles {
		articles(where: { articleCategories: { none: { id: { startsWith: "" } } } }) {
			...ArticleList
		}
	}
`;

export const GQL_GET_ARTICLE_USAGES = gql`
	fragment ArticleUsage on ArticleCategory {
		id
		category {
			id
			name
			programme {
				id
				name
			}
		}
	}

	query ArticleUsages($articleId: String!) {
		articleCategories(where: { articleId: { equals: $articleId } }) {
			...ArticleUsage
		}
	}
`;

export const GQL_DELETE_ARTICLES = gql`
	mutation DeleteArticles($articleIds: [String!]) {
		deleteManyArticle(where: { id: { in: $articleIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_ARTICLE = gql`
	${GQL_FRAG_ARTICLE_SINGLE}
	mutation CreateArticle($article: ArticleCreateInput!) {
		createOneArticle(data: $article) {
			...ArticleSingle
		}
	}
`;

export const GQL_UPDATE_ARTICLE = gql`
	${GQL_FRAG_ARTICLE_SINGLE}
	mutation UpdateArticle($articleId: String!, $article: ArticleUpdateInput!) {
		updateOneArticle(where: { id: $articleId }, data: $article) {
			...ArticleSingle
		}
	}
`;

export const GQL_ADD_ARTICLE_TO_CATEGORY = gql`
	${GQL_FRAG_ARTICLE_CATEGORY_LIST}
	mutation AddArticleToCategory($articleId: String!, $categoryId: String!, $sortOrder: Int) {
		upsertOneArticleCategory(
			where: { articleCategory: { articleId: $articleId, categoryId: $categoryId } }
			create: {
				article: { connect: { id: $articleId } }
				category: { connect: { id: $categoryId } }
				sortOrder: $sortOrder
			}
			update: {
				article: { connect: { id: $articleId } }
				category: { connect: { id: $categoryId } }
				sortOrder: { set: $sortOrder }
			}
		) {
			...ArticleCategoryList
		}
	}
`;

export const GQL_REMOVE_ARTICLE_FROM_CATEGORY = gql`
	mutation RemoveArticleFromCategory($articleId: String!, $categoryId: String!) {
		deleteOneArticleCategory(where: { articleCategory: { articleId: $articleId, categoryId: $categoryId } }) {
			id
		}
	}
`;

export const GQL_UPDATE_ARTICLE_CATEGORY_IS_PINNED = gql`
	mutation UpdateArticleCategoryIsPinned($articleCategoryId: String!, $isPinned: Boolean!) {
		updateOneArticleCategory(where: { id: $articleCategoryId }, data: { isPinned: { set: $isPinned } }) {
			id
		}
	}
`;

export const GQL_UPDATE_ARTICLE_CATEGORY_IS_REQUIRED = gql`
	mutation UpdateArticleCategoryIsRequired($articleCategoryId: String!, $isRequired: Boolean!) {
		updateOneArticleCategory(where: { id: $articleCategoryId }, data: { isRequired: { set: $isRequired } }) {
			id
		}
	}
`;

export default class ArticleService
	implements
		ICrudService<ArticleListFragment, ArticleSingleFragment, ArticleCreateInput, ArticleUpdateInput>,
		IPublishable<ArticleListFragment>
{
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public async publish(articleId: string): Promise<ArticleListFragment> {
		const request = FetchUtils.postJson<ArticleListFragment>(`/api/article/${articleId}/publish`, {});

		const response = await request.response;

		return response;
	}

	public async unpublish(articleId: string): Promise<ArticleListFragment> {
		const request = FetchUtils.postJson<ArticleListFragment>(`/api/article/${articleId}/unpublish`, {});

		const response = await request.response;

		return response;
	}

	public get(articleId: string) {
		const request = ApolloUtils.abortableQuery<ArticleQuery, ArticleSingleFragment | null, ArticleQueryVariables>(
			this._apolloClient,
			{
				query: GQL_GET_ARTICLE,
				variables: {
					articleId,
				},
			},
			(data) => data.article ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getAll() {
		const request = ApolloUtils.abortableQuery<ArticlesQuery, ArticleListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_ARTICLES_FOR_LIST,
			},
			(data) => data.articles,
			this._registerAbort,
		);

		return request;
	}

	public getOrphans() {
		const request = ApolloUtils.abortableQuery<ArticlesQuery, ArticleListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_ORPHAN_ARTICLES,
			},
			(data) => data.articles,
			this._registerAbort,
		);

		return request;
	}

	public searchByTitle(title: string) {
		const request = ApolloUtils.abortableQuery<
			SearchArticlesQuery,
			ArticleListFragment[],
			SearchArticlesQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_SEARCH_ARTICLES_FOR_LIST,
				variables: {
					title,
				},
			},
			(data) => data.articles,
			this._registerAbort,
		);

		return request;
	}

	public getWithContent(articleId: string) {
		const request = ApolloUtils.abortableQuery<
			ArticleWithContentQuery,
			ArticleWithContent | null,
			ArticleWithContentQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_ARTICLE_WITH_CONTENT,
				variables: {
					articleId,
				},
			},
			(data) => data.article ?? null,
			this._registerAbort,
		);

		return request;
	}

	public async create(article: ArticleCreateInput) {
		const result = await this._apolloClient.mutate<CreateArticleMutation, CreateArticleMutationVariables>({
			mutation: GQL_CREATE_ARTICLE,
			variables: {
				article,
			},
		});
		if (!result.data?.createOneArticle) {
			throw new Error('Failed to create article');
		}
		return result.data.createOneArticle;
	}

	public async update(articleId: string, article: ArticleUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateArticleMutation, UpdateArticleMutationVariables>({
			mutation: GQL_UPDATE_ARTICLE,
			variables: {
				articleId,
				article,
			},
		});
		if (!result.data?.updateOneArticle) {
			throw new Error('Failed to update article');
		}
		return result.data.updateOneArticle;
	}

	public async delete(articleIds: string | string[]) {
		articleIds = Array.isArray(articleIds) ? articleIds : [articleIds];

		await this._apolloClient.mutate<DeleteArticlesMutation, DeleteArticlesMutationVariables>({
			mutation: GQL_DELETE_ARTICLES,
			variables: {
				articleIds,
			},
		});
	}

	public getAllByCategoryId(categoryIds: string | string[]) {
		if (typeof categoryIds === 'string') {
			categoryIds = [];
		}

		const request = ApolloUtils.abortableQuery<
			ArticlesByCategoryIdQuery,
			ArticleListFragment[],
			ArticlesByCategoryIdQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_ARTICLES_FOR_LIST_BY_CATEGORY,
				variables: {
					categoryIds,
				},
			},
			(data) => data.articleCategories.map((ac) => ac.article),
			this._registerAbort,
		);

		return request;
	}

	public getAllUsages(articleId: string) {
		const request = ApolloUtils.abortableQuery<
			ArticleUsagesQuery,
			ArticleUsageFragment[],
			ArticleUsagesQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_ARTICLE_USAGES,
				variables: {
					articleId,
				},
			},
			(data) => data.articleCategories,
			this._registerAbort,
		);

		return request;
	}

	public async addArticleToCategory(
		articleId: string,
		categoryId: string,
		sortOrder?: number,
	): Promise<ArticleCategoryListFragment> {
		const result = await this._apolloClient.mutate<
			AddArticleToCategoryMutation,
			AddArticleToCategoryMutationVariables
		>({
			mutation: GQL_ADD_ARTICLE_TO_CATEGORY,
			variables: {
				articleId,
				categoryId,
				sortOrder: sortOrder ?? 0,
			},
		});
		if (!result.data?.upsertOneArticleCategory) {
			throw new Error('Failed to add article to category');
		}
		return result.data.upsertOneArticleCategory;
	}

	public async removeArticleFromCategory(articleId: string, categoryId: string): Promise<void> {
		const result = await this._apolloClient.mutate<
			RemoveArticleFromCategoryMutation,
			RemoveArticleFromCategoryMutationVariables
		>({
			mutation: GQL_REMOVE_ARTICLE_FROM_CATEGORY,
			variables: {
				articleId,
				categoryId,
			},
		});
		if (!result.data?.deleteOneArticleCategory) {
			throw new Error('Failed to remove article from category');
		}
	}

	public async updateIsPinned(articleCategoryId: string, isPinned: boolean) {
		const result = await this._apolloClient.mutate<
			UpdateArticleCategoryIsPinnedMutation,
			UpdateArticleCategoryIsPinnedMutationVariables
		>({
			mutation: GQL_UPDATE_ARTICLE_CATEGORY_IS_PINNED,
			variables: {
				articleCategoryId,
				isPinned,
			},
		});
		if (!result.data?.updateOneArticleCategory) {
			throw new Error('Failed to update article category');
		}
		return result.data.updateOneArticleCategory;
	}

	public async updateIsRequired(articleCategoryId: string, isRequired: boolean) {
		const result = await this._apolloClient.mutate<
			UpdateArticleCategoryIsRequiredMutation,
			UpdateArticleCategoryIsRequiredMutationVariables
		>({
			mutation: GQL_UPDATE_ARTICLE_CATEGORY_IS_REQUIRED,
			variables: {
				articleCategoryId,
				isRequired,
			},
		});
		if (!result.data?.updateOneArticleCategory) {
			throw new Error('Failed to update article category');
		}
		return result.data.updateOneArticleCategory;
	}

	public duplicateArticle(articleId: string) {
		return FetchUtils.postJson<ArticleListFragment>(`/api/article/${articleId}/duplicate`, {});
	}
}

export function useArticleService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new ArticleService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
