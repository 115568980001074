import React, { useEffect, useState } from 'react';
import { Loader, Stack, TextInput, Table, Text, Checkbox } from '@mantine/core';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { useDebouncedValue } from '@mantine/hooks';
import { EventListFragment } from '../../graphql/graphql';
import { useEventService } from '../../services/EventService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type SearchEventProps = {
	onSelected: (event: EventListFragment | undefined) => void;
};

export default function SearchEvent(props: SearchEventProps) {
	const { onSelected } = props;

	const eventService = useEventService();

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [events, setEvents] = useState<EventListFragment[]>([]);
	const [selectedEvent, setSelectedEvent] = useState<EventListFragment | undefined>(undefined);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);

	useEffect(() => {
		if (debouncedSearchQuery) {
			const loader = addLoader();
			const request = eventService.searchByTitle(debouncedSearchQuery);
			setSelectedEvent(undefined);
			request.response
				.then((events) => {
					setEvents(events);
				})
				.catch((err) => {
					NotificationUtils.showError(err as Error, 'Failed to search events');
				})
				.finally(() => {
					removeLoader(loader);
				});
		}
	}, [addLoader, eventService, debouncedSearchQuery, removeLoader]);

	const handleEventSelected = (e: React.ChangeEvent<HTMLInputElement>, event: EventListFragment) => {
		setSelectedEvent(e.currentTarget.checked ? event : undefined);
		onSelected(e.currentTarget.checked ? event : undefined);
	};

	return (
		<Stack>
			<TextInput
				value={searchQuery}
				onChange={(event) => setSearchQuery(event.currentTarget.value)}
				label="Search events by title"
				disabled={isLoading}
			/>
			{isLoading && (
				<Text ta="center">
					<Loader size="xs" ml="xs" />
				</Text>
			)}
			{!isLoading &&
				debouncedSearchQuery &&
				(events.length ? (
					<Table>
						<tbody>
							{events.map((a) => (
								<tr key={a.id}>
									<td width="100%">{a.title}</td>
									<td align="right">
										<Checkbox
											checked={selectedEvent?.id === a.id}
											onChange={(e) => handleEventSelected(e, a)}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				) : (
					<Text ta="center">No search results</Text>
				))}
		</Stack>
	);
}
