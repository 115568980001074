import React, { useMemo, useRef, useState } from 'react';
import Content from '../Content';
import { Group, Loader, LoadingOverlay, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import AddCategoryModal from '../category/AddCategoryModal';
import SortableTree, { ChildItem } from '../category/SortableTree';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import NotFound from '../page/NotFound';
import { useArticleService } from '../../services/ArticleService';
import AddArticleModal from '../articles/AddArticleModal';
import AddQuizModal from '../quizzes/AddQuizModal';
import AddResourceModal from '../resources/AddResourceModal';
import SelectArticleModal from '../articles/SelectArticleModal';
import SelectResourceModal from '../resources/SelectResourceModal';
import SelectQuizModal from '../quizzes/SelectQuizModal';
import EditResourceModal from '../resources/EditResourceModal';
import EditArticleModal from '../articles/EditArticleModal';
import EditQuizModal from '../quizzes/EditQuizModal';
import EditCategoryModal from '../category/EditCategoryModal';
import {
	CategoryListWithContentAndPrerequisitesFragment,
	ArticleListFragment,
	QuizListFragment,
	ResourceListFragment,
	ArticleCategoryListFragment,
	QuizCategoryListFragment,
	ResourceCategoryListFragment,
	EventCategoryListFragment,
	EventListFragment,
	CategoryListFragment,
} from '../../graphql/graphql';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { SortableTreeItem } from '../../models/SortableTreeItem';
import CategoryService, {
	useCategoryService,
	CategorySortableTreeItem,
	isCategoryListWithContentFragment,
} from '../../services/CategoryService';
import { useProgrammeService, ProgrammeWithContent } from '../../services/ProgrammeService';
import { useQuizService } from '../../services/QuizService';
import { useResourceService } from '../../services/ResourceService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import ConfirmModal from '../modals/ConfirmModal';
import IPublishable from '@dr-pam/common-components/Services/IPublishable';
import AddEventModal from '../events/AddEventModal';
import { useEventService } from '../../services/EventService';
import SelectEventModal from '../events/SelectEventModal';
import EditEventModal from '../events/EditEventModal';
import UpdateEventRequirementsModal from '../events/UpdateEventRequirementsModal';
import UpdateQuizRequirementsModal from '../quizzes/UpdateQuizRequirementsModal';
import CategoryPrerequisitesModal from '../category/CategoryPrerequisitesModal';

export type EditProgrammeProps = {
	className?: string;
	programmeId: string;
};

export default function EditProgramme(props: EditProgrammeProps) {
	const { className, programmeId } = props;

	const programmeService = useProgrammeService();
	const categoryService = useCategoryService();
	const articleService = useArticleService();
	const quizService = useQuizService();
	const resourceService = useResourceService();
	const eventService = useEventService();

	const asyncSavePromise = useRef<Promise<unknown>>();

	const [saveError, setSaveError] = useState<Error | null>(null);
	const [programme, setProgramme] = useState<ProgrammeWithContent | undefined | null>();
	const [categories, setCategories] = useState<
		CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>[]
	>([]);

	const isLoadingProgramme = useLoadingEffect(async () => {
		try {
			const programme = await programmeService.getWithContent(programmeId).response;
			if (programme) {
				programme.categories.sort(CategoryService.compareBySortOrderThenName);

				const categoryTree = CategoryService.toTree(programme.categories);
				categoryTree.sort(SortableTreeItem.compareBySortOrder);
				setCategories(categoryTree);
			}
			setProgramme(programme);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load programme.');
		}
	}, [programmeService, programmeId]);

	const { addLoader, removeLoader, isLoading: isSaving } = useLoadTracker();

	const rootCategory = useMemo(() => {
		const item = new CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>({
			id: 'root',
			name: 'Programme Outline',
			sortOrder: 0,
			parentId: null,
			slug: '',
			prerequisites: [],
			categoryArticles: [],
			categoryResources: [],
			categoryQuizzes: [],
			categoryEvents: [],
		});
		item.children = categories.map((c) => {
			c.parent = item;
			c.resetChangedState();
			return c;
		});
		return item;
	}, [categories]);

	if (programme === undefined || isLoadingProgramme) {
		return <LoadingOverlay visible={isLoadingProgramme} overlayProps={{ blur: 2 }} />;
	}

	if (programme === null) {
		return <NotFound />;
	}

	async function performAsyncSave<T>(work: () => Promise<T>): Promise<T | null> {
		const loader = addLoader();

		try {
			if (asyncSavePromise.current) {
				await asyncSavePromise.current;
			}

			asyncSavePromise.current = work();

			const result = await asyncSavePromise.current;

			asyncSavePromise.current = undefined;

			removeLoader(loader);

			return result as T;
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to save article content.');
			removeLoader(loader);
			setSaveError(err as Error);
			return null;
		}
	}

	const handleOnAddSubCategoryRequested = async (categoryId: string) => {
		return new Promise<CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null>(
			(resolve) => {
				const modalId = 'create-sub-category';
				modals.open({
					modalId,
					title: 'Add sub-category',
					children: (
						<AddCategoryModal
							modalId={modalId}
							programmeId={programme.id}
							parentId={categoryId === 'root' ? undefined : categoryId}
							onCreated={(category) => {
								resolve(
									new CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>({
										...category,
										prerequisites: [],
										categoryQuizzes: [],
										categoryResources: [],
										categoryArticles: [],
										categoryEvents: [],
									}),
								);
							}}
							onCancel={() => {
								resolve(null);
							}}
						/>
					),
				});
			},
		);
	};

	const handleOnAddArticleRequested = async (categoryId: string): Promise<ArticleCategoryListFragment | null> => {
		return new Promise<ArticleCategoryListFragment | null>((resolve) => {
			const modalId = 'add-article';
			modals.open({
				modalId,
				title: 'Add article',
				children: (
					<AddArticleModal
						modalId={modalId}
						categoryId={categoryId}
						onCreated={async (article) => {
							const articleCategory = await articleService.addArticleToCategory(article.id, categoryId);
							resolve(articleCategory);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnAddQuizRequested = async (categoryId: string): Promise<QuizCategoryListFragment | null> => {
		return new Promise<QuizCategoryListFragment | null>((resolve) => {
			const modalId = 'add-quiz';
			modals.open({
				modalId,
				title: 'Add quiz',
				children: (
					<AddQuizModal
						modalId={modalId}
						categoryId={categoryId}
						onCreated={(quiz) => {
							const quizCategory = quizService.addQuizToCategory(quiz.id, categoryId);
							resolve(quizCategory);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnAddResourceRequested = async (categoryId: string): Promise<ResourceCategoryListFragment | null> => {
		return new Promise<ResourceCategoryListFragment | null>((resolve) => {
			const modalId = 'add-resource';
			modals.open({
				modalId,
				title: 'Add resource',
				children: (
					<AddResourceModal
						modalId={modalId}
						categoryId={categoryId}
						onCreated={(resource) => {
							const resourceCategory = resourceService.addResourceToCategory(resource.id, categoryId);
							resolve(resourceCategory);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnAddEventRequested = async (categoryId: string): Promise<EventCategoryListFragment | null> => {
		return new Promise<EventCategoryListFragment | null>((resolve) => {
			const modalId = 'add-event';
			modals.open({
				modalId,
				title: 'Add event',
				children: (
					<AddEventModal
						modalId={modalId}
						categoryId={categoryId}
						onCreated={(event) => {
							const eventCategory = eventService.addEventToCategory(event.id, categoryId);
							resolve(eventCategory);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};

	const handleOnLinkArticleRequested = async (categoryId: string): Promise<ArticleCategoryListFragment | null> => {
		return new Promise<ArticleCategoryListFragment | null>((resolve) => {
			const modalId = 'link-article';
			modals.open({
				modalId,
				title: 'Link article',
				children: (
					<SelectArticleModal
						modalId={modalId}
						categoryId={categoryId}
						onSelected={(articleCategory) => {
							resolve(articleCategory);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnLinkQuizRequested = async (categoryId: string): Promise<QuizCategoryListFragment | null> => {
		return new Promise<QuizCategoryListFragment | null>((resolve) => {
			const modalId = 'link-quiz';
			modals.open({
				modalId,
				title: 'Link quiz',
				children: (
					<SelectQuizModal
						modalId={modalId}
						categoryId={categoryId}
						onSelected={(quizCategory) => {
							resolve(quizCategory);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnLinkResourceRequested = async (categoryId: string): Promise<ResourceCategoryListFragment | null> => {
		return new Promise<ResourceCategoryListFragment | null>((resolve) => {
			const modalId = 'link-resource';
			modals.open({
				modalId,
				title: 'Link resource',
				children: (
					<SelectResourceModal
						modalId={modalId}
						categoryId={categoryId}
						onSelected={(resourceCategory) => {
							resolve(resourceCategory);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnLinkEventRequested = async (categoryId: string): Promise<EventCategoryListFragment | null> => {
		return new Promise<EventCategoryListFragment | null>((resolve) => {
			const modalId = 'link-event';
			modals.open({
				modalId,
				title: 'Link event',
				children: (
					<SelectEventModal
						modalId={modalId}
						categoryId={categoryId}
						onSelected={(eventCategory) => {
							resolve(eventCategory);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};

	const handleOnDeleteCategoryRequested = async (categoryId: string) => {
		await categoryService.delete(categoryId);
	};
	const handleOnUnlinkArticleRequested = async (articleId: string, categoryId: string) => {
		await articleService.removeArticleFromCategory(articleId, categoryId);
	};
	const handleOnUnlinkQuizRequested = async (quizId: string, categoryId: string) => {
		await quizService.removeQuizFromCategory(quizId, categoryId);
	};
	const handleOnUnlinkResourceRequested = async (resourceId: string, categoryId: string) => {
		await resourceService.removeResourceFromCategory(resourceId, categoryId);
	};
	const handleOnUnlinkEventRequested = async (eventId: string, categoryId: string) => {
		await eventService.removeEventFromCategory(eventId, categoryId);
	};

	const handleOnUpdateCategoryRequested = (
		category: CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>,
	): Promise<CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null> => {
		return new Promise<CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null>(
			(resolve) => {
				const modalId = 'edit-category';
				modals.open({
					modalId,
					title: 'Edit category',
					children: (
						<EditCategoryModal
							modalId={modalId}
							current={category.item}
							programmeId={programme.id}
							onEdited={(updatedCategory) => {
								category.item.name = updatedCategory.name;
								category.item.slug = updatedCategory.slug;
								resolve(category);
							}}
							onCancel={() => {
								resolve(null);
							}}
						/>
					),
				});
			},
		);
	};

	const handleOnSetCategoryPrerequisitesRequested = (
		category: CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment>,
	): Promise<CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null> => {
		return new Promise<CategorySortableTreeItem<CategoryListWithContentAndPrerequisitesFragment> | null>(
			(resolve) => {
				const categories = rootCategory.children
					.filter((c) => isCategoryListWithContentFragment(c.item))
					.map((c) => c.item as CategoryListFragment)
					.sort((c) => c.sortOrder);

				const modalId = 'set-category-prerequisites';
				modals.open({
					modalId,
					title: 'Set category prerequisites',
					children: (
						<CategoryPrerequisitesModal
							modalId={modalId}
							current={category.item}
							categories={categories}
							onEdited={(updatedCategory) => {
								category.item.prerequisites = updatedCategory.prerequisites;
								resolve(category);
							}}
							onCancel={() => {
								resolve(null);
							}}
						/>
					),
				});
			},
		);
	};

	const handleOnUpdateArticleRequested = (article: ArticleListFragment): Promise<ArticleListFragment | null> => {
		return new Promise<ArticleListFragment | null>((resolve) => {
			const modalId = 'edit-article';
			modals.open({
				modalId,
				title: 'Edit article',
				children: (
					<EditArticleModal
						modalId={modalId}
						current={article}
						onEdited={(article) => {
							resolve(article);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnUpdateQuizRequested = (quiz: QuizListFragment): Promise<QuizListFragment | null> => {
		return new Promise<QuizListFragment | null>((resolve) => {
			const modalId = 'edit-quiz';
			modals.open({
				modalId,
				title: 'Edit quiz',
				children: (
					<EditQuizModal
						modalId={modalId}
						current={quiz}
						onEdited={(quiz) => {
							resolve(quiz);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnUpdateResourceRequested = (resource: ResourceListFragment): Promise<ResourceListFragment | null> => {
		return new Promise<ResourceListFragment | null>((resolve) => {
			const modalId = 'Edit-resource';
			modals.open({
				modalId,
				title: 'Edit resource',
				children: (
					<EditResourceModal
						modalId={modalId}
						current={resource}
						onEdited={(resource) => {
							resolve(resource);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};
	const handleOnUpdateEventRequested = (event: EventListFragment): Promise<EventListFragment | null> => {
		return new Promise<EventListFragment | null>((resolve) => {
			const modalId = 'Edit-event';
			modals.open({
				modalId,
				title: 'Edit event',
				children: (
					<EditEventModal
						modalId={modalId}
						current={event}
						onEdited={(event) => {
							resolve(event);
						}}
						onCancel={() => {
							resolve(null);
						}}
					/>
				),
			});
		});
	};

	const handleOnPinArticleCategoryRequested = async (articleCategoryId: string, isPinned: boolean) => {
		await performAsyncSave(() => articleService.updateIsPinned(articleCategoryId, isPinned));
	};

	const handleOnPinQuizCategoryRequested = async (quizCategoryId: string, isPinned: boolean) => {
		await performAsyncSave(() => quizService.updateIsPinned(quizCategoryId, isPinned));
	};

	const handleOnPinResourceCategoryRequested = async (resourceCategoryId: string, isPinned: boolean) => {
		await performAsyncSave(() => resourceService.updateIsPinned(resourceCategoryId, isPinned));
	};

	const handleOnPinEventCategoryRequested = async (eventCategoryId: string, isPinned: boolean) => {
		await performAsyncSave(() => eventService.updateIsPinned(eventCategoryId, isPinned));
	};

	const handleOnRequiredArticleCategoryRequested = async (articleCategoryId: string, isRequired: boolean) => {
		await performAsyncSave(() => articleService.updateIsRequired(articleCategoryId, isRequired));
	};

	const handleOnRequiredQuizCategoryRequested = async (quizCategoryId: string, isRequired: boolean) => {
		await performAsyncSave(() => quizService.updateIsRequired(quizCategoryId, isRequired));
	};

	const handleOnRequiredResourceCategoryRequested = async (resourceCategoryId: string, isRequired: boolean) => {
		await performAsyncSave(() => resourceService.updateIsRequired(resourceCategoryId, isRequired));
	};

	const handleOnRequiredEventCategoryRequested = async (eventCategoryId: string, isRequired: boolean) => {
		await performAsyncSave(() => eventService.updateIsRequired(eventCategoryId, isRequired));
	};

	const handleOnSetQuizRequirementsRequested = async (quizCategory: QuizCategoryListFragment) => {
		return new Promise<QuizCategoryListFragment | null>((resolve) => {
			const modalId = `set-quiz-requirements`;
			modals.open({
				modalId,
				title: `Update Quiz Requirements`,
				children: (
					<UpdateQuizRequirementsModal
						modalId={modalId}
						quizCategory={quizCategory}
						onCancel={() => resolve(null)}
						onUpdated={(updated) => {
							resolve(updated);
						}}
					/>
				),
			});
		});
	};

	const handleOnSetEventRequirementsRequested = async (eventCategory: EventCategoryListFragment) => {
		return new Promise<EventCategoryListFragment | null>((resolve) => {
			const modalId = `set-event-requirements`;
			modals.open({
				modalId,
				title: `Update Event Requirements`,
				children: (
					<UpdateEventRequirementsModal
						modalId={modalId}
						eventCategory={eventCategory}
						onCancel={() => resolve(null)}
						onUpdated={(updated) => {
							resolve(updated);
						}}
					/>
				),
			});
		});
	};

	const handleOnDuplicateArticleRequested = (articleId: string, categoryId: string, sortOrder?: number) => {
		return performAsyncSave<ArticleCategoryListFragment>(async () => {
			const request = articleService.duplicateArticle(articleId);
			const duplicatedArticle = await request.response;

			const duplicatedArticleCategory = await articleService.addArticleToCategory(
				duplicatedArticle.id,
				categoryId,
				sortOrder,
			);
			return duplicatedArticleCategory;
		});
	};

	const handleOnDuplicateQuizRequested = (quizId: string, categoryId: string, sortOrder?: number) => {
		return performAsyncSave<QuizCategoryListFragment>(async () => {
			const request = quizService.duplicateQuiz(quizId);
			const duplicatedQuiz = await request.response;

			const duplicatedQuizCategory = await quizService.addQuizToCategory(
				duplicatedQuiz.id,
				categoryId,
				sortOrder,
			);
			return duplicatedQuizCategory;
		});
	};

	const toggleIsPublished = (
		itemId: string,
		isCurrentlyPublished: boolean,
		type: 'article' | 'quiz' | 'resource',
	) => {
		return new Promise<void>((resolve, reject) => {
			const modalId = `${isCurrentlyPublished ? 'un' : ''}publish-${type}`;

			let service: IPublishable<unknown>;

			if (type === 'article') {
				service = articleService;
			} else if (type === 'quiz') {
				service = quizService;
			} else {
				service = resourceService;
			}

			modals.open({
				modalId,
				title: `${isCurrentlyPublished ? 'Un-p' : 'P'}ublish ${type}`,
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText={`${isCurrentlyPublished ? 'Un-p' : 'P'}ublish`}
						onConfirm={async () => {
							try {
								if (isCurrentlyPublished) {
									await service.unpublish(itemId);
								} else {
									await service.publish(itemId);
								}
								resolve();
							} catch (err) {
								NotificationUtils.showError(
									err as Error,
									`Failed to ${isCurrentlyPublished ? 'un-' : ''}publish this ${type}.`,
								);
								reject(err);
							}
						}}
						danger={isCurrentlyPublished}
					>
						Are you sure you want to {isCurrentlyPublished ? 'un-' : ''}publish this {type}?
					</ConfirmModal>
				),
			});
		});
	};

	const republish = (itemId: string, type: 'article' | 'quiz') => {
		return new Promise<unknown>((resolve, reject) => {
			const modalId = `republish-${type}`;

			let service: IPublishable<unknown>;

			if (type === 'article') {
				service = articleService;
			} else {
				service = quizService;
			}

			modals.open({
				modalId,
				title: `Re-publish ${type}`,
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText={`Re-publish`}
						onConfirm={async () => {
							try {
								const updated = await service.publish(itemId);
								resolve(updated);
							} catch (err) {
								NotificationUtils.showError(err as Error, `Failed to re-publish this ${type}.`);
								reject(err);
							}
						}}
					>
						Are you sure you want to re-publish this {type}?
					</ConfirmModal>
				),
			});
		});
	};

	const handleOnPublishArticleRequested = async (articleId: string) => {
		await toggleIsPublished(articleId, false, 'article');
	};

	const handleOnRepublishArticleRequested = async (articleId: string) => {
		return (await republish(articleId, 'article')) as ArticleListFragment;
	};

	const handleOnUnpublishArticleRequested = async (articleId: string) => {
		await toggleIsPublished(articleId, true, 'article');
	};

	const handleOnPublishQuizRequested = async (quizId: string) => {
		await toggleIsPublished(quizId, false, 'quiz');
	};

	const handleOnRepublishQuizRequested = async (quizId: string) => {
		return (await republish(quizId, 'quiz')) as QuizListFragment;
	};

	const handleOnUnpublishQuizRequested = async (quizId: string) => {
		await toggleIsPublished(quizId, true, 'quiz');
	};

	const handleOnPublishResourceRequested = async (resourceId: string) => {
		await toggleIsPublished(resourceId, false, 'resource');
	};

	const handleOnUnpublishResourceRequested = async (resourceId: string) => {
		await toggleIsPublished(resourceId, true, 'resource');
	};

	const handleOnTreeModified = async () => {
		await performAsyncSave(async () => {
			const modifiedChildItems = rootCategory.getChangedChildren();

			if (modifiedChildItems.length) {
				await programmeService.updateChildItemSortOrdersAndParentIds(modifiedChildItems);

				modifiedChildItems.forEach((childItem) => {
					childItem.resetChangedState();
				});
			}
		});
	};

	const findItemById = (id: string): SortableTreeItem<ChildItem> | null => {
		if (rootCategory.id === id) {
			return rootCategory;
		} else {
			return rootCategory.findChild(id) as SortableTreeItem<ChildItem>;
		}
	};

	let saveMessage = (
		<Group justify="flex-end">
			Saved <IconCheck color="green" />
		</Group>
	);
	if (saveError) {
		saveMessage = (
			<Tooltip label={saveError.message}>
				<Group justify="flex-end">
					Failed to save <IconAlertCircle color="red" />
				</Group>
			</Tooltip>
		);
	} else if (isSaving) {
		saveMessage = (
			<Group justify="flex-end">
				Saving changes...
				<Loader size={'sm'} />
			</Group>
		);
	}

	return (
		<Content
			className={`EditProgramme ${className ?? ''}`}
			breadcrumbs={{ '/programmes': 'Programmes', '#': programme.name }}
			title={programme.name}
			preContent={saveMessage}
			fillHeight
		>
			<LoadingOverlay visible={isLoadingProgramme} overlayProps={{ blur: 2 }} />
			<SortableTree
				category={rootCategory}
				onAddSubCategoryRequested={handleOnAddSubCategoryRequested}
				onAddArticleRequested={handleOnAddArticleRequested}
				onAddQuizRequested={handleOnAddQuizRequested}
				onAddResourceRequested={handleOnAddResourceRequested}
				onAddEventRequested={handleOnAddEventRequested}
				onLinkArticleRequested={handleOnLinkArticleRequested}
				onLinkQuizRequested={handleOnLinkQuizRequested}
				onLinkResourceRequested={handleOnLinkResourceRequested}
				onLinkEventRequested={handleOnLinkEventRequested}
				onDeleteCategoryRequested={handleOnDeleteCategoryRequested}
				onUnlinkArticleRequested={handleOnUnlinkArticleRequested}
				onUnlinkQuizRequested={handleOnUnlinkQuizRequested}
				onUnlinkResourceRequested={handleOnUnlinkResourceRequested}
				onUnlinkEventRequested={handleOnUnlinkEventRequested}
				onUpdateCategoryRequested={handleOnUpdateCategoryRequested}
				onSetCategoryPrerequisitesRequested={handleOnSetCategoryPrerequisitesRequested}
				onUpdateArticleRequested={handleOnUpdateArticleRequested}
				onUpdateQuizRequested={handleOnUpdateQuizRequested}
				onUpdateResourceRequested={handleOnUpdateResourceRequested}
				onUpdateEventRequested={handleOnUpdateEventRequested}
				onPinArticleCategoryRequested={handleOnPinArticleCategoryRequested}
				onPinResourceCategoryRequested={handleOnPinResourceCategoryRequested}
				onPinEventCategoryRequested={handleOnPinEventCategoryRequested}
				onPinQuizCategoryRequested={handleOnPinQuizCategoryRequested}
				onRequiredArticleCategoryRequested={handleOnRequiredArticleCategoryRequested}
				onRequiredResourceCategoryRequested={handleOnRequiredResourceCategoryRequested}
				onRequiredEventCategoryRequested={handleOnRequiredEventCategoryRequested}
				onRequiredQuizCategoryRequested={handleOnRequiredQuizCategoryRequested}
				onTreeModified={handleOnTreeModified}
				onDuplicateQuizRequested={handleOnDuplicateQuizRequested}
				onDuplicateArticleRequested={handleOnDuplicateArticleRequested}
				onPublishArticleRequested={handleOnPublishArticleRequested}
				onRepublishArticleRequested={handleOnRepublishArticleRequested}
				onUnpublishArticleRequested={handleOnUnpublishArticleRequested}
				onPublishQuizRequested={handleOnPublishQuizRequested}
				onRepublishQuizRequested={handleOnRepublishQuizRequested}
				onUnpublishQuizRequested={handleOnUnpublishQuizRequested}
				onPublishResourceRequested={handleOnPublishResourceRequested}
				onUnpublishResourceRequested={handleOnUnpublishResourceRequested}
				onSetQuizRequirementsRequested={handleOnSetQuizRequirementsRequested}
				onSetEventRequirementsRequested={handleOnSetEventRequirementsRequested}
				findItemById={findItemById}
				isRootCategory
			/>
		</Content>
	);
}
