import React, { useCallback, useEffect, useState } from 'react';
import { useReportService } from '../../services/ReportService';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Loader, Stack, MultiSelect, Checkbox, Text, Divider } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import styled from '@emotion/styled';
import { DateInput } from '@mantine/dates';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import dayjs from 'dayjs';
import { ProductListFragment } from '../../graphql/graphql';
import { useProductService } from '../../services/ProductService';
import { ProductUserReportRequest } from '@dr-pam/common-components/Models/Reports';
import CsvUtils from '@dr-pam/common-components/Utils/CsvUtils';

export type UserReportProps = {
	className?: string;
};

export default function UserReport(props: UserReportProps) {
	const { className } = props;

	const [isAllActiveSubscriptions, setIsAllActiveSubscriptions] = useState(false);

	const { isLoading, addLoader, removeLoader } = useLoadTracker();
	const [products, setResourceTypes] = useState<ProductListFragment[]>([]);

	const { isInitialLoading } = useAuthenticatedUser();

	const reportService = useReportService();
	const productService = useProductService();

	useEffect(() => {
		const loader = addLoader();
		const request = productService.getAll();
		request.response
			.then((types) => {
				setResourceTypes(types);
			})
			.catch((err) => {
				NotificationUtils.showError(err as Error, 'Failed to load products');
			})
			.finally(() => {
				removeLoader(loader);
			});
	}, [addLoader, removeLoader, productService]);

	const fetchIncome = useCallback(
		async (
			startDate: string,
			endDate: string,
			productIds: string[],
			isAllActiveSubscriptions: boolean,
			isAllActiveUsers: boolean,
		) => {
			const loader = addLoader();
			try {
				const request = reportService.getProductUser(
					isAllActiveSubscriptions ? '' : dayjs(startDate).tz('UTC', true).toISOString(),
					isAllActiveSubscriptions ? '' : dayjs(endDate).tz('UTC', true).toISOString(),
					productIds,
					isAllActiveSubscriptions,
					isAllActiveUsers,
				);
				const response = await request.response;

				if (response.csvRows.length > 0) {
					const url = URL.createObjectURL(CsvUtils.convertToCsv(response));
					const link = document.createElement('a');
					link.href = url;
					if (isAllActiveSubscriptions) {
						link.download = `user_subscriptions_all_current.csv`;
					} else {
						link.download = `user_subscriptions_from_${dayjs(startDate).format('DD-MM-YYYY')}_to_${dayjs(
							endDate,
						).format('DD-MM-YYYY')}.csv`;
					}
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					URL.revokeObjectURL(url);
				} else {
					throw new Error('No records found');
				}
			} catch (err) {
				NotificationUtils.showError(err as Error, 'Failed to create report');
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, reportService, removeLoader],
	);

	const form = useForm<ProductUserReportRequest>({
		initialValues: {
			startDate: '',
			endDate: '',
			productId: [],
			allActiveUsers: false,
			isAllActiveSubscriptions: false,
		},
		validate: {
			startDate: (value) => {
				if (isAllActiveSubscriptions) return undefined;
				if (!value || value === '') {
					return 'Enter a date';
				} else {
					const isValidDate = dayjs(value).isValid();
					return isValidDate ? undefined : 'Invalid date';
				}
			},
			endDate: (value, values) => {
				if (isAllActiveSubscriptions) return undefined;
				if (!value || value === '') {
					return 'Enter a date';
				} else {
					const isValidDate = dayjs(value).isValid();
					const isStartDateValid = dayjs(values.startDate).isValid();

					if (!isValidDate) {
						return 'Invalid date';
					} else if (isStartDateValid && dayjs(value).isBefore(dayjs(values.startDate))) {
						return 'The end date must be greater or equal to start date';
					}
				}

				return undefined;
			},
			productId: isNotEmpty('Select one or more products'),
		},
	});

	const handleSubmit = async (values: ProductUserReportRequest) => {
		await fetchIncome(values.startDate, values.endDate, values.productId, isAllActiveSubscriptions, false);
	};

	const handleCheckboxChange = (event: { currentTarget: { checked: boolean } }) => {
		const isChecked = event.currentTarget.checked;
		setIsAllActiveSubscriptions(isChecked);

		if (isChecked) {
			// Clear the date inputs
			form.setFieldValue('startDate', '');
			form.setFieldValue('endDate', '');
		}
	};

	const isDisabled = isLoading || isInitialLoading;

	const productData = products.map((p) => ({
		value: p.id,
		label: p.shortName,
	}));
	const productDataWithAllOption = [...productData];
	return (
		<StyledExportOptions className={`ExportOptions ${className ?? ''}`}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Text size="lg" fw={700}>
						Product User Report
					</Text>
					<Divider />
					<Text size="sm" fw={500}>
						Only users subscribed to the selected product(s) will be included.
					</Text>
					<MultiSelect
						{...form.getInputProps('productId')}
						data={productDataWithAllOption}
						label="Product(s)"
						description="Select one or more products"
						placeholder={isLoading ? 'Loading...' : ''}
						disabled={isLoading}
						withAsterisk
					/>
					<Divider />
					<Text size="sm" fw={500}>
						Only users that have subscribed within the date range will be included.
					</Text>
					<DateInput
						{...form.getInputProps('startDate')}
						label="From"
						description="Enter a date in the following format: DD-MM-YYYY"
						valueFormat="DD-MM-YYYY"
						popoverProps={{ withinPortal: true }}
						disabled={isLoading || isAllActiveSubscriptions}
						withAsterisk
						clearable
					/>
					<DateInput
						{...form.getInputProps('endDate')}
						label="To"
						description="Enter a date in the following format: DD-MM-YYYY"
						valueFormat="DD-MM-YYYY"
						popoverProps={{ withinPortal: true }}
						disabled={isLoading || isAllActiveSubscriptions}
						withAsterisk
						clearable
					/>
					<Divider />
					<Text size="sm" fw={500}>
						Check this box to include all current subscriptions, ignoring the date range.
					</Text>
					<Checkbox
						label="All Current Subscriptions&nbsp;"
						checked={isAllActiveSubscriptions}
						onChange={handleCheckboxChange}
					/>
					<Divider />
					<Button type="submit" className="flex ac jc gxs" disabled={isDisabled}>
						<span>Create User Report (CSV)</span>
						{(isLoading || isInitialLoading) && <Loader size="xs" ml="xs" />}
					</Button>
				</Stack>
			</form>
		</StyledExportOptions>
	);
}

const StyledExportOptions = styled.div`
	form {
		width: 420px;
	}
`;
