import React, { useEffect, useState } from 'react';
import { Loader, Stack, TextInput, Text, Checkbox } from '@mantine/core';
import { useArticleService } from '../../services/ArticleService';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { useDebouncedValue } from '@mantine/hooks';
import { ArticleListFragment } from '../../graphql/graphql';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import styled from '@emotion/styled';

export type SearchArticleProps = {
	onSelected: (article: ArticleListFragment | undefined) => void;
};

export default function SearchArticle(props: SearchArticleProps) {
	const { onSelected } = props;

	const articleService = useArticleService();

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [articles, setArticles] = useState<ArticleListFragment[]>([]);
	const [selectedArticle, setSelectedArticle] = useState<ArticleListFragment | undefined>(undefined);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);

	useEffect(() => {
		if (debouncedSearchQuery) {
			const loader = addLoader();
			const request = articleService.searchByTitle(debouncedSearchQuery);
			setSelectedArticle(undefined);
			request.response
				.then((articles) => {
					setArticles(articles);
				})
				.catch((err) => {
					NotificationUtils.showError(err as Error, 'Failed to search articles');
				})
				.finally(() => {
					removeLoader(loader);
				});
		}
	}, [addLoader, articleService, debouncedSearchQuery, removeLoader]);

	const handleArticleSelected = (event: React.ChangeEvent<HTMLInputElement>, article: ArticleListFragment) => {
		setSelectedArticle(event.currentTarget.checked ? article : undefined);
		onSelected(event.currentTarget.checked ? article : undefined);
	};

	return (
		<Stack>
			<TextInput
				value={searchQuery}
				onChange={(event) => setSearchQuery(event.currentTarget.value)}
				label="Search articles by title"
				disabled={isLoading}
			/>
			{isLoading && (
				<Text ta="center">
					<Loader size="xs" ml="xs" />
				</Text>
			)}
			{!isLoading &&
				debouncedSearchQuery &&
				(articles.length ? (
					<Grid>
						{articles.map((a) => (
							<div key={a.id}>
								<div>{a.title}</div>
								<div>
									<Checkbox
										checked={selectedArticle?.id === a.id}
										onChange={(e) => handleArticleSelected(e, a)}
									/>
								</div>
							</div>
						))}
					</Grid>
				) : (
					<Text ta="center">No search results</Text>
				))}
		</Stack>
	);
}

const Grid = styled.div`
	max-height: 500px;
	overflow-y: auto;
	> div {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr auto;
		padding: 0.5rem;
		gap: 0.5rem;
		align-items: center;
		overflow-wrap: anywhere;

		&:nth-child(even) {
			background: var(--mantine-color-gray-2);
		}
	}
`;
