import CrudTable, { CrudTableColumn, useCrudState, useStandardCrudHandlers } from '../crud/CrudTable';
import ConfirmModal, { openConfirmModal } from '../modals/ConfirmModal';
import { ArticleListFragment, ArticleSingleFragment } from '../../graphql/graphql';
import { useArticleService } from '../../services/ArticleService';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { ActionIcon, Button, Flex, Menu, Tabs, Text } from '@mantine/core';
import { modals, openModal } from '@mantine/modals';
import {
	IconCopy,
	IconDotsVertical,
	IconFileText,
	IconLink,
	IconLinkOff,
	IconList,
	IconPencil,
	IconTrash,
} from '@tabler/icons-react';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AddArticleModal from './AddArticleModal';
import EditArticleModal from './EditArticleModal';
import ArticleUsageModal from './ArticleUsageModal';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import StatusBadge from '../content/StatusBadge';
import ResourceTypeList from '../resources/ResourceTypeList';

export default function ArticlesList() {
	const [filterType, setFilterType] = useState<FilterType>('all');
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');
	const [selectedTab, setSelectedTab] = useState<string>('all');

	const navigate = useNavigate();
	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const articleService = useArticleService();

	const fetchArticles = useCallback((): AbortableRequest<ArticleListFragment[]> | null => {
		if (filterType === 'orphans') {
			return articleService.getOrphans();
		} else if (searchQuery) {
			return articleService.searchByTitle(searchQuery);
		} else {
			return articleService.getAll();
		}
	}, [articleService, searchQuery, filterType]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const crudState = useCrudState<ArticleListFragment, ArticleSingleFragment>(fetchArticles);

	const crudHandlers = useStandardCrudHandlers({
		crudState,
		entityName: 'article',
		nameAccessor: (article) => article.title,
		addModalFactory: (props) => <AddArticleModal {...props} />,
		editModalFactory: (props) => <EditArticleModal {...props} />,
		delete: (article) => articleService.delete(article.id),
	});

	const handleDuplicateClicked = async (article: ArticleListFragment) => {
		await openConfirmModal({
			title: 'Duplicate article',
			confirmText: 'Yes',
			children: <Text>Are you sure you want to duplicate the article &quot;{article.title}&quot;?</Text>,
			onConfirm: async () => {
				const request = articleService.duplicateArticle(article.id);
				const duplicatedArticle = await request.response;
				crudState.setItems((existing) => {
					// Add the duplicated article after the original article
					const updated = [...(existing ?? [])];
					const index = updated.findIndex((item) => item.id === article.id);
					updated.splice(index + 1, 0, duplicatedArticle);
					return updated;
				});
			},
		});
	};

	const handleOnNavigateToProgramme = (programmeId: string) => {
		navigate(`/programmes/${programmeId}`);
	};

	const handleUnlinkArticleFromCategory = async (articleId: string, categoryId: string) => {
		const loader = addLoader();
		try {
			await articleService.removeArticleFromCategory(articleId, categoryId);
			removeLoader(loader);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to unlink article from category');
			removeLoader(loader);
		}
	};

	const handleUsageClicked = async (article: ArticleListFragment) => {
		const modalId = `article-usage-${article.id}`;
		openModal({
			modalId,
			title: 'Article usage',
			children: (
				<ArticleUsageModal
					modalId={modalId}
					articleId={article.id}
					onNavigate={handleOnNavigateToProgramme}
					onUnlink={handleUnlinkArticleFromCategory}
				/>
			),
		});
	};

	const getStatusForArticle = (article: ArticleListFragment): 'draft' | 'published' | 'changed' => {
		if (!article.isPublished) {
			return 'draft';
		}
		if (
			article.content?.markdownHash &&
			article.content.publishedMarkdownHash &&
			article.content.markdownHash !== article.content.publishedMarkdownHash
		) {
			return 'changed';
		}
		return 'published';
	};

	const crudColumns = (article: ArticleListFragment): CrudTableColumn[] => [
		{
			children: article.title,
		},
		{
			children: article.slug,
		},
		{
			children: (
				<StatusBadge status={getStatusForArticle(article)} onClick={() => handleTogglePublished(article)} />
			),
		},
		{
			children: (
				<Flex justify="flex-end" gap={0} wrap="nowrap" align="center">
					<Link to={`/articles/${article.id}`}>
						<Button variant="subtle">Edit content</Button>
					</Link>
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>

						<Menu.Dropdown>
							<Menu.Item leftSection={<IconPencil />} onClick={() => crudHandlers.editHandler(article)}>
								Edit
							</Menu.Item>
							<Menu.Item leftSection={<IconCopy />} onClick={() => handleDuplicateClicked(article)}>
								Duplicate
							</Menu.Item>
							<Menu.Item leftSection={<IconLink />} onClick={() => handleUsageClicked(article)}>
								Show usage
							</Menu.Item>
							<Menu.Item
								leftSection={<IconTrash />}
								onClick={() => crudHandlers.deleteHandler(article)}
								color="red"
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			),
		},
	];

	const handleTogglePublished = (article: ArticleListFragment) => {
		const modalId = 'publish-article';
		const status = getStatusForArticle(article);

		if (status === 'published') {
			modals.open({
				modalId,
				title: 'Un-publish article',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Un-publish"
						onConfirm={async () => {
							const updatedArticle = await articleService.unpublish(article.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedArticle.id ? updatedArticle : item,
								),
							);
						}}
						danger
					>
						Are you sure you want to un-publish the article {article.title}?
					</ConfirmModal>
				),
			});
		} else {
			modals.open({
				modalId,
				title: 'Publish article',
				children: (
					<ConfirmModal
						modalId={modalId}
						confirmText="Publish"
						onConfirm={async () => {
							const updatedArticle = await articleService.publish(article.id);
							crudState.setItems(
								(crudState.items ?? []).map((item) =>
									item.id === updatedArticle.id ? updatedArticle : item,
								),
							);
						}}
					>
						Are you sure you want to publish the article {article.title}?
					</ConfirmModal>
				),
			});
		}
	};

	const handleTabChanged = (tab: string | null) => {
		if (!tab) {
			return;
		}

		setSelectedTab(tab);

		if (tab !== 'types') {
			setFilterType(tab === 'orphans' ? 'orphans' : 'all');
		}
	};

	return (
		<Tabs value={selectedTab} onChange={handleTabChanged}>
			<Tabs.List mb="md">
				<Tabs.Tab value="all" leftSection={<IconFileText />}>
					All articles
				</Tabs.Tab>
				<Tabs.Tab value="orphans" leftSection={<IconLinkOff />}>
					Orphan articles
				</Tabs.Tab>
				<Tabs.Tab value="types" leftSection={<IconList />}>
					Resource types
				</Tabs.Tab>
			</Tabs.List>
			{selectedTab === 'types' ? (
				<ResourceTypeList />
			) : (
				<CrudTable
					items={crudState.items}
					isLoading={crudState.isFetching || isLoading}
					headers={crudHeaders}
					columns={crudColumns}
					entityName="article"
					onAddClicked={crudHandlers.addHandler}
					search={{
						onSearchRequested: handleSearchRequested,
						onSearchCleared: handleSearchRequested,
						initialValue: searchQuery,
					}}
				/>
			)}
		</Tabs>
	);
}

type FilterType = 'all' | 'orphans';

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Title',
	},
	{
		children: 'Slug',
	},
	{
		children: 'Published',
	},
	{
		children: '',
	},
];
