import React, { createContext, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { ProgrammeListFragment } from '../../graphql/graphql';
import { useProgrammeService } from '../../services/ProgrammeService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useAuthProvider } from '@dr-pam/common-components/Components/Auth/AuthProvider';

export type ProgrammesContextValue = {
	programmes: ProgrammeListFragment[];
	setProgrammes: Dispatch<SetStateAction<ProgrammeListFragment[]>>;
	updateSortOrders: (sortOrders: Pick<ProgrammeListFragment, 'id' | 'sortOrder'>[]) => Promise<void>;
};

export const ProgrammesContext = createContext<ProgrammesContextValue | null>(null);

export type ProgrammesProviderProps = {
	children?: React.ReactNode;
};

export default function ProgrammesProvider(props: ProgrammesProviderProps) {
	const { children } = props;

	const programmeService = useProgrammeService();
	const authProvider = useAuthProvider();

	const [programmes, setProgrammes] = useState<ProgrammeListFragment[]>([]);

	const updateSortOrders = async (sortOrders: Pick<ProgrammeListFragment, 'id' | 'sortOrder'>[]) => {
		await programmeService.updateSortOrders(sortOrders);
		setProgrammes((existing) => {
			const newProgrammes = [...existing];
			for (const sortOrder of sortOrders) {
				const index = newProgrammes.findIndex((p) => p.id === sortOrder.id);
				if (index !== -1) {
					newProgrammes[index].sortOrder = sortOrder.sortOrder;
				}
			}
			return newProgrammes;
		});
	};

	const contextValue = {
		programmes,
		setProgrammes,
		updateSortOrders,
	};

	useEffect(() => {
		if (authProvider.isInitialLoading || authProvider.user == null) {
			return;
		}
		const request = programmeService.getAll();
		request.response.then(setProgrammes).catch((err) => {
			NotificationUtils.showError(err as Error, 'Failed to load programmes');
		});
	}, [authProvider.isInitialLoading, authProvider.user, programmeService]);

	return <ProgrammesContext.Provider value={contextValue}>{children}</ProgrammesContext.Provider>;
}

export function useProgrammeProvider() {
	const context = React.useContext(ProgrammesContext);
	if (!context) {
		throw new Error('useProgrammeProvider must be used within an ProgrammesProvider');
	}
	return context;
}
