import { useMemo } from 'react';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import {
	CSVReport,
	FinancialReportRequest,
	ProductUserReportRequest,
	XeroReportRequest,
} from '@dr-pam/common-components/Models/Reports';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export const GQL_GET_MONTHLY_PRODUCT_INCOME = gql`
	query MonthlyProductIncome($startDate: DateTimeISO!, $endDate: DateTimeISO!) {
		payments(where: { created: { gte: $startDate }, AND: { created: { lte: $endDate } } }) {
			id
			created
			amountInCents
			currency
			paymentItems {
				id
				productSubscriptionType {
					id
					name
					priceInCents
					currency
					durationInDays
					product {
						name
					}
				}
			}
		}
	}
`;

export default class ReportService {
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public getMonthlyProductIncome(startDate: string, endDate: string) {
		const request = FetchUtils.postJson<CSVReport, FinancialReportRequest>(
			`/api/payment/report/monthly-product-income`,
			{
				startDate,
				endDate,
			},
		);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getXeroReport(reportDate: string) {
		const request = FetchUtils.postJson<CSVReport, XeroReportRequest>(`/api/payment/report/xero`, {
			reportDate,
		});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getProductUser(
		startDate: string,
		endDate: string,
		productId: string[],
		isAllActiveSubscriptions: boolean,
		allActiveUsers: boolean,
	) {
		const request = FetchUtils.postJson<CSVReport, ProductUserReportRequest>(`/api/payment/report/product-user`, {
			startDate,
			endDate,
			productId,
			isAllActiveSubscriptions,
			allActiveUsers,
		});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}
}

export function useReportService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new ReportService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
