import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import { useParams } from 'react-router-dom';
import EditArticle from '../../components/articles/EditArticle';
import NotFound from '../../components/page/NotFound';

export default function ArticlePage() {
	const { articleId } = useParams<{ articleId: string }>();

	return (
		<BackendPage centerChildren={articleId === undefined}>
			{articleId ? <EditArticle articleId={articleId} /> : <NotFound />}
		</BackendPage>
	);
}
