import React, { useState } from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useProductService } from '../../services/ProductService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { ProductProgrammeFragment, ProgrammeListFragment } from '../../graphql/graphql';
import SearchProgramme from '../programmes/SearchProgramme';

export type AddProductProgrammeModalProps = CrudAddModalProps<ProductProgrammeFragment, ProductProgrammeFragment> & {
	productId: string;
	excludeIds?: string[];
	onManyCreated?: (programmes: ProductProgrammeFragment[]) => void;
};

export default function AddProductProgrammeModal(props: AddProductProgrammeModalProps) {
	const { modalId, productId, excludeIds, onCancel, onManyCreated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [selectedProgrammes, setSelectedProgrammes] = useState<ProgrammeListFragment[]>([]);

	const productService = useProductService();

	const handleAddProgramme = async () => {
		if (!selectedProgrammes.length) {
			return;
		}
		const loader = addLoader();
		try {
			const productProgrammes = await productService.addProgrammes(
				productId,
				selectedProgrammes.map((x) => x.id),
			);
			onManyCreated?.(productProgrammes);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to add programme to product');
		} finally {
			removeLoader(loader);
			modals.close(modalId);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<Stack>
				<SearchProgramme onSelected={setSelectedProgrammes} excludeIds={excludeIds} allowMultiple />
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button
						type="submit"
						disabled={isLoading || !selectedProgrammes.length}
						onClick={handleAddProgramme}
					>
						Add programmes
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}
