import React, { useState } from 'react';
import BackendPage from '../../components/page/BackendPage';
import XeroReport from '../../components/reports/XeroReport';
import Content from '../../components/Content';
import { Tabs } from '@mantine/core';
import { IconCoins, IconFileImport, IconUsers } from '@tabler/icons-react';
import UserReport from '../../components/reports/UserReport';
import FinancialReport from '../../components/reports/FinancialReport';

export default function ReportPage() {
	const [selectedTab, setSelectedTab] = useState<string>('user-report');
	const handleTabChanged = (tab: string | null) => {
		if (!tab) {
			return;
		}

		setSelectedTab(tab);
	};
	return (
		<BackendPage title="Reports" centerChildren>
			<Content fillHeight>
				<Tabs value={selectedTab} onChange={handleTabChanged}>
					<Tabs.List mb="md">
						<Tabs.Tab value="user-report" leftSection={<IconUsers />}>
							Product User Report
						</Tabs.Tab>
						<Tabs.Tab value="financial-report" leftSection={<IconCoins />}>
							Financial Report
						</Tabs.Tab>
						<Tabs.Tab value="xero-report" leftSection={<IconFileImport />}>
							Xero Journal Template
						</Tabs.Tab>
					</Tabs.List>
				</Tabs>
				{selectedTab === 'user-report' && (
					<>
						<UserReport />
					</>
				)}
				{selectedTab === 'financial-report' && (
					<>
						<FinancialReport />
					</>
				)}
				{selectedTab === 'xero-report' && (
					<>
						<XeroReport />
					</>
				)}
			</Content>
		</BackendPage>
	);
}
