import { Center, Stack, Title } from '@mantine/core';
import React, { ReactNode } from 'react';

export type NotFoundProps = {
	title?: string;
	children?: ReactNode;
};

export default function NotFound(props: NotFoundProps) {
	const { title, children } = props;
	return (
		<Center maw={1024} mx="auto" mt="lg">
			<Stack w="100%" h="100%">
				<Title order={1}>{title ?? 'Not found'}</Title>
				{children}
			</Stack>
		</Center>
	);
}
