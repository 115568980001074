import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import ProductsList from '../../components/products/ProductsList';

export default function ProductsPage() {
	return (
		<BackendPage title="Products">
			<ProductsList />
		</BackendPage>
	);
}
