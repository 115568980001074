import React from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, NumberInput, Select, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { useEventService } from '../../services/EventService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import {
	EventOccurrenceCreateInput,
	EventOccurrenceListFragment,
	EventOccurrenceSingleFragment,
} from '../../graphql/graphql';
import z from 'zod';
import { DateTimePicker } from '@mantine/dates';
import DateTimeUtils, { DATE_TIME_FORMAT } from '@dr-pam/common-components/Utils/DateTimeUtils';

export type AddEventOccurrenceModalProps = CrudAddModalProps<
	EventOccurrenceListFragment,
	EventOccurrenceSingleFragment
> & {
	eventId: string;
	maxRegistrations?: number;
};

const RequiredConfig = { required_error: 'Required', invalid_type_error: 'Required' };

export const validationSchema = z
	.object({
		start: z.date(RequiredConfig),
		end: z.date().optional().nullable(),
		maxRegistrations: z.null().or(z.coerce.number().positive().or(z.coerce.string().length(0))),
	})
	.refine(
		(data) => {
			if (!data.end) {
				return true;
			}
			return data.start < data.end;
		},
		{
			message: `"Start Date/Time" must be before "End Date/Time"`,
			path: ['end'],
		},
	);

export default function AddEventOccurrenceModal(props: AddEventOccurrenceModalProps) {
	const { modalId, onCancel, onCreated, eventId, maxRegistrations } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const eventService = useEventService();

	const form = useForm<AddEventOccurrenceForm>({
		initialValues: {
			start: null,
			end: null,
			maxRegistrations: maxRegistrations ?? null,
			timezone: DateTimeUtils.getTimezoneInfo().name,
		},
		validate: zodResolver(validationSchema),
		transformValues: (values) => {
			return {
				...values,
				maxRegistrations: values.maxRegistrations ? parseInt(`${values.maxRegistrations}`, 10) : null,
			};
		},
	});

	const handleSubmit = async (values: AddEventOccurrenceForm) => {
		const loader = addLoader();

		try {
			const start = DateTimeUtils.parseTz(
				DateTimeUtils.format(values.start, DATE_TIME_FORMAT),
				values.timezone,
				DATE_TIME_FORMAT,
			);
			const end = values.end
				? DateTimeUtils.parseTz(
						DateTimeUtils.format(values.end, DATE_TIME_FORMAT),
						values.timezone,
						DATE_TIME_FORMAT,
				  )
				: null;

			const createdEventOccurrence = await eventService.createOccurrence({
				start,
				end,
				maxRegistrations: values.maxRegistrations,
				event: {
					connect: {
						id: eventId,
					},
				},
			});
			onCreated(createdEventOccurrence);
			NotificationUtils.showSuccess('Successfully created event occurrence', 'Event Occurrence');
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create event occurrence');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const allTimezones = DateTimeUtils.getTimezones().map((tz) => ({ value: tz, label: tz }));

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<DateTimePicker
						{...form.getInputProps('start')}
						label="Start Date/Time"
						description="(DD-MM-YYYY HH:mm)"
						valueFormat={DATE_TIME_FORMAT}
						popoverProps={{ withinPortal: true }}
						disabled={isLoading}
						withAsterisk
						clearable
					/>
					<DateTimePicker
						{...form.getInputProps('end')}
						label="End Date/Time"
						description="(DD-MM-YYYY HH:mm). Optional"
						valueFormat={DATE_TIME_FORMAT}
						popoverProps={{ withinPortal: true }}
						disabled={isLoading}
						clearable
					/>
					<Select
						{...form.getInputProps('timezone')}
						data={allTimezones}
						label="Timezone"
						placeholder={'--- Please select ---'}
						nothingFoundMessage="No search results"
						disabled={isLoading}
						searchable
						clearable
					/>
					<NumberInput
						{...form.getInputProps('maxRegistrations')}
						label="Maximum registrations"
						description="The maximum number of people that may register for this event occurrence"
						decimalScale={0}
						thousandSeparator=","
						disabled={isLoading}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Add event {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddEventOccurrenceForm = Pick<EventOccurrenceCreateInput, 'start' | 'end' | 'maxRegistrations'> & {
	timezone: string;
};
