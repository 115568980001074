import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import EventsList from '../../components/events/EventsList';

export default function EventsPage() {
	return (
		<BackendPage title="Events">
			<EventsList />
		</BackendPage>
	);
}
