import React from 'react';
import Login from '../components/auth/Login';
import { Center } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';

export default function LoginPage() {
	const [params] = useSearchParams();
	const email = params.get('email') ?? undefined;

	return (
		<Center maw={256} mx="auto" h="100%">
			<Login email={email} />
		</Center>
	);
}
