import React from 'react';
import { CrudEditModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { UserForUpdate, useUserService } from '../../services/UserService';
import type { User } from '@dr-pam/common-types/database';
import z from 'zod';

const validationSchema = z.object({
	email: z.string().nonempty('Required').email().max(512),
	displayName: z.string().max(512),
	fullName: z.string().nonempty().max(512),
});

export type UpdateUserModalProps = CrudEditModalProps<User, User>;

export default function UpdateUserModal(props: UpdateUserModalProps) {
	const { modalId, onCancel, onEdited, current } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const userService = useUserService();

	const form = useForm<UpdateUserForm>({
		initialValues: {
			email: current.email,
			fullName: current.fullName,
			displayName: current.displayName ?? '',
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: UpdateUserForm) => {
		const loader = addLoader();

		try {
			const request = userService.update(current.id, {
				id: current.id,
				email: values.email || undefined,
				fullName: values.fullName,
				displayName: values.displayName || undefined,
			});
			const updatedUser = await request.response;
			onEdited(updatedUser);
			NotificationUtils.showSuccess('Successfully updatee user', updatedUser.displayName ?? updatedUser.email);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update user');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...form.getInputProps('email')}
						type="email"
						label="Email"
						description="The email of the user"
						disabled={isLoading}
					/>
					<TextInput
						{...form.getInputProps('fullName')}
						label="Full name"
						description="The full name of the user"
						disabled={isLoading}
					/>
					<TextInput
						{...form.getInputProps('displayName')}
						label="Display name"
						description="First name or nickname"
						disabled={isLoading}
					/>
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Save user {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type UpdateUserForm = Pick<UserForUpdate, 'email' | 'displayName' | 'fullName'>;
