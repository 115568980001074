import React, { useEffect, useState } from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Container, TextInput, Button, Loader, Select, Stack, Group } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { useArticleService } from '../../services/ArticleService';
import useComputedSlug from '../../hooks/useComputedSlug';
import useLoadingEffect from '@dr-pam/common-components/Hooks/useLoadingEffect';
import {
	ArticleListFragment,
	ArticleSingleFragment,
	CategoryListFragment,
	ResourceTypeListFragment,
} from '../../graphql/graphql';
import { CategorySortableTreeItem, useCategoryService } from '../../services/CategoryService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useResourceService } from '../../services/ResourceService';
import z from 'zod';

export const validationSchema = z.object({
	title: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
});

export type AddArticleModalProps = CrudAddModalProps<ArticleListFragment, ArticleSingleFragment> & {
	categoryId?: string;
};

export default function AddArticleModal(props: AddArticleModalProps) {
	const { modalId, onCancel, onCreated, categoryId } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [categories, setCategories] = useState<CategorySortableTreeItem<CategoryListFragment>[]>([]);
	const [resourceTypes, setResourceTypes] = useState<ResourceTypeListFragment[]>([]);

	const categoryService = useCategoryService();
	const articleService = useArticleService();
	const resourceService = useResourceService();

	useEffect(() => {
		const loader = addLoader();
		const request = resourceService.getAllTypes();
		request.response
			.then((types) => {
				setResourceTypes(types);
			})
			.catch((err) => {
				NotificationUtils.showError(err as Error, 'Failed to load resource types');
			})
			.finally(() => {
				removeLoader(loader);
			});
	}, [addLoader, removeLoader, resourceService]);

	const isLoadingCategories = useLoadingEffect(async () => {
		try {
			const response = await categoryService.getAllInFlattenedTree().response;
			setCategories(response);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to load categories');
		}
	}, [categoryService, categoryId]);

	const form = useForm<AddArticleForm>({
		initialValues: {
			title: '',
			slug: '',
			categoryId: categoryId ?? '',
			resourceTypeId: undefined,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: AddArticleForm) => {
		const loader = addLoader();
		try {
			const createdArticle = await articleService.create({
				title: values.title,
				slug: values.slug,
				type: values.resourceTypeId
					? {
							connect: {
								id: values.resourceTypeId,
							},
					  }
					: undefined,
				...((categoryId || values.categoryId) && {
					articleCategories: {
						create: [
							{
								category: {
									connect: {
										id: categoryId || values.categoryId,
									},
								},
							},
						],
					},
				}),
			});
			onCreated(createdArticle);
			NotificationUtils.showSuccess('Successfully created article', createdArticle.title);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create article');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'title');

	const resourceTypeData = resourceTypes.map((resourceType) => ({
		value: resourceType.id,
		label: resourceType.name,
	}));

	const categoryData = (categories ?? []).map((category) => ({
		value: category.item.id,
		label: category.getName(),
	}));

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...slugComputedFromProps}
						label="Title"
						description="The title of the article. Forms the basis of the Slug"
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Select
						{...form.getInputProps('resourceTypeId')}
						data={resourceTypeData}
						label="Resource type"
						placeholder={isLoading ? 'Loading...' : '--- Please select ---'}
						disabled={isLoading}
					/>
					<Select
						{...form.getInputProps('categoryId')}
						data={categoryData}
						label="Initial category"
						placeholder={isLoadingCategories ? 'Loading...' : '--- Please select ---'}
						nothingFoundMessage="No search results"
						searchable
						disabled={isLoading || isLoadingCategories || categoryId !== undefined}
					/>
					<Group justify="flex-end" mt="md">
						<Button
							type="button"
							variant="subtle"
							onClick={handleCancel}
							disabled={isLoading || isLoadingCategories}
						>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading || isLoadingCategories}>
							Add article {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddArticleForm = Pick<ArticleSingleFragment, 'title' | 'slug' | 'resourceTypeId'> & {
	categoryId: string;
};
