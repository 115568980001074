import React, { useState } from 'react';
import { Container, Button, Stack, Group } from '@mantine/core';
import { modals } from '@mantine/modals';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { ResourceCategoryListFragment } from '../../graphql/graphql';
import SearchResource from './SearchResource';
import { useResourceService } from '../../services/ResourceService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';

export type SelectResourceModalProps = {
	modalId: string;
	categoryId: string;
	onCancel?: () => void;
	onSelected: (resource: ResourceCategoryListFragment) => void;
};

export default function SelectResourceModal(props: SelectResourceModalProps) {
	const { modalId, categoryId, onCancel, onSelected } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const [selectedResourceId, setSelectedResourceId] = useState<string | undefined>(undefined);

	const resourceService = useResourceService();

	const handleLinkClicked = async () => {
		if (!selectedResourceId) {
			return;
		}
		const loader = addLoader();
		try {
			const linkedResourceCategory = await resourceService.addResourceToCategory(selectedResourceId, categoryId);
			onSelected(linkedResourceCategory);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to link resource');
		} finally {
			removeLoader(loader);
			modals.close(modalId);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<Stack>
				<SearchResource onSelected={(a) => setSelectedResourceId(a?.id)} />
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button
						type="submit"
						disabled={isLoading || selectedResourceId === undefined}
						onClick={handleLinkClicked}
					>
						Link selected resource
					</Button>
				</Group>
			</Stack>
		</Container>
	);
}
